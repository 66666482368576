<template>
    <div class="targets-toolbar">
        <div class="targets-toolbar__blur" />
        <div class="targets-toolbar__content container container--narrow">
            <div class="targets-toolbar__content-search">
                <InputField
                    :modelValue="modelValue"
                    :placeholder="$t('targetsPage.searchPlaceholder')"
                    icon="fa-magnifying-glass"
                    clearable
                    @update:modelValue="onSearchTermUpdate"
                    @onClear="() => onSearchTermUpdate('')"
                />
            </div>
            <div v-if="tabs.length" class="targets-toolbar__content-tabs" ref="tabsPanel">
                <TabNavigation
                    :tabs="tabs"
                    prefix="functionalGroup"
                    :offset="155"
                    class="target-selector__tabs"
                    ref="tabNavigation"
                >
                    <template #default="{ item }">
                        {{ $t(getToolbarItemName(item)) }}
                    </template>
                </TabNavigation>
            </div>
            <div
                class="targets-toolbar__content-counter"
                :class="{ 'targets-toolbar__content-counter--show': this.shouldShowCounterBorder }"
                ref="counter"
            >
                <Counter :max="counterMax" :value="counterValue" />
            </div>
            <div class="targets-toolbar__content-selected-targets">
                <SelectedTargetsList xs disableInteraction />
            </div>
        </div>
    </div>
</template>

<script>
import TabNavigation from '@/components/molecules/TabNavigation.vue';
import InputField from '@/components/atoms/InputField.vue';
import Counter from '@/components/atoms/Counter.vue';
import SelectedTargetsList from '@/components/molecules/SelectedTargetsList.vue';

export default {
    name: 'TargetsToolbar',
    components: { SelectedTargetsList, Counter, InputField, TabNavigation },
    emits: ['update:modelValue'],
    data() {
        return {
            searchTerm: '',
            shouldShowCounterBorder: false,
            activeTab: 0,
        };
    },
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        counterMax: {
            type: Number,
        },
        counterValue: {
            type: Number,
        },
        modelValue: {
            type: String,
        },
    },
    beforeUnmount() {
        this.onSearchTermUpdate('');
    },
    updated() {
        this.$nextTick(() => {
            if (!this.$refs.tabsPanel) return;

            // BACE-2252 :: Only show border when tabs are overlapping...
            const innerWidth =
                this.$refs.tabsPanel.querySelector('.target-selector__tabs').offsetWidth;
            const outerWidth = this.$refs.tabsPanel.getBoundingClientRect().width;

            this.shouldShowCounterBorder = innerWidth >= outerWidth;
        });
    },
    methods: {
        onSearchTermUpdate(term) {
            this.searchTerm = term;
            this.$emit('update:modelValue', term);
        },
        updateSelectedTab(data) {
            if (this.$refs.tabNavigation) {
                this.$refs.tabNavigation.setCurrentTab(data);
            }
        },
        getToolbarItemName(item) {
            if (
                this.$te(`functionalGroups.${item}.custom_name`) ||
                this.$te(`functionalGroups.${item}.custom_name`, 'en_ALL')
            ) {
                return `functionalGroups.${item}.custom_name`;
            }

            return `functionalGroups.${item}.name`;
        },
    },
};
</script>
