<template>
    <div class="parameter-label">
        <button
            v-if="sidePanelContent"
            class="parameter-label__info-btn"
            type="button"
            @click.prevent="onInfoIconClick"
        />
        <h4 class="parameter-label__info-title">
            {{ title }}
            <span v-if="required"> * </span>
        </h4>
        <slot name="extra" />
    </div>
</template>

<script>
import SidebarContentTypes from '@/utils/constants/sidebar-content-types';

export default {
    name: 'ParameterLabel',
    props: {
        title: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: true,
        },
        sidePanelContent: {
            type: [Object, String],
        },
    },
    methods: {
        onInfoIconClick() {
            if (Object.keys(this.sidePanelContent).length === 0) {
                return;
            }
            this.$openSidebar(SidebarContentTypes.RTE_TEXT, this.title, {
                text: this.sidePanelContent,
            });
        },
    },
};
</script>
