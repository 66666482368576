export default {
    RTE_TEXT: 'rte-text',
    CROP_STRATEGY: 'crop-strategy',
    SELECTED_TARGETS: 'selected-targets',
    SELECTED_TARGETS_MOBILE: 'selected-targets-mobile',
    NAVIGATION: 'navigation',
    IFRAME: 'iframe',
    EMAIL_FORM: 'email-form',
    DEEPLINK: 'deeplink',
    GROWING_CONDITIONS: 'growing-conditions',
    CHARACTERISTIC: 'characteristic',
    COMPARE_TANKMIX: 'compare-tankmix',
    SEED_RATE_CALCULATOR: 'seed-rate-calculator',
    COUNTRY_CONFIRMATION_GATE: 'confirmation-gate',
};
