<template>
    <div class="scroll-spy">
        <div class="scroll-spy__content" ref="scrollContent">
            <slot />
        </div>
    </div>
</template>

<script>
import ScrollSpyContainer from '@/components/atoms/ScrollSpyContainer.vue';
import { isInIframe } from '@/utils/helpers';

export default {
    name: 'ScrollSpy',
    components: { ScrollSpyContainer },
    data() {
        return {
            sections: [],
        };
    },
    props: {
        order: {
            type: Array,
            required: false,
        },
    },
    emits: ['onActiveSectionChanged'],
    mounted() {
        const container = isInIframe() ? window.document.querySelector('#app') : document;
        container.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        this.sections = [];

        const container = isInIframe() ? window.document.querySelector('#app') : document;
        container.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        initSection(sectionEl) {
            if (!this.sections.includes(sectionEl) && sectionEl?.id) {
                this.sections.push(sectionEl);
                this.sortSections();
            }
        },
        sortSections() {
            if (this.order) {
                this.sections.sort((a, b) => {
                    const aOrderIdentifier = a?.id.split('.')[1];
                    const bOrderIdentifier = b?.id.split('.')[1];
                    const aIndex = this.order.indexOf(aOrderIdentifier);
                    const bIndex = this.order.indexOf(bOrderIdentifier);
                    return aIndex - bIndex;
                });
            }
        },
        handleScroll(e) {
            const inactiveSections = [];
            let maxIntersectionPercentage = -1;

            this.sections.forEach((target) => {
                const rects = target.getClientRects();
                const index = this.sections.findIndex((section) => section === target);

                if (rects.length === 0) {
                    inactiveSections.push(index);
                } else {
                    const visibilityPercentage = this.checkElementVisibilityPercentage(target);
                    const id = target.getAttribute('id');

                    if (
                        visibilityPercentage > 0 &&
                        visibilityPercentage > maxIntersectionPercentage
                    ) {
                        maxIntersectionPercentage = visibilityPercentage;
                        this.$emit('onActiveSectionChanged', { id, target, index });
                    }
                }
            });

            inactiveSections.forEach((s) => this.sections.splice(s, 1));
        },
        checkElementVisibilityPercentage(el) {
            const { top, left, bottom, right } = el.getBoundingClientRect();
            const { innerHeight, innerWidth } = window;

            const isVisibleVertically = top < innerHeight && bottom > 0;
            const isVisibleHorizontally = left < innerWidth && right > 0;

            if (!isVisibleVertically || !isVisibleHorizontally) {
                return 0;
            }

            const intersectionHeight = Math.min(innerHeight, bottom) - Math.max(0, top);
            const intersectionWidth = Math.min(innerWidth, right) - Math.max(0, left);

            const intersectionArea = intersectionHeight * intersectionWidth;
            const elementArea = (bottom - top) * (right - left);

            return (intersectionArea / elementArea) * 100;
        },
        resetSections() {
            this.sections = [];
        },
    },
};
</script>
