<template>
    <section class="growth-stage-selector form-section">
        <h2 class="headline">
            {{ $t('form.selectGrowthStage') }}
            <span>*</span>
        </h2>

        <Slider
            :items="growthStages"
            :slidesPerView="'auto'"
            :slidesPerViewM="'auto'"
            :slidesPerViewL="'auto'"
            :spacing="10"
            :spacingM="10"
            :spacingL="10"
            :initialSlide="selectedGrowthStageIndex"
            controls
            ref="slider"
            @slideChange="onSelectionChange"
        >
            <template #item="props">
                <GrowthStageSelectorItem
                    :growthStage="props.item"
                    :image="growthStageImages || null"
                    :illustrationMapping="growthStageIllustrationMapping"
                    @onClick="() => onGrowthStageClick(props.item)"
                />
            </template>
        </Slider>

        <GrowthStageSelectorNavigation
            :modelValue="selectedGrowthStageIndex"
            :growthStageCount="growthStages.length"
            :selectedGrowthStageIndex="selectedGrowthStageIndex"
            @onChange="(i) => onGrowthStageClick(growthStages[i])"
        />
    </section>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import Slider from '@/components/molecules/Slider.vue';
import GrowthStageSelectorItem from '@/components/atoms/GrowthStageSelectorItem.vue';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import GrowthStageSelectorNavigation from '@/components/molecules/GrowthStageSelectorNavigation.vue';
import { useCropProtectionStore } from '@/stores/crop-protection';

export default {
    name: 'GrowthStageSelector',
    components: {
        GrowthStageSelectorNavigation,
        GrowthStageSelectorItem,
        Slider,
    },
    props: {
        growthStages: {
            type: Array,
            required: true,
        },
        selectedCrop: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            growthStageImage: null,
        };
    },
    mounted() {
        this.$refs.slider.slideTo(this.selectedGrowthStageIndex);
    },
    computed: {
        ...mapState(useConfigurationFormStore, ['selectedGrowthStage']),
        ...mapState(useCropProtectionStore, [
            'growthStageImages',
            'growthStageIllustrationMapping',
        ]),

        selectedGrowthStageIndex() {
            return this.growthStages.findIndex((gs) => gs.code === this.selectedGrowthStage?.code);
        },
    },
    methods: {
        ...mapActions(useConfigurationFormStore, ['setSelectedGrowthStage']),

        onSelectionChange(selectedSlideIndex) {
            const selectedGrowthStage = this.growthStages[selectedSlideIndex];
            this.setSelectedGrowthStage(selectedGrowthStage);
        },
        onGrowthStageClick(growthStage) {
            this.$refs.slider.slideTo(this.growthStages.indexOf(growthStage), 500);
        },
    },
    watch: {
        selectedGrowthStageIndex(oldIdx, newIdx) {
            if (oldIdx !== newIdx) {
                this.$nextTick(() => this.$refs.slider.slideTo(this.selectedGrowthStageIndex));
            }
        },
    },
};
</script>
