<template>
    <div class="recommendation-card-header" :class="classes">
        <div class="recommendation-card-header__info">
            <div class="recommendation-card-header__info-title" v-sub-tag-wrapper>
                <span>
                    <template v-if="type">
                        {{ type }}
                    </template>
                </span>
                <h3>
                    <strong v-html="title"></strong>
                    <span v-if="hasNewTag">{{ $t('recommendations.newTag') }}</span>
                </h3>
            </div>
            <button
                v-if="hasComparisonButton"
                class="recommendation-card-header__info-compare-btn"
                :class="{
                    'recommendation-card-header__info-compare-btn--checked': isPartOfComparison,
                }"
                @click.prevent="onCompareClick"
            />
        </div>
        <template v-if="!isSeedCard">
            <Accordion v-if="expandable" class="recommendation-card-header__effect">
                <template #head>
                    <div class="recommendation-card-header__effect-overall">
                        <span>
                            <template v-if="overallEffect">
                                {{ $t('recommendations.overallEffect') }}
                                <IconBadge
                                    v-if="hasInefficientEfficacies"
                                    :title="$t('recommendation.notEfficientForAllTargets')"
                                />
                            </template>
                            <template v-else>
                                {{ $t('recommendations.effect') }}
                                <IconBadge
                                    v-if="hasInefficientEfficacies"
                                    :title="$t('recommendation.notEfficientForAllTargets')"
                                />
                            </template>
                        </span>
                        <Efficacy v-if="overallEffect" :amount="overallEffect || 0" />
                    </div>
                </template>

                <template #content>
                    <ul>
                        <li v-for="efficacy in efficacies">
                            {{ efficacy.name }}
                            <Efficacy :amount="efficacy.amount || 0" />
                        </li>
                    </ul>
                </template>
            </Accordion>
            <div v-else>
                <div
                    v-if="efficacies?.length > 0"
                    class="recommendation-card-header__effect-overall"
                >
                    <span>
                        <span>{{ $t('recommendations.overallEfficacy') }}</span>
                        <IconBadge
                            v-if="hasInefficientEfficacies"
                            :title="$t('recommendation.notEfficientForAllTargets')"
                        />
                    </span>
                    <Efficacy :amount="overallEffect || 0" />
                </div>
            </div>
        </template>
        <template v-else>
            <div
                class="recommendation-card-header__effect-overall"
                @click.prevent="onCharacteristicsClick"
            >
                <span>{{ $t('recommendations.characteristics') }}</span>
            </div>
        </template>
    </div>
</template>

<script>
import Efficacy from '@/components/atoms/Efficacy.vue';
import Accordion from '@/components/atoms/Accordion.vue';
import IconBadge from '@/components/atoms/IconBadge.vue';
import { mapActions, mapState } from 'pinia';
import { useSeedStore } from '@/stores/seed';
import SidebarContentTypes from '@/utils/constants/sidebar-content-types';

export default {
    name: 'RecommendationCardHeader',
    components: { IconBadge, Accordion, Efficacy },
    props: {
        adviceId: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        overallEffect: {
            type: Number,
            default: 0,
        },
        efficacies: {
            type: Array,
        },
        expandable: {
            type: Boolean,
            default: false,
        },
        isSeedCard: {
            type: Boolean,
            default: false,
        },
        hasNewTag: {
            type: Boolean,
            default: false,
        },
        seedProduct: {
            type: Object,
        },
    },
    computed: {
        ...mapState(useSeedStore, ['isInCompareMode', 'tankmixesForComparison']),

        classes() {
            return {
                'recommendation-card-header--expandable': this.expandable,
                'recommendation-card-header--no-efficacies': this.efficacies?.length === 0,
            };
        },
        isPartOfComparison() {
            return this.tankmixesForComparison.includes(this.adviceId);
        },
        hasInefficientEfficacies() {
            return this.efficacies.filter((e) => e.amount <= 1).length;
        },
        hasComparisonButton() {
            return this.isSeedCard && this.isInCompareMode;
        },
    },
    methods: {
        ...mapActions(useSeedStore, ['addOrRemoveTankmixForComparison']),

        onCharacteristicsClick() {
            const productName = this.$t(`products.${this.seedProduct?.id}.name`);

            this.$openSidebar(
                SidebarContentTypes.CHARACTERISTIC,
                `${this.$t('recommendations.characteristics')}: ${productName}`,
                {
                    product: this.seedProduct,
                },
            );
        },
        onCompareClick() {
            this.addOrRemoveTankmixForComparison(this.adviceId);
        },
    },
};
</script>
