<template>
    <div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ScrollSpyContainer',
    mounted() {
        // Sections register themselves on the parent.
        this.$parent.initSection(this.$el);
    },
};
</script>
