<template>
    <iframe class="iframe" :src="content?.url" />
</template>

<script>
export default {
    name: 'SidebarIFrame',
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
};
</script>
