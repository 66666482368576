import axios from 'axios';

// Axios instance for the ACE api.
export const aceApiClient = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
});

export const gmapsApiClient = axios.create({
    baseURL: import.meta.env.VITE_GOOGLE_MAPS_API_BASE_PATH,
});
