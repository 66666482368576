<template>
    <div class="selected-target-card" :class="{ 'selected-target-card--inactive': inactive }">
        <span class="selected-target-card__title">{{
                $t(
                    `targets.${
                        target[$translationMappingKey('targets')]
                    }.name`,
                )
            }}</span>
        <div
            class="selected-target-card__delete"
            @click.prevent="deletable ? onDeleteClick() : null"
        >
            <span v-if="inactive">{{ $t('targetsPage.restoreTarget') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectedTargetCard',
    emits: ['onDelete'],
    props: {
        target: {
            type: Object,
            required: true,
        },
        inactive: {
            type: Boolean,
            default: false,
        },
        deletable: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        onDeleteClick() {
            this.$emit('onDelete', { target: this.target, inactive: this.inactive });
        },
    },
};
</script>
