import { useLocalizationStore } from '@/stores/localization';
import { useAppStore } from '@/stores/app';

const sendPostMessage = (eventName, data = {}) => {
    // We always send the countryCode.
    const localizationStore = useLocalizationStore();
    data['countryCode'] = localizationStore.selectedCountry;

    // Event structure.
    const eventData = {
        source: 'agsf',
        action: eventName,
        data: data,
    };

    parent.postMessage(eventData);
};

// Attach function to @click handler of links.
// If the app is running in mobile version, postMessage will be sent and link will be prevented.
export const linkOverwriteFunction = (e, eventName, data) => {
    const appStore = useAppStore();

    if (appStore.isMobileVersion) {
        e.preventDefault();

        sendPostMessage(eventName, data);
    }
};
