<template>
    <div class="range-slider">
        <input
            :value="modelValue"
            type="range"
            :step="step"
            :min="min"
            :max="max"
            @input="onInput"
        />
    </div>
</template>

<script>
export default {
    name: 'RangeSlider',
    props: {
        modelValue: {},
        max: {
            type: Number,
            required: true,
        },
        min: {
            type: Number,
            default: 0,
        },
        step: {
            type: Number,
            default: 1,
        },
    },
    methods: {
        onInput(e) {
            this.$emit('onChange', e.target.value);
        },
    },
};
</script>
