<template>
    <template v-if="dataObject?.isEditable">
        <template v-if="type === 'number'">
            <InputField
                :label="$t(`seedRateCalculator.${identifier}Label`)"
                :hint="hint"
                :suffix="dataObject.unit"
                type="number"
                :disabled="!dataObject.isEditable"
                v-model="dataObject.value"
                showLockWhenDisabled
            />
        </template>
        <template v-else-if="type === 'select'">
            <div
                class="dropdown-menu-wrapper"
                :class="{ 'dropdown-menu-wrapper--has-hint': this.hint }"
            >
                <Dropdown
                    :label="$t(`seedRateCalculator.${identifier}Label`)"
                    :hint="hint"
                    :disabled="!dataObject.isEditable"
                    :items="dataObject.options"
                    v-model="dataObject.value"
                    :clearable="false"
                >
                    <template #item="{ item }">
                        {{ item }}
                    </template>
                    <template #head="{ items }">
                        {{ items[0] }}
                    </template>
                </Dropdown>
            </div>
        </template>
    </template>
</template>

<script>
import InputField from '@/components/atoms/InputField.vue';
import Dropdown from '@/components/atoms/Dropdown.vue';

export default {
    name: 'SeedCalculatorInput',
    components: { Dropdown, InputField },
    props: {
        identifier: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'number',
            validator: (val) => ['select', 'number'].includes(val),
        },
        dataObject: {
            type: Object,
            required: true,
        },
    },
    computed: {
        hint() {
            return this.$te(`seedRateCalculator.${this.identifier}Hint`) ||
                this.$te(`seedRateCalculator.${this.identifier}Hint`, 'en_ALL')
                ? this.$t(`seedRateCalculator.${this.identifier}Hint`)
                : null;
        },
    },
};
</script>
