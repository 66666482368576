<template>
    <i :title="title" class="icon-badge" />
</template>

<script>
export default {
    name: 'IconBadge',
    props: {
        title: {
            type: String,
        },
    },
};
</script>
