<template>
    <section
        v-if="$te(`static-content-omni.content`) || $te(`static-content-omni.content`, 'en_ALL')"
        ref="legalBox"
        class="legal-text"
        v-sub-tag-wrapper
    >
        <hr class="legal-text__divider" />
        <h5 class="legal-text__headline">{{ $t('static-content-omni.title') }}</h5>
        <div class="legal-text__content" v-html="$t('static-content-omni.content')"></div>
    </section>
</template>

<script>
export default {
    name: 'LegalText',
};
</script>
