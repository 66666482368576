import { defineStore } from 'pinia';
import SidebarContentTypes from '@/utils/constants/sidebar-content-types';

export const useSidebarStore = defineStore('sidebar', {
    state: () => ({
        show: false,
        wide: false,
        lowered: false,
        popup: false,
        type: null,
        title: null,
        content: null,
        persistent: false,
        timerStart: -1,
        onTimerFinish: null,
    }),
    actions: {
        openSidebar(
            type,
            title,
            content,
            wide = false,
            lowered = false,
            popup = false,
            persistent = false,
        ) {
            if (this.type === SidebarContentTypes.COUNTRY_CONFIRMATION_GATE) {
                return false;
            }

            this.type = type;
            this.title = title;
            this.content = content;
            this.wide = wide;
            this.lowered = lowered;
            this.popup = popup;
            this.persistent = persistent;

            this.show = true;

            return true;
        },
        openModalWithTimer(type, title, content, onFinish, time = -1) {
            const hasOpened = this.openSidebar(type, title, content, false, false, true);

            if (hasOpened) {
                this.timerStart = time;
                this.onTimerFinish = onFinish;
            }
        },
        hideSidebar() {
            this.type = null;
            this.show = false;
        },
        resetTimer() {
            this.timerStart = -1;
            this.onTimerFinish = null;
        },
    },
});
