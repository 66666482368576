<template>
    <div class="growth-stage-selector-item" @click="onClick">
        <div class="growth-stage-selector-item__image">
            <div v-html="computedImage" />
        </div>

        <span
            v-if="selectedCountryMeta.showGrowthStageCode"
            class="growth-stage-selector-item__code"
            >{{ growthStage.code }}</span
        >

        <div
            v-if="
                selectedCountryMeta.showGrowthStageDescription &&
                ($te(`growthStages.${growthStage.id}`) ||
                    $te(`growthStages.${growthStage.id}`, 'en_ALL'))
            "
            :title="$t(`growthStages.${growthStage.id}`)"
            class="growth-stage-selector-item__description"
        >
            <p>{{ $t(`growthStages.${growthStage.id}`) }}</p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';

export default {
    name: 'GrowthStageSelectorItem',
    props: {
        growthStage: {
            type: Object,
            required: true,
        },
        image: {
            type: [String, null],
            required: true,
        },
        illustrationMapping: {
            type: [Object, null],
            required: true,
        },
    },
    computed: {
        ...mapState(useLocalizationStore, ['selectedCountryMeta']),

        computedImage() {
            if (!this.illustrationMapping) {
                return null;
            }

            // Modify the SVG image based on the illustration mapping.
            const tempDiv = document.createElement('div');
            const idToLookFor = this.illustrationMapping[this.growthStage?.code];

            tempDiv.innerHTML = this.image;
            const gElements = tempDiv.querySelectorAll('svg > g');

            // Remove the <g> elements for other growth-stages.
            gElements.forEach((gElement) => {
                const gElId = gElement.id.replace('GS-', '').replace('ES', '');

                if (gElId !== idToLookFor) {
                    gElement.style.display = 'none';
                }
            });

            const html = tempDiv.innerHTML;
            tempDiv.remove();

            return html;
        },
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        },
    },
};
</script>
