<template>
    <div class="sidebar-navigation">
        <ul class="sidebar-navigation__links">
            <li v-for="link in menuLinks">
                <component
                    :is="link.external ? 'a' : 'RouterLink'"
                    :target="link.external ? '_blank' : '_self'"
                    v-bind="getLinkBinding(link)"
                    :class="{ disabled: $route?.name === 'no-service' }"
                    @click.native="link.external ? null : onServiceLinkClick()"
                >
                    <span>{{ link.label }}</span>
                    <i class="fa" :class="getIconClass(link)" />
                </component>
            </li>
        </ul>
        <div class="sidebar-navigation__footer">
            <span class="sidebar-navigation__footer-app-name">
                {{
                    $te('agsfApplicationName') || $te('agsfApplicationName', 'en_ALL')
                        ? $t('agsfApplicationName')
                        : 'AgSolutions Finder'
                }}
            </span>
            <ul class="sidebar-navigation__footer-links">
                <li v-for="link in footerLinks">
                    <component
                        :is="link.external ? 'a' : 'button'"
                        v-bind="link.external ? { href: link.to, target: '_blank' } : null"
                        @click="link.external ? null : onFooterLinkClick(link)"
                    >
                        <span>{{ link.label }}</span>
                    </component>
                </li>
                <li>
                    <Btn type="link" onclick="Optanon.ToggleInfoDisplay();">
                        {{ $t('oneTrust.openCookieSettings') }}
                    </Btn>
                </li>
            </ul>
            <div class="sidebar-navigation__footer-split">
                <span class="sidebar-navigation__footer-copyright">
                    {{ $t('burgerMenu.copyright') }} BASF SE {{ year }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';
import SidebarContentTypes from '@/utils/constants/sidebar-content-types';
import AllowedUrlParameters from '@/utils/constants/allowed-url-parameters';
import { track } from '@/utils/utag';
import { useTeaserStore } from '@/stores/teaser';
import { insertStaticContentData } from '@/utils/staticContentRteInserter';
import TrackingTypes from '@/utils/constants/trackingTypes';
import Btn from '@/components/atoms/Btn.vue';

export default {
    name: 'SidebarNavigation',
    components: { Btn },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState(useLocalizationStore, [
            'selectedCountry',
            'selectedLanguage',
            'legalContent',
            'selectedCountryMeta',
        ]),
        ...mapState(useTeaserStore, ['serviceTeasers', 'getServiceLink', 'getTeaserTranslation']),

        menuLinks() {
            const items = [];

            if (this.selectedCountryMeta?.hasCropProtection) {
                items.push({
                    label: this.$t('burgerMenu.cropProtection'),
                    to: 'crop-protection.form',
                    external: false,
                });
            }
            if (this.selectedCountryMeta?.hasSeedProtection) {
                items.push({
                    label: this.$t('burgerMenu.seeds'),
                    to: 'seeds.form',
                    external: false,
                });
            }

            const externalTeasers = this.serviceTeasers.filter((t) => t.target === 'external');

            items.push(
                ...externalTeasers.map((t) => ({
                    label: this.getTeaserTranslation(t.title),
                    to: this.getServiceLink(t),
                    external: true,
                })),
            );

            return items;
        },
        footerLinks() {
            if (!this.legalContent) return [];

            const content = this.legalContent.map((e) => {
                const isLink = e.type === 'link';
                const target = isLink ? e.linkUrl[this.selectedLanguage?.locale] : '';

                return {
                    label: e.title[this.selectedLanguage?.locale],
                    to: target,
                    external: isLink,
                    content: e.content[this.selectedLanguage?.locale],
                    isPrivacyPolicy: e.isPrivacyPolicy,
                };
            });

            content.sort((a, b) => a.position - b.position);

            return content;
        },
        year() {
            return new Date().getFullYear();
        },
    },
    methods: {
        getIconClass(link) {
            if (link.external) {
                return 'fa-arrow-up-right';
            }
            return 'fa-arrow-right';
        },
        onFooterLinkClick(link) {
            this.$hideSidebar();
            setTimeout(() => {
                track(
                    {
                        page_name: link.label,
                        page_category: TrackingTypes.Category.General,
                        page_subcategory: '',
                        page_country: this.selectedCountry,
                        page_language: this.selectedLanguage.language,
                        page_type: TrackingTypes.Type.Supplementary,
                        page_privacy_policy: link.isPrivacyPolicy,
                    },
                    false,
                );

                this.$openSidebar(
                    SidebarContentTypes.RTE_TEXT,
                    link.label,
                    { text: insertStaticContentData(link.content) },
                    true,
                    false,
                    true,
                );
            }, 0);
        },
        onServiceLinkClick() {
            this.$hideSidebar();
            // TODO :: For Seeds we probably need logic here (skip crops page only 1 available crop)
        },
        getLinkBinding(link) {
            const filteredQuery = Object.keys(this.$route.query)
                .filter((key) => AllowedUrlParameters.includes(key))
                .reduce((obj, key) => {
                    obj[key] = this.$route.query[key];
                    return obj;
                }, {});

            return link.external
                ? { href: link.to }
                : {
                      to: {
                          name: link.to,
                          params: { country: this.selectedCountry },
                          query: filteredQuery,
                      },
                  };
        },
    },
};
</script>
