<template>
    <div class="input-field-wrapper">
        <Field
            :name="identifier || ''"
            :rules="rules"
            :type="type"
            :modelValue="modelValue"
            v-slot="{ errorMessage, value, field }"
        >
            <div class="input-field" :class="classes(errorMessage)">
                <span v-if="label" class="input-field__label">{{ label }}</span>
                <div v-if="icon" class="input-field__icon fas" :class="icon"></div>
                <component
                    :is="multiline ? 'textarea' : 'input'"
                    :type="type"
                    class="input-field__input"
                    :value="modelValue"
                    v-bind="field"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    @input="onInput"
                />
                <span v-if="suffix" class="input-field__suffix">
                    {{ suffix }}
                </span>
                <button
                    v-if="clearable && modelValue?.length > 0"
                    type="button"
                    class="fas fa-trash-alt input-field__clear"
                    @click.prevent="onClearClick"
                ></button>
            </div>
            <span v-if="errorMessage" class="input-field-error">
                {{ $t(`validationError.${errorMessage}`) }}
            </span>
        </Field>
        <div v-if="hint" class="input-field-wrapper__hint">
            {{ hint }}
        </div>
    </div>
</template>

<script>
import { Field } from 'vee-validate';

export default {
    name: 'InputField',
    components: { Field },
    emits: ['update:modelValue', 'onClear'],
    props: {
        modelValue: {},
        identifier: {
            type: String,
        },
        type: {
            type: String,
            default: 'text',
        },
        multiline: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        placeholder: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showLockWhenDisabled: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: [Object, String],
            default: {},
        },
        hint: {
            type: String,
        },
        suffix: {
            type: String,
        },
    },
    methods: {
        onInput(e) {
            this.$emit('update:modelValue', e.target.value);
        },
        onClearClick() {
            this.$emit('onClear');
        },
        classes(errorMessage) {
            return {
                'input-field--clearable': this.clearable,
                'input-field--multiline': this.multiline,
                'input-field--has-label': this.label,
                'input-field--can-clear': this.clearable && this.modelValue?.length > 0,
                'input-field--has-error': errorMessage,
                'input-field--disabled': this.disabled,
                'input-field--has-lock': this.showLockWhenDisabled,
            };
        },
    },
};
</script>
