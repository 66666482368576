import {filter, has, isObject} from "lodash";

export function findObjectsInNestedStructure(obj, key, value) {
  const objects = [];

  const _findNestedRecursive = (obj, key, value) => {
    if (isObject(obj)) {
      if (has(obj, key) && obj[key] === value) {
        objects.push(obj);
        return true;
      }

      return filter(obj, (item) => _findNestedRecursive(item, key, value));
    }

    return null;
  };

  _findNestedRecursive(obj, key, value);

  return objects;
}
