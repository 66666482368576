<template>
    <div class="decision-tree">
        <TreeParameter
            v-if="parameterConfiguration && isParamConfigValid"
            :tree="mergedTree"
            ref="tree"
            @treeChange="onTreeChange"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import TreeParameter from '@/components/molecules/TreeParameter.vue';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import { mergeTree } from '@/utils/tree-helpers';

export default {
    name: 'DecisionTree',
    components: { TreeParameter },
    props: {
        parameterConfiguration: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.createParameterTreeFields();
            this.validateParameterConfiguration();
        });
    },
    computed: {
        ...mapState(useConfigurationFormStore, ['enteredTreeValues']),

        tree() {
            return this.parameterConfiguration.recommendationFlowParameters;
        },
        mergedTree() {
            return mergeTree(
                this.tree,
                this.enteredTreeValues,
                this.parameterConfiguration.recommendationFlowParameterOrder,
            );
        },
        isParamConfigValid() {
            return this.parameterConfiguration && this.tree && Object.keys(this.tree).length > 0;
        },
    },
    methods: {
        ...mapActions(useConfigurationFormStore, [
            'setValueByParameterIdentifier',
            'checkForValuesWithInactiveParameters',
            'removeHiddenFieldFromUserInput',
            'createParameterTreeFields',
            'validateParameterConfiguration',
        ]),

        resetTree() {
            if (!this.$refs.tree) return;

            this.$refs.tree.resetToInitialState();
        },
        onTreeChange() {
            this.$nextTick(this.validateParameterConfiguration);
        },
    },
};
</script>
