<template>
    <div class="accordion" :class="classes">
        <div v-if="$slots.head" class="accordion__head" ref="head" @click.prevent="onHeadClick">
            <slot name="head" v-bind:opened="opened" />
        </div>
        <div class="accordion__content" ref="content">
            <slot name="content" />
        </div>
        <div
            v-if="$slots.footer"
            class="accordion__footer"
            ref="footer"
            @click.prevent="onHeadClick"
        >
            <slot name="footer" v-bind:opened="opened" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Accordion',
    emits: ['onOpened', 'onClosed'],
    props: {
        enableInteraction: {
            type: Boolean,
            default: true,
        },
        initiallyOpen: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (!this.enableInteraction || this.initiallyOpen) {
            this.opened = true;
            this.updateContentExpand(this.opened);
        }
    },
    data() {
        return {
            opened: false,
        };
    },
    computed: {
        classes() {
            return {
                'accordion--open': this.opened,
                'accordion--disabled': !this.enableInteraction,
            };
        },
    },
    methods: {
        updateContentExpand(opened) {
            const content = this.$refs.content;

            if (!content) return;

            if (opened) {
                content.style.height = `${content.scrollHeight}px`;
                content.style.opacity = '1';

                this.$emit('onOpened');
            } else {
                content.style.height = '0';
                content.style.opacity = '0';

                this.$emit('onClosed');
            }
        },
        onHeadClick() {
            if (!this.enableInteraction) return;

            this.opened = !this.opened;
            this.updateContentExpand(this.opened);
        },
    },
};
</script>
