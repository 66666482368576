<template>
    <div
        v-if="selectedTargets.length"
        class="selected-targets-list"
        :class="{ 'selected-targets-list--xs': xs }"
        @click="disableInteraction ? openSelectedTargetsSidebar() : null"
    >
        <div class="selected-targets-list__targets">
            <div v-for="target in selectedTargets">
                <SelectedTargetCard
                    :target="target"
                    :inactive="isTargetDisabled(target.id) && !disableInteraction"
                    :deletable="!disableInteraction"
                    @onDelete="onDeleteTarget"
                />
            </div>
        </div>
        <div
            v-if="hasSidebarBtn"
            class="selected-targets-list__btn"
            @click="openSelectedTargetsSidebar"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import SelectedTargetCard from '@/components/atoms/SelectedTargetCard.vue';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import SidebarContentTypes from '@/utils/constants/sidebar-content-types';

export default {
    name: 'SelectedTargetsList',
    components: { SelectedTargetCard },
    props: {
        xs: {
            type: Boolean,
            default: false,
        },
        hasSidebarBtn: {
            type: Boolean,
            default: true,
        },
        dontRemoveTargetsFromList: {
            type: Boolean,
            default: false,
        },
        disableInteraction: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(useConfigurationFormStore, [
            'selectedTargets',
            'isTargetDisabled',
            'userDisabledTargets',
        ]),
    },
    methods: {
        ...mapActions(useConfigurationFormStore, [
            'setSelectedTargets',
            'setUserDisabledTargets',
            'removeDisabledTarget',
        ]),

        onDeleteTarget({ target }) {
            if (!this.dontRemoveTargetsFromList) {
                const selected = this.selectedTargets;

                selected.splice(
                    selected.findIndex((t) => t.id === target.id),
                    1,
                );

                this.setSelectedTargets(selected);
                this.removeDisabledTarget(target.id);
            } else {
                const newDisabledTargets = this.userDisabledTargets;

                if (newDisabledTargets.includes(target.id)) {
                    this.removeDisabledTarget(target.id);
                } else {
                    newDisabledTargets.push(target.id);
                }

                this.setUserDisabledTargets(newDisabledTargets);
            }
        },
        openSelectedTargetsSidebar() {
            this.$openSidebar(
                SidebarContentTypes.SELECTED_TARGETS_MOBILE,
                this.$t('bottomBar.selectedTargets'),
            );
        },
    },
};
</script>
