<template>
    <div ref="sidebarWrapper" class="sidebar-wrapper" :class="wrapperClasses" :style="wrapperStyle">
        <transition
            name="move"
            @beforeEnter="onTransitionStart"
            @afterEnter="onTransitionEnd"
            @beforeLeave="onTransitionStart"
            @afterLeave="onTransitionEnd"
        >
            <aside v-if="show" class="sidebar" ref="sidebar">
                <div class="sidebar__head">
                    <span class="sidebar__head-title">
                        {{ title }}
                    </span>
                    <button
                        v-if="!persistent"
                        type="button"
                        class="sidebar__head-close-btn"
                        @click="hideSidebar"
                    />
                </div>
                <div class="sidebar__content">
                    <component :is="sidebarContentComponent" v-bind="sidebarContentProps" />
                </div>
                <div class="sidebar__progress">
                    <div class="sidebar__progress-fill" :style="{ width: `${timerProgress}%` }" />
                </div>
            </aside>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useSidebarStore } from '@/stores/sidebar';
import SidebarContentTypes from '@/utils/constants/sidebar-content-types';
import SidebarNavigation from '@/components/molecules/Sidebar/Elements/SidebarNavigation.vue';
import SidebarText from '@/components/molecules/Sidebar/Elements/SidebarText.vue';
import SidebarIFrame from '@/components/molecules/Sidebar/Elements/SidebarIFrame.vue';
import SidebarTargets from '@/components/molecules/Sidebar/Elements/SidebarTargets.vue';
import SidebarTargetsMobile from '@/components/molecules/Sidebar/Elements/SidebarTargetsMobile.vue';
import SidebarCropStrategy from '@/components/molecules/Sidebar/Elements/SidebarCropStrategy.vue';
import SidebarDeeplink from '@/components/molecules/Sidebar/Elements/SidebarDeeplink.vue';
import SidebarEmailForm from '@/components/molecules/Sidebar/Elements/SidebarEmailForm.vue';
import SidebarGrowingConditions from '@/components/molecules/Sidebar/Elements/SidebarGrowingConditions.vue';
import SidebarCharacteristic from '@/components/molecules/Sidebar/Elements/SidebarCharacteristic.vue';
import SidebarCompareTankmix from '@/components/molecules/Sidebar/Elements/SidebarCompareTankmix.vue';
import SidebarSeedRateCalculator from '@/components/molecules/Sidebar/Elements/SidebarSeedRateCalculator.vue';
import SidebarConfirmationGate from '@/components/molecules/Sidebar/Elements/SidebarConfirmationGate.vue';
import ScrollLock from '@/utils/scrollLock';
import { isInIframe } from '@/utils/helpers';

export default {
    name: 'Sidebar',
    components: {
        SidebarNavigation,
        SidebarText,
        SidebarCropStrategy,
        SidebarTargets,
        SidebarTargetsMobile,
        SidebarIFrame,
        SidebarEmailForm,
        SidebarDeeplink,
        SidebarGrowingConditions,
        SidebarCompareTankmix,
        SidebarCharacteristic,
        SidebarSeedRateCalculator,
        SidebarConfirmationGate,
    },
    data() {
        return {
            timer: 0,
            timerInterval: null,
            animationFrameId: null,
            inTransition: false,
        };
    },
    computed: {
        ...mapState(useSidebarStore, [
            'show',
            'title',
            'type',
            'content',
            'wide',
            'lowered',
            'popup',
            'persistent',
            'timerStart',
            'onTimerFinish',
        ]),

        sidebarContentComponent() {
            if (this.type === SidebarContentTypes.RTE_TEXT) {
                return 'SidebarText';
            } else if (this.type === SidebarContentTypes.CROP_STRATEGY) {
                return 'SidebarCropStrategy';
            } else if (this.type === SidebarContentTypes.SELECTED_TARGETS) {
                return 'SidebarTargets';
            } else if (this.type === SidebarContentTypes.SELECTED_TARGETS_MOBILE) {
                return 'SidebarTargetsMobile';
            } else if (this.type === SidebarContentTypes.IFRAME) {
                return 'SidebarIFrame';
            } else if (this.type === SidebarContentTypes.NAVIGATION) {
                return 'SidebarNavigation';
            } else if (this.type === SidebarContentTypes.EMAIL_FORM) {
                return 'SidebarEmailForm';
            } else if (this.type === SidebarContentTypes.DEEPLINK) {
                return 'SidebarDeeplink';
            } else if (this.type === SidebarContentTypes.GROWING_CONDITIONS) {
                return 'SidebarGrowingConditions';
            } else if (this.type === SidebarContentTypes.CHARACTERISTIC) {
                return 'SidebarCharacteristic';
            } else if (this.type === SidebarContentTypes.SEED_RATE_CALCULATOR) {
                return 'SidebarSeedRateCalculator';
            } else if (this.type === SidebarContentTypes.COMPARE_TANKMIX) {
                return 'SidebarCompareTankmix';
            } else if (this.type === SidebarContentTypes.COUNTRY_CONFIRMATION_GATE) {
                return 'SidebarConfirmationGate';
            }
        },
        sidebarContentProps() {
            if (this.type === SidebarContentTypes.RTE_TEXT) {
                return { content: this.content };
            } else if (this.type === SidebarContentTypes.CROP_STRATEGY) {
                return { content: this.content };
            } else if (this.type === SidebarContentTypes.SELECTED_TARGETS) {
                return { content: this.content };
            } else if (this.type === SidebarContentTypes.SELECTED_TARGETS_MOBILE) {
                return { content: this.content };
            } else if (this.type === SidebarContentTypes.IFRAME) {
                return { content: this.content };
            } else if (this.type === SidebarContentTypes.NAVIGATION) {
                return { content: this.content };
            } else if (this.type === SidebarContentTypes.EMAIL_FORM) {
                return { content: this.content };
            } else if (this.type === SidebarContentTypes.DEEPLINK) {
                return { content: this.content };
            } else if (this.type === SidebarContentTypes.GROWING_CONDITIONS) {
                return {};
            } else if (this.type === SidebarContentTypes.CHARACTERISTIC) {
                return { content: this.content };
            } else if (this.type === SidebarContentTypes.COMPARE_TANKMIX) {
                return { content: this.content };
            } else if (this.type === SidebarContentTypes.SEED_RATE_CALCULATOR) {
                return { content: this.content };
            } else if (this.type === SidebarContentTypes.COUNTRY_CONFIRMATION_GATE) {
                return {};
            }

            return {};
        },
        wrapperClasses() {
            return {
                'sidebar-wrapper--sidebar-open': this.show,
                'sidebar-wrapper--sidebar-wide': this.wide,
                'sidebar-wrapper--sidebar-lowered': this.lowered,
                'sidebar-wrapper--sidebar-popup': this.popup,
                [`sidebar--${this.type}`]: true,
            };
        },
        wrapperStyle() {
            return {
                overflow: this.inTransition ? 'hidden' : 'visible',
            };
        },
        timerProgress() {
            let percentage = ((this.timerStart - this.timer) / this.timerStart) * 100;

            if (percentage === 100) percentage = 0;

            return percentage;
        },
    },
    methods: {
        ...mapActions(useSidebarStore, ['hideSidebar', 'resetTimer']),

        onOutsideClick(event) {
            if (this.persistent) {
                return;
            }

            const allowedClickElementClasses = ['v-dropdown-menu__item'];
            const sidebar = this.$refs.sidebar;

            if (
                !sidebar ||
                !this.show ||
                [...event.target.classList].some((c) => allowedClickElementClasses.includes(c))
            )
                return;

            if (!(sidebar === event.target || sidebar.contains(event.target))) {
                this.hideSidebar();
            }
        },
        startTimer(time = 10, callback = null) {
            this.timer = time;
            const startTime = performance.now();
            const duration = time * 1000;

            const updateTimer = () => {
                const currentTime = performance.now();
                const elapsed = currentTime - startTime;
                const remainingTime = duration - elapsed;

                this.timer = Math.max(0, remainingTime / 1000);

                if (remainingTime > 0) {
                    this.animationFrameId = requestAnimationFrame(updateTimer);
                } else {
                    if (callback) callback();
                    this.resetTimer();
                    this.hideSidebar();
                }
            };

            this.animationFrameId = requestAnimationFrame(updateTimer);
        },
        onTransitionStart() {
            this.inTransition = true;
        },
        onTransitionEnd() {
            this.inTransition = false;
        },
    },
    watch: {
        show(oldVal, newVal) {
            setTimeout(() => {
                const appElement = document.querySelector('#app');

                if (!newVal) {
                    if (isInIframe()) {
                        // Scrolling in iframe has to be handled differently.
                        const parentEl = window.document.querySelector('#app');
                        this.$refs.sidebarWrapper.style.top = `${parentEl.scrollTop}px`;
                    } else {
                        this.$refs.sidebarWrapper.style.top = `${window.scrollY}px`;
                    }


                    appElement.addEventListener('click', this.onOutsideClick);
                    ScrollLock.lock();

                    if (this.timerStart !== -1) {
                        this.startTimer(this.timerStart, this.onTimerFinish);
                    }
                } else {
                    if (this.animationFrameId) {
                        cancelAnimationFrame(this.animationFrameId);
                        this.animationFrameId = null;
                    }

                    appElement.removeEventListener('click', this.onOutsideClick);
                    ScrollLock.unlock();
                }
            }, 0);
        },
    },
};
</script>
