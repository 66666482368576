<template>
    <section class="parameter-section form-section">
        <div class="parameter-section__title">
            <slot v-if="$slots.title" name="title"></slot>
            <ParameterLabel
                v-else
                :title="title"
                :sidePanelContent="sidePanelContent"
                :required="required"
            />
        </div>
        <div class="parameter-section__content">
            <slot name="content"></slot>
        </div>
        <slot name="nextParameter"></slot>
    </section>
</template>

<script>
import ParameterLabel from '@/components/atoms/ParameterLabel.vue';

export default {
    name: 'ParameterSection',
    components: { ParameterLabel },
    props: {
        title: {
            type: String,
        },
        required: {
            type: Boolean,
            default: true,
        },
        sidePanelContent: {
            type: [Object, String],
        },
    },
};
</script>
