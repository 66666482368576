<template>
    <button class="option-card" :class="classes" type="button">
        <slot name="image"></slot>
        <slot name="title"></slot>
    </button>
</template>

<script>
export default {
    name: 'OptionCard',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                'option-card--active': this.active,
            };
        },
    },
};
</script>

<style lang="scss">
.option-card {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;

    img {
        margin-right: 10px;
    }
}
.option-card--active {
    color: green;
    border-color: green;
}
</style>
