<template>
    <component
        :is="getComponent"
        :target="isExternal ? '_blank' : ''"
        class="service-teaser"
        :class="classes"
        v-bind="isExternal ? { href: link } : { to: link }"
        @click="onTeaserClick(link)"
    >
        <div class="service-teaser__content">
            <span v-if="category">
                <img v-if="icon" alt="" :src="icon" />
                {{ category }}
            </span>
            <h4>{{ title }}</h4>
            <p>
                {{ text }}
            </p>

            <div class="service-teaser__content-link-text">
                {{ linkText }}
            </div>
        </div>
        <div class="service-teaser__badge">
            {{ $t('services.currentService') }}
        </div>
    </component>
</template>

<script>
import FormStepKeys from '@/utils/constants/form-step-keys';
import { mapActions } from 'pinia';
import { useConfigurationFormStore } from '@/stores/configuration-form';

export default {
    name: 'ServiceTeaser',
    props: {
        isActive: {
            type: Boolean,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        link: {
            type: [String, Object],
            required: true,
        },
        linkText: {
            type: String,
            required: true,
        },
        isExternal: {
            type: Boolean,
            required: false,
        },
        category: {
            type: String,
        },
        icon: {
            type: String,
        },
    },
    computed: {
        classes() {
            return {
                'service-teaser--active': this.isActive,
                'service-teaser--external': this.isExternal,
            };
        },
        getComponent() {
            if (!this.isExternal) {
                return 'router-link';
            } else {
                return 'a';
            }
        },
    },
    methods: {
        ...mapActions(useConfigurationFormStore, ['setCurrentStep']),

        onTeaserClick(link) {
            if (link.name === 'crop-protection.form' || link.name === 'seeds.form') {
                this.setCurrentStep(FormStepKeys.CROP);
            }
        },
    },
};
</script>
