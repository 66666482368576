<template>
    <div class="notification notification-box notification--warning">
        <i class="notification__icon" />

        <div class="notification__content">
            <div v-html="text" />
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotificationBox',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
    methods: {
        onCloseClick() {},
    },
};
</script>
