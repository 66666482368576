import { defineStore } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';
import SidebarContentTypes from '@/utils/constants/sidebar-content-types';

export const useConfirmationGateStore = defineStore('confirmation-gate', {
    persist: {
        storage: localStorage,
        pick: ['confirmedCountries'],
    },
    state: () => ({
        confirmedCountries: {},
        localizationStore: useLocalizationStore(),
    }),
    actions: {
        checkConfirmationGateState() {
            this.fillUpRemainingCountries();

            if (!this.localizationStore.confirmationGateData) return;

            if (
                !this.confirmedCountries?.hasOwnProperty(this.localizationStore.selectedCountry) ||
                !this.confirmedCountries[this.localizationStore.selectedCountry]
            ) {
                let title = '';

                if (
                    this.localizationStore.confirmationGateData?.title?.hasOwnProperty(
                        this.$i18n.locale,
                    )
                ) {
                    title = this.localizationStore.confirmationGateData?.title[this.$i18n.locale];
                } else {
                    title =
                        this.localizationStore.confirmationGateData?.title[
                            Object.keys(this.localizationStore.confirmationGateData?.title)[0]
                        ];
                }

                this.$openSidebar(
                    SidebarContentTypes.COUNTRY_CONFIRMATION_GATE,
                    title,
                    {},
                    true,
                    false,
                    true,
                    true,
                );
            }
        },
        confirmCountry(countryCode) {
            this.confirmedCountries[countryCode] = true;
        },
        fillUpRemainingCountries() {
            // Make sure all countries are in the list.
            this.localizationStore?.countries.forEach((country) => {
                if (!this.confirmedCountries?.hasOwnProperty(country.contraction)) {
                    this.confirmedCountries[country.contraction] = false;
                }
            });
        },
    },
});
