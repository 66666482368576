<template>
    <form class="multi-form" ref="form" @submit.prevent="null">
        <slot></slot>
        <LegalText ref="legalBox" />
    </form>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';
import { mapState } from 'pinia';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import LegalText from '@/components/atoms/LegalText.vue';

export default {
    name: 'MultiForm',
    emits: ['onStepChanged', 'onSubmit'],
    expose: [
        'activeStepIndex',
        'onForwardsClick',
        'onBackwardsClick',
        'canGoForwards',
        'canGoBackwards',
    ],
    components: { LegalText, Btn },
    computed: {
        ...mapState(useConfigurationFormStore, [
            'formSteps',
            'currentFormStep',
            'currentFormStepData',
        ]),
    },
    watch: {
        currentFormStep(_) {
            this.$emit('onStepChanged', {
                step: this.currentFormStepData,
                index: this.currentFormStep,
            });
        },
    },
};
</script>
