<template>
    <div class="growth-stage-selector-navigation">
        <div class="growth-stage-selector-navigation__input">
            <RangeSlider :modelValue="modelValue" :max="growthStageCount - 1" @onChange="onInput" />
        </div>
    </div>
</template>

<script>
import RangeSlider from '@/components/atoms/RangeSlider.vue';

export default {
    name: 'GrowthStageSelectorNavigation',
    components: { RangeSlider },
    props: {
        modelValue: {},
        growthStageCount: {
            type: Number,
            required: true,
        },
    },
    methods: {
        onInput(val) {
            this.$emit('onChange', val);
        },
    },
};
</script>
