<template>
    <div class="sidebar-confirmation-gate">
        <StaticTextContainer>
            <p v-html="content" />
        </StaticTextContainer>

        <div class="sidebar-confirmation-gate__footer">
            <div class="sidebar-confirmation-gate__footer-check">
                <Toggle
                    v-model="isCheckboxChecked"
                    :label="$t('countryConfirmationGate.checkboxText')"
                ></Toggle>
            </div>
            <div class="sidebar-confirmation-gate__footer-buttons">
                <Btn type="green" :disabled="!isCheckboxChecked" @click="onAcceptClick">
                    {{ $t('countryConfirmationGate.acceptBtnText') }}
                </Btn>
                <Btn type="white" @click="onDeclineClick">
                    {{ $t('countryConfirmationGate.declineBtnText') }}
                </Btn>
            </div>
        </div>
    </div>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';
import StaticTextContainer from '@/components/atoms/StaticTextContainer.vue';
import Toggle from '@/components/atoms/Toggle.vue';
import { mapActions, mapState } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';
import { useConfirmationGateStore } from '@/stores/confirmation-gate';

export default {
    name: 'SidebarConfirmationGate',
    components: { Toggle, Btn, StaticTextContainer },
    data() {
        return {
            isCheckboxChecked: false,
        };
    },
    created() {
        this.$nextTick(() => (this.isCheckboxChecked = false));

        if (
            this.$route.name === 'no-service' &&
            this.confirmedCountries?.hasOwnProperty(this.selectedCountry) &&
            this.confirmedCountries[this.selectedCountry]
        ) {
            this.$router.push({
                name: 'service-select',
                params: this.$route.params,
                query: this.$route.query,
            });
        }
    },
    computed: {
        ...mapState(useLocalizationStore, ['selectedCountry', 'confirmationGateData']),
        ...mapState(useConfirmationGateStore, ['confirmedCountries']),

        content() {
            if (this.confirmationGateData?.content?.hasOwnProperty(this.$i18n.locale)) {
                return this.confirmationGateData?.content[this.$i18n.locale];
            }
            return this.confirmationGateData?.content[
                Object.keys(this.confirmationGateData?.content)[0]
            ];
        },
    },
    methods: {
        ...mapActions(useConfirmationGateStore, ['confirmCountry']),

        onAcceptClick() {
            this.confirmCountry(this.selectedCountry);
            this.$hideSidebar();

            // If on no-service page, redirect to service select.
            if (this.$route.name === 'no-service') {
                this.$router.push({
                    name: 'service-select',
                    params: this.$route.params,
                    query: this.$route.query,
                });
            }
        },
        onDeclineClick() {
            this.$router.push({
                name: 'no-service',
                params: this.$route.params,
                query: this.$route.query,
            });
            this.$hideSidebar();
        },
    },
};
</script>
