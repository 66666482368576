<template>
    <div class="notification" :class="notificationTypeClass" v-if="$route.name !== 'no-service'">
        <i class="notification__icon" />

        <div class="notification__content">
            <div v-html="notification.text" />
            <button type="button" class="notification__content-close-btn" @click="onCloseClick" />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useNotificationsStore } from '@/stores/notifications';

export default {
    name: 'Notification',
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    computed: {
        notificationTypeClass() {
            return `notification--${this.notification.type}`;
        },
    },
    methods: {
        ...mapActions(useNotificationsStore, ['removeNotification']),

        onCloseClick() {
            this.removeNotification(this.notification.id);
        },
    },
};
</script>
