<template>
    <div class="crop-strategy">
        <div class="crop-strategy__header">
            <strong>{{ content.title }}</strong>
            <div class="crop-strategy__header-buttons">
                <Btn
                    type="translucent"
                    icon="fa-download"
                    iconPosition="start"
                    @click.prevent="onDownloadImageClick"
                    >{{ $t('sidePanel.downloadImage') }}
                </Btn>
                <Btn
                    type="translucent"
                    icon="fa-arrows-maximize"
                    iconPosition="start"
                    @click.prevent="onExpandImageClick"
                    >{{ $t('sidePanel.expandImage') }}
                </Btn>
            </div>
        </div>
        <div class="crop-strategy__image-wrapper">
            <img :src="content?.image" alt="" />
        </div>
    </div>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';

export default {
    name: 'SidebarCropStrategy',
    components: { Btn },
    data() {
        return {
            downloadName: 'crop-strategy.jpg',
        };
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    methods: {
        async onDownloadImageClick() {
            // Download the image.
            const a = document.createElement('a');
            a.href = this.content.image;
            a.download = this.downloadName;
            a.click();
            a.remove();
        },

        onExpandImageClick() {
            // Open new tab and write <img> to it.
            const newTab = window.open();

            newTab?.document.write(`<img src=${this.content.image}>`);
            newTab?.document.close();
        },
    },
};
</script>
