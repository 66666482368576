<template>
    <!--Exception: Location autocomplete-->
    <LocationAutocomplete
        v-if="parameterIdentifier === 'hasAdviceRegion'"
        :country="selectedCountry"
        :modelValue="savedValue"
        :placeholder="placeholder"
        :addressComponent="selectedCountryMeta?.googleApiRegionLevel"
        :validLocations="possibleOptions"
        @onInvalidAddressSelected="onInvalidAddressSelected"
        @update:modelValue="onInput"
    />

    <!--Single-/ Multi Select-->
    <OptionSelectList
        v-else-if="
            !limitInputTypes &&
            (inputType === allInputTypes.SINGLE_SELECT ||
                inputType === allInputTypes.MULTI_SELECT) &&
            possibleOptions.length < 7
        "
        :options="possibleOptions"
        :multiple="inputType === allInputTypes.MULTI_SELECT"
        :emptyValue="canBeEmpty ? '' : null"
        :name="parameterIdentifier"
        :key="`${parameterIdentifier}--options-list`"
        :modelValue="savedValue"
        grid
        :gridColumns="3"
        @update:modelValue="onInput"
    >
        <template #content="props">
            <SelectButton
                :title="getParameterValueTranslation(parameterIdentifier, props.item)"
                :checkbox="inputType === allInputTypes.MULTI_SELECT"
                :active="props.active"
                :image="getValueIcon(props.item)"
                isSVGImage
            />
        </template>
    </OptionSelectList>

    <!--Dropdown for 7+ Options-->
    <Dropdown
        v-else-if="
            inputType === allInputTypes.SINGLE_AUTOCOMPLETE ||
            inputType === allInputTypes.MULTI_AUTOCOMPLETE ||
            ((inputType === allInputTypes.SINGLE_SELECT ||
                inputType === allInputTypes.MULTI_SELECT) &&
                possibleOptions.length >= 7) ||
            (limitInputTypes &&
                (inputType === allInputTypes.SINGLE_SELECT ||
                    inputType === allInputTypes.MULTI_SELECT))
        "
        :label="customLabel"
        :items="possibleOptions"
        :modelValue="savedValue"
        :placeholder="placeholder"
        :multiple="
            inputType === allInputTypes.MULTI_SELECT ||
            inputType === allInputTypes.MULTI_AUTOCOMPLETE
        "
        :filterFunction="
            (item, term) =>
                getParameterValueTranslation(parameterIdentifier, item)
                    .toLowerCase()
                    .includes(term.toLowerCase())
        "
        @update:modelValue="onInput"
    >
        <template #item="{ item }">
            {{ getParameterValueTranslation(parameterIdentifier, item) }}
        </template>
        <template #head="{ items }">
            <span v-if="items.length" v-for="item in items">
                {{ getParameterValueTranslation(parameterIdentifier, item) }}
            </span>
        </template>
    </Dropdown>

    <!--Toggle-->
    <Toggle
        v-else-if="inputType === allInputTypes.BOOLEAN"
        :identifier="parameterIdentifier"
        :key="`${parameterIdentifier}--options-toggle`"
        :modelValue="savedValue?.length ? savedValue[0] : false"
        :label="customLabel"
        :hasBackground="hasToggleBackground"
        @update:modelValue="onInput"
    />

    <!--Number-->
    <InputField
        v-else-if="inputType === allInputTypes.NUMBER"
        type="number"
        :key="`${parameterIdentifier}--number-input`"
        :modelValue="savedValue"
        @update:modelValue="onInput"
    />
</template>

<script>
import Dropdown from '@/components/atoms/Dropdown.vue';
import OptionSelectList from '@/components/molecules/OptionSelectList.vue';
import InputField from '@/components/atoms/InputField.vue';
import SelectButton from '@/components/atoms/SelectButton.vue';
import Toggle from '@/components/atoms/Toggle.vue';
import ParameterInputTypes from '@/utils/constants/parameter-input-types';
import LocationAutocomplete from '@/components/atoms/LocationAutocomplete.vue';
import { mapActions, mapState } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';
import { useConfigurationFormStore } from '@/stores/configuration-form';

export default {
    name: 'ParameterInputWrapper',
    components: {
        LocationAutocomplete,
        Toggle,
        SelectButton,
        InputField,
        OptionSelectList,
        Dropdown,
    },
    props: {
        customLabel: {
            type: String,
        },
        onInput: {
            required: true,
        },
        savedValue: {
            required: true,
        },
        parameterId: {
            required: true,
        },
        parameterIdentifier: {
            required: true,
        },
        possibleOptions: {
            required: true,
        },
        canBeEmpty: {
            type: Boolean,
            required: true,
        },
        inputType: {
            required: true,
        },
        limitInputTypes: {
            type: Boolean,
            default: false,
        },
        hasToggleBackground: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(useLocalizationStore, [
            'selectedCountryMeta',
            'selectedCountry',
            'getParameterValueTranslation',
        ]),

        allInputTypes() {
            return ParameterInputTypes;
        },
        placeholder() {
            return this.canBeEmpty ? this.$t(`parameters.${this.parameterId}.empty_label`) : null;
        },
    },
    methods: {
        ...mapActions(useConfigurationFormStore, ['setValueByParameterIdentifier']),

        onInvalidAddressSelected(address) {
            this.$notify('errors.invalidLocation', 'error');

            this.$emit('treeChange', this.parameterIdentifier);
            this.setValueByParameterIdentifier(this.parameterIdentifier, {
                value: [null],
                parameter: this.parameterConfiguration,
            });
        },
        getValueIcon(val) {
            val = val.replace(' ', '').replace('/', '').replace('-', '');

            const img = new URL(
                `../../assets/images/icons/parameter-values/${this.parameterIdentifier}_${val}.svg`,
                import.meta.url,
            ).href;

            if (img.includes('undefined')) {
                return null;
            }

            return img;
        },
    },
};
</script>
