<template>
    <nav class="navigation" :class="classes">
        <div
            class="navigation__main"
            :class="{ 'navigation__main--has-meta-nav': showMetaNavigation }"
        >
            <img src="../../assets/images/basf-logo.svg" alt="" class="navigation__main-logo" />
            <div class="navigation__main-language">
                <LangSwitch v-if="isLangSwitchVisible" />
            </div>
            <button
                v-if="showServiceNavigation"
                class="navigation__main-services"
                type="button"
                @click="onServicesClick"
            >
                <span>{{
                    $te(`burgerMenu.services`) || $te(`burgerMenu.services`, 'en_ALL')
                        ? $t('burgerMenu.services')
                        : 'Services'
                }}</span>
            </button>
        </div>
        <div v-if="showMetaNavigation" class="navigation__meta">
            <div class="navigation__meta-app-title">
                {{
                    $te(`agsfApplicationName`) || $te(`agsfApplicationName`, 'en_ALL')
                        ? $t('agsfApplicationName')
                        : 'AgSolutions Finder'
                }}
            </div>
            <div v-if="currentService" class="navigation__meta-active-service">
                <span>{{ serviceLabel }}</span>
                <InlineSvg v-if="serviceIcon" :src="serviceIcon" />
            </div>
        </div>
    </nav>
</template>

<script>
import LangSwitch from '@/components/molecules/LangSwitch.vue';
import SidebarContentTypes from '@/utils/constants/sidebar-content-types';
import InlineSvg from 'vue-inline-svg';
import { mapState } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';

export default {
    name: 'Navigation',
    components: { InlineSvg, LangSwitch },
    props: {
        showMetaNavigation: {
            type: Boolean,
            default: true,
        },
        showServiceNavigation: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapState(useLocalizationStore, ['availableLanguages']),

        currentService() {
            // Returns e.g.: 'crop-protection'.
            const route = this.$route.name?.split('.')[0];

            if (route === 'crop-protection' || route === 'seeds') {
                return route;
            }
            return null;
        },
        serviceLabel() {
            if (this.currentService === 'crop-protection') {
                return this.$t('burgerMenu.cropProtection');
            } else if (this.currentService === 'seeds') {
                return this.$t('burgerMenu.seeds');
            }
        },
        serviceIcon() {
            let icon = '';

            if (this.currentService === 'crop-protection') {
                icon = 'crop';
            } else if (this.currentService === 'seeds') {
                icon = 'seeds';
            }

            const img = new URL(`../../assets/images/icons/services/${icon}.svg`, import.meta.url)
                .href;

            if (img.includes('undefined')) {
                return null;
            }

            return img;
        },
        classes() {
            return {
                'navigation--services-hidden': !this.showServiceNavigation,
                'navigation--lang-switch-hidden': !this.isLangSwitchVisible,
            };
        },
        isLangSwitchVisible() {
            return this.availableLanguages?.length > 1;
        },
    },
    methods: {
        onServicesClick() {
            this.$openSidebar(SidebarContentTypes.NAVIGATION, '', {}, false, true);
        },
    },
};
</script>
