<template>
    <div class="location-autocomplete">
        <vue-google-autocomplete
            id="map"
            classname="v-dropdown-menu"
            ref="dropdown"
            :placeholder="placeholder ?? ''"
            :country="country"
            :types="types"
            :disabled="isLoading"
            @placechanged="onPlaceChange"
        />
        <button
            v-if="selectedAddress"
            class="location-autocomplete__clear"
            :disabled="isLoading"
            type="button"
            @click.prevent="onClearClick"
        />
        <button
            class="location-autocomplete__btn"
            type="button"
            @click.prevent="getCurrentLocation"
        />
    </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { mapActions } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';

export default {
    name: 'LocationAutocomplete',
    emits: ['update:modelValue', 'onInvalidAddressSelected'],
    components: {
        VueGoogleAutocomplete,
    },
    data() {
        return {
            selectedAddress: null,
            isLoading: false,
        };
    },
    props: {
        modelValue: {
            type: Array,
            required: true,
        },
        country: {
            type: String,
            required: true,
        },
        types: {
            type: String,
            default: '(regions)',
        },
        placeholder: {
            type: String,
            default: '',
        },
        addressComponent: {
            type: String,
            default: 'administrative_area_level_1',
        },
        validLocations: {
            type: Array,
        },
    },
    async mounted() {
        this.isLoading = false;

        if (this.$route.query?.hasOwnProperty('userLocation')) {
            const value = this.$route.query.userLocation;

            this.$refs.dropdown.update(decodeURIComponent(value));
            this.selectedAddress = value;

            const englishName = await this.getGoogleMapsLocationResult(value);

            if (this.validLocations.includes(englishName)) {
                this.selectedAddress = englishName;
                this.$emit('update:modelValue', englishName);
            }
        }
    },
    methods: {
        ...mapActions(useLocalizationStore, ['getGoogleMapsLocationResult']),

        async onPlaceChange(addressData, placeResultData, id) {
            const placeName = placeResultData.address_components.find((c) =>
                c.types.includes(this.addressComponent),
            )?.long_name;

            // Second request to get same location name in english.
            const englishName = await this.getGoogleMapsLocationResult(placeName);

            if (this.validLocations.includes(englishName)) {
                this.selectedAddress = englishName;
                this.$emit('update:modelValue', englishName);

                this.updateUserLocationInURL(placeName);
            } else {
                this.selectedAddress = null;
                this.$emit('onInvalidAddressSelected', englishName);
            }
        },
        async getCurrentLocation() {
            if (navigator.geolocation) {
                this.selectedAddress = null;
                this.$emit('update:modelValue', []);
                this.updateUserLocationInURL('');

                this.isLoading = true;
                await navigator.geolocation.getCurrentPosition(
                    this.onGeoLocationFound,
                    this.onGetLocationError,
                );
            } else {
                this.$notify(this.$t('errors.locationNotFound'), 'warning');
            }
        },
        onGeoLocationFound(position) {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            const geocoder = new google.maps.Geocoder();
            const coordinates = { lat, lng };

            geocoder.geocode({ location: coordinates }, this.onLocationDataReady);
        },
        async onLocationDataReady(results, status) {
            if (status === 'OK') {
                if (results[0]) {
                    const placeResultData = results[0];
                    const placeName = placeResultData.address_components.find((c) =>
                        c.types.includes(this.addressComponent),
                    )?.long_name;

                    const englishName = await this.getGoogleMapsLocationResult(placeName);

                    if (this.validLocations.includes(englishName)) {
                        this.selectedAddress = englishName;
                        this.$emit('update:modelValue', englishName);

                        this.updateUserLocationInURL(placeName);

                        const addressToDisplay =
                            placeResultData.address_components.find((c) =>
                                c.types.includes('postal_code'),
                            )?.long_name ?? formatted_address;

                        this.$refs.dropdown.update(addressToDisplay);
                    }
                }
            }
            this.isLoading = false;
        },
        onGetLocationError(e) {
            this.isLoading = false;
            this.$notify(this.$t('errors.locationNotFound'), 'warning');
            this.selectedAddress = null;
            this.$emit('update:modelValue', []);
        },
        onClearClick() {
            this.isLoading = false;
            this.selectedAddress = null;
            this.$refs.dropdown.update('');
            this.updateUserLocationInURL('');
            this.$emit('update:modelValue', []);
        },
        updateUserLocationInURL(value) {
            this.$router.replace({
                state: history.state,
                query: {
                    ...this.$route.query,
                    userLocation: encodeURIComponent(value),
                },
            });
        },
    },
};
</script>
