<template>
    <div class="card" :class="classes">
        <Toggle disabled :modelValue="active" class="card__checkbox" />
        <div class="card__img">
            <div :style="imgStyle" />
        </div>
        <div class="card__content">
            <strong>{{ title }}</strong>
            <span v-if="subtitle">
                {{ subtitle }}
            </span>
        </div>
        <a
            v-if="link"
            :href="link"
            class="card__link"
            target="_blank"
            @click.stop="onLinkClicked"
        />
    </div>
</template>

<script>
import Toggle from '@/components/atoms/Toggle.vue';
import { linkOverwriteFunction } from '@/utils/sendPostMessage';

export default {
    name: 'Card',
    components: { Toggle },
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
        },
        image: {
            type: String,
            required: true,
        },
        imageOverlay: {
            type: Boolean,
            default: false,
        },
        link: {
            type: String,
        },
        active: {
            type: Boolean,
            default: false,
        },
        linkOverwriteFunctionEvent: {
            type: Object,
        },
    },
    computed: {
        classes() {
            return {
                'card--active': this.active,
                'card--image-overlay': this.imageOverlay,
            };
        },
        imgStyle() {
            return `background-image: url(${this.image})`;
        },
    },
    methods: {
        onLinkClicked(event) {
            if (this.linkOverwriteFunctionEvent) {
                linkOverwriteFunction(
                    event,
                    this.linkOverwriteFunctionEvent.eventName,
                    this.linkOverwriteFunctionEvent.data,
                );
            }

            event.stopPropagation();
            return false;
        },
    },
};
</script>
