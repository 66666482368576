<template>
    <div class="sidebar-deeplink">
        <div class="sidebar-deeplink__header-image" />
        <div class="sidebar-deeplink__content">
            <div class="sidebar-deeplink__content-left">
                <h2>{{ $t('sidePanel.deeplinkPopupHeadline') }}</h2>
                <p>
                    {{ $t('sidePanel.deeplinkPopupText') }}
                </p>
            </div>
            <div class="sidebar-deeplink__content-right">
                <div class="sidebar-deeplink__content-card">
                    <ul>
                        <li>
                            <span>{{ $t('bottomBar.crop') }}</span>
                            <span v-if="appliedData.crop">{{
                                $t(
                                    `crops.${
                                        appliedData.crop[$translationMappingKey('crops')]
                                    }.name`,
                                )
                            }}</span>
                        </li>
                        <li v-if="selectedTime?.value">
                            <span>{{ selectedTime?.label }}</span>
                            <span>{{ selectedTime?.value }}</span>
                        </li>
                        <li v-if="appliedData.growthStage">
                            <span>{{ $t('recommendations.growthStage') }}</span>
                            <span>
                                {{ appliedData.growthStage.growthStageScaleName }}
                                {{ appliedData.growthStage.code }}</span
                            >
                        </li>
                        <li v-if="targetFunctionalGroups?.length">
                            <span>{{ $t('bottomBar.targets') }}</span>
                            <span>{{ targetFunctionalGroups.join(', ') }}</span>
                        </li>
                        <li v-for="param in parametersToDisplay">
                            <span>{{ $t(`parameters.${param.parameter}.name_translations`) }}</span>
                            <span>{{ getParameterValues(param).join(', ') }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="sidebar-deeplink__buttons">
            <Btn @click.prevent="onAcceptClick">
                {{ $t('sidePanel.deeplinkPopupApplyText') }}
            </Btn>
            <Btn
                @click.prevent="onDeclineClick"
                type="white"
                icon="fa-rotate-right"
                iconPosition="start"
            >
                {{ $t('sidePanel.deeplinkPopupAbortText') }}
            </Btn>
        </div>
    </div>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';
import { mapActions, mapState } from 'pinia';
import { useDeeplinkStore } from '@/stores/deeplink';

export default {
    name: 'SidebarIFrame',
    components: { Btn },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState(useDeeplinkStore, ['appliedData']),

        targetFunctionalGroups() {
            return Array.from(
                new Set(
                    this.appliedData.targets.map((t) =>
                        this.$t(`functionalGroups.${t.functionalGroupCode}.name`),
                    ),
                ),
            );
        },
        selectedTime() {
            const seasonParameter = this.appliedData.parameters.find(
                (p) => p.identifier === 'hasAdviceSeason',
            );

            if (!seasonParameter || seasonParameter.value?.length === 0) {
                return '';
            }

            return {
                label: this.$t(`parameters.${seasonParameter.parameter}.name_translations`),
                value: this.$t(`parameterValues.hasAdviceSeason.${seasonParameter.value[0]}`),
            };
        },
        parametersToDisplay() {
            return this.appliedData.parameters.filter(
                (p) =>
                    p.identifier !== 'hasAdviceSeason' &&
                    p.identifier !== 'hasAdviceFunctionalGroupCodes',
            );
        },
    },
    methods: {
        ...mapActions(useDeeplinkStore, ['gotoDeeplinkResults']),

        async onAcceptClick() {
            this.$hideSidebar();
            await this.gotoDeeplinkResults();
        },
        onDeclineClick() {
            this.$hideSidebar();
        },
        getParameterValues(param) {
            return param.value.map((v) => {
                const key = `parameterValues.${param.identifier}.${v}`;

                if (this.$te(key) || this.$te(key, 'en_ALL')) {
                    return this.$t(key);
                } else if (
                    this.$te(`parameterValues.unsorted.${param.identifier}`) ||
                    this.$te(`parameterValues.unsorted.${param.identifier}`, 'en_ALL')
                ) {
                    return this.$t(`parameterValues.unsorted.${v}`);
                }
                return v;
            });
        },
    },
};
</script>
