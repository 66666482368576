<template>
    <div v-if="selectedCountry && availableLanguages?.length" class="lang-switch">
        <DropdownMenu :overlay="false" :closeOnClickOutside="true" withDropdownCloser>
            <template #trigger>
                <template v-if="!isOpened">
                    <span class="v-dropdown-menu__selected">
                        {{ selectedLanguage?.language?.toUpperCase() }}
                    </span>
                </template>
                <div class="v-dropdown-menu__icon-btn" dropdown-closer />
            </template>

            <template #body>
                <ul dropdown-closer>
                    <li
                        v-for="(item, i) in availableLanguages"
                        :key="i"
                        @click="() => onSelectedLanguageChange(item)"
                    >
                        {{ item.language.toUpperCase() }}
                    </li>
                </ul>
            </template>
        </DropdownMenu>
    </div>
</template>

<script>
import { useLocalizationStore } from '@/stores/localization';
import { mapActions, mapState } from 'pinia';
import DropdownMenu from 'v-dropdown-menu';

export default {
    name: 'LangSwitch',
    components: { DropdownMenu },
    data() {
        return {
            isOpened: false,
            selectedItem: null,
        };
    },
    computed: {
        ...mapState(useLocalizationStore, [
            'availableLanguages',
            'selectedLanguage',
            'selectedCountry',
        ]),
    },
    methods: {
        ...mapActions(useLocalizationStore, ['setSelectedLanguage']),

        onSelectedLanguageChange(e) {
            this.setSelectedLanguage(e);
        },
    },
};
</script>
