<template>
    <div v-if="linksMapped?.length || regionalConsultantLink" class="upselling-card">
        <div class="upselling-card__header">
            <span>{{ $t('recommendations.regionalAdviceSubtitle') }}</span>
            <h3>{{ $t('recommendations.regionalAdviceTitle') }}</h3>
        </div>
        <a
            v-if="regionalConsultantLink"
            :href="regionalConsultantLink"
            target="_blank"
            class="upselling-card__button"
            @click="(e) => linkOverwriteFunction(e, 'findConsultant')"
        >
            {{ $t('recommendations.findNow') }}
        </a>
        <div v-if="linksMapped.length" class="upselling-card__list">
            <span>
                {{ $t('recommendations.upsellingCardListHeadline') }}
            </span>

            <ul>
                <li v-for="link in linksMapped">
                    <a :href="link.url" class="upselling-card__list-link" target="_blank">
                        <span>{{ link.text }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import ProductTeaser from '@/components/molecules/Recommendation/ProductTeaser.vue';
import DownloadTeaser from '@/components/molecules/Recommendation/DownloadTeaser.vue';
import { mapState } from 'pinia';
import { useLinksAndResourcesStore } from '@/stores/links-and-resources';
import { useLocalizationStore } from '@/stores/localization';
import { linkOverwriteFunction } from '@/utils/sendPostMessage';

export default {
    name: 'UpsellingCard',
    methods: { linkOverwriteFunction },
    components: { DownloadTeaser, ProductTeaser },
    computed: {
        ...mapState(useLocalizationStore, ['selectedLanguage', 'selectedCountryMeta']),
        ...mapState(useLinksAndResourcesStore, ['cropSpecificLinks']),

        linksMapped() {
            const links = this.cropSpecificLinks.map((l) => ({
                text: l.linkText[this.selectedLanguage?.locale],
                url: l.linkUrl[this.selectedLanguage?.locale],
                order: l.order,
            }));

            links.sort((a, b) => a.order - b.order);

            return links;
        },
        regionalConsultantLink() {
            const countryMeta = this.selectedCountryMeta;
            const route = this.$route?.name?.split('.')[0];

            if (route === 'crop-protection') {
                return countryMeta.findRegionalConsultantLink;
            } else {
                return countryMeta.findRegionalConsultantLinkSeeds;
            }
        },
    },
};
</script>
