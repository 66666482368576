// Build translation object for each language and pass it to i18n.
export const addEntityTranslations = (i18n, translations, mapping, languages = ['en_ALL']) => {
    languages.forEach((lang) => {
        const entityKeys = Object.keys(translations);
        const translationsForLanguage = i18n.getLocaleMessage(lang);

        // Iterate through all entity keys.
        entityKeys.forEach((key) => {
            const identifier = 'identifier';

            if (key === 'parameterValues') {
                translationsForLanguage[key] = {};

                // Parameter values have a different format, so we handle it separately.
                translations[key].forEach((e) => {
                    const reference = e.reference || 'unsorted';
                    const translationsObj = {
                        [`${e.identifier}`]: e.translations[lang]?.custom_value_translations,
                    };

                    if (!translationsForLanguage[key][reference]) {
                        translationsForLanguage[key][reference] = translationsObj;
                    } else {
                        translationsForLanguage[key][reference] = {
                            ...translationsForLanguage[key][reference],
                            ...translationsObj,
                        };
                    }
                });
            } else {
                // Transform translations of the current entity.
                translationsForLanguage[key] = {
                    ...translationsForLanguage[key],
                    ...transformTranslations(identifier, translations[key], lang),
                };
            }
        });

        // Set translations for specified language in i18n.
        i18n.setLocaleMessage(lang, translationsForLanguage);
    });
};

// Manually add a single translation item with given key and translations.
export const addSingleTranslation = (i18n, key, data, languages = ['en_ALL']) => {
    languages.forEach((lang) => {
        const translationsForLanguage = i18n.getLocaleMessage(lang);

        translationsForLanguage[key] = data[lang];

        i18n.setLocaleMessage(lang, translationsForLanguage);
    });
};

// Manually add translations from entity array.
export const addTranslations = (i18n, key, data, identifier = 'id', languages = ['en_ALL']) => {
    languages.forEach((lang) => {
        const translationsForLanguage = {};

        data.forEach((item) => {
            const translation = item.translatedNames ? item.translatedNames : item.translations;
            const isArrayStructure = Array.isArray(translation);
            const translationObject = isArrayStructure
                ? translation.find((t) => t.locale === lang)
                : translation;

            translationsForLanguage[item[identifier]] = isArrayStructure
                ? translationObject?.translation
                : translationObject[lang];
        });

        // Get previous translations and add the new ones to that object.
        const newTranslationObject = i18n.getLocaleMessage(lang);

        // Set translations for specified language in i18n.
        newTranslationObject[key] = { ...newTranslationObject[key], ...translationsForLanguage };
        i18n.setLocaleMessage(lang, newTranslationObject);
    });
};

// Transform interface translations from API to a object format.
export const addInterfaceTranslations = (i18n, data, languages = ['en_ALL']) => {
    const transformed = {};

    data.forEach((t) => {
        const [categoryKey, subKey] = t.key.split('.');
        if (!transformed.hasOwnProperty(categoryKey)) {
            transformed[categoryKey] = {};
        }
        transformed[categoryKey][subKey] = {
            translations: t.translations,
        };
    });

    Object.keys(transformed).forEach((category) => {
        const transformedArray = [];
        const translations = transformed[category];

        Object.keys(translations).forEach((t) => {
            transformedArray.push({ key: t, ...translations[t] });
        });

        addTranslations(i18n, category, transformedArray, 'key', languages);
    });
};

const transformTranslations = (objectIdentifier, translations, lang) => {
    const mapped = {};

    translations.forEach((i) => {
        const identifier = i[objectIdentifier].replace('.', '_');
        mapped[identifier] = i.translations[lang];
    });

    return mapped;
};
