<template>
    <div v-if="mappedTeasers.length" class="promo-teaser-list">
        <h2 class="promo-teaser-list__headline">{{ title || $t('promoTeasers.headline') }}</h2>
        <GridList class="promo-teaser-list__ul" :columns="2">
            <PromoTeaser
                v-for="teaser in mappedTeasers"
                :title="teaser.title"
                :image="teaser.image"
                :text="teaser.text"
                :link="teaser.link"
                :btnText="teaser.btnText"
            />
        </GridList>
    </div>
</template>

<script>
import PromoTeaser from '@/components/atoms/PromoTeaser.vue';
import { mapState } from 'pinia';
import { useTeaserStore } from '@/stores/teaser';
import { useLocalizationStore } from '@/stores/localization';
import GridList from '@/components/atoms/GridList.vue';

export default {
    name: 'PromoTeaserList',
    components: { GridList, PromoTeaser },
    props: {
        title: {
            type: String,
        },
    },
    computed: {
        ...mapState(useTeaserStore, ['promotionTeasers']),
        ...mapState(useLocalizationStore, ['selectedLanguage']),

        mappedTeasers() {
            return this.promotionTeasers.map((t) => ({
                title: t.title.find((i) => i.locale === this.selectedLanguage?.locale)?.translation,
                text: t.text.find((i) => i.locale === this.selectedLanguage?.locale)?.translation,
                image: t.media?.url,
                link: t.linkUrl.find((i) => i.locale === this.selectedLanguage?.locale)
                    ?.translation,
                btnText: t.button.find((i) => i.locale === this.selectedLanguage?.locale)
                    ?.translation,
            }));
        },
    },
};
</script>
