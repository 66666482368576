<template>
    <div class="notification-container">
        <ul class="notification-container__list">
            <li v-for="notify in notifications.slice().reverse()">
                <Notification :notification="notify" :key="notify.id" />
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useNotificationsStore } from '@/stores/notifications';
import Notification from '@/components/atoms/Notification.vue';

export default {
    name: 'NotificationContainer',
    components: { Notification },
    computed: {
        ...mapState(useNotificationsStore, ['notifications']),
    },
};
</script>
