<template>
    <div class="autocomplete">
        <v-select
            v-model="selected"
            :options="sortedOptions"
            :placeholder="placeholder"
            :multiple="multiple"
            :value="modelValue"
            @option:selected="onInput"
            @option:deselected="onInput"
        >
            <button
                v-if="selected.length"
                type="button"
                class="autocomplete__clear-btn"
                @click.stop="onClearClick"
            />
        </v-select>
    </div>
</template>

<script>
export default {
    name: 'Autocomplete',
    emits: ['update:modelValue'],
    props: {
        modelValue: {},
        options: {
            type: Array,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            // Internally store selected values because events return only the changed item.
            selected: [],
        };
    },
    computed: {
        sortedOptions() {
            return [...this.options].sort();
        },
    },
    methods: {
        onInput(value) {
            this.$emit('update:modelValue', this.selected);
        },
        onClearClick() {
            this.selected = [];
            this.onInput(this.selected);
        },
    },
};
</script>
