<template>
    <ParameterSection :title="$t('form.selectCharacteristics')" class="characteristics-toggles">
        <template #content>
            <ul class="characteristics-toggles__list">
                <li v-for="toggle in parameterConfiguration?.recommendationCharacteristics">
                    <Toggle
                        :label="$t(`characteristics.${toggle.id}.translated_name`)"
                        hasBackground
                        :identifier="toggle.id"
                        :key="`characteristic-toggle-${toggle.id}`"
                        :modelValue="getCharacteristicValue(toggle)"
                        @update:modelValue="(val) => onCharacteristicChange(toggle, val)"
                    />
                </li>
            </ul>
        </template>
    </ParameterSection>
</template>

<script>
import Toggle from '@/components/atoms/Toggle.vue';
import { mapActions, mapState } from 'pinia';
import { useCropProtectionStore } from '@/stores/crop-protection';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import ParameterSection from '@/components/molecules/ParameterSection.vue';

export default {
    name: 'CharacteristicsToggles',
    components: { ParameterSection, Toggle },
    computed: {
        ...mapState(useCropProtectionStore, ['parameterConfiguration']),
        ...mapState(useConfigurationFormStore, [
            'getValueByParameterName',
            'getCharacteristicByIdentifier',
        ]),
    },
    methods: {
        ...mapActions(useConfigurationFormStore, ['setCharacteristicByIdentifier']),

        onCharacteristicChange(characteristic, value) {
            this.setCharacteristicByIdentifier(characteristic.externalIdentifier, value);
        },
        getCharacteristicValue(characteristic) {
            const value = this.getCharacteristicByIdentifier(characteristic.externalIdentifier);

            if (value === undefined || value === null) {
                const defaultVal = characteristic.defaultValues?.length
                    ? characteristic.defaultValues[0] == 'true'
                    : false;
                this.setCharacteristicByIdentifier(characteristic.externalIdentifier, defaultVal);
            }

            return this.getCharacteristicByIdentifier(characteristic.externalIdentifier);
        },
    },
};
</script>
