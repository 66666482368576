<template>
    <section class="target-selector">
        <div class="target-selector__mobile-counter">
            <ParameterLabel
                :title="$t('targetsPage.specifyDetails')"
                :required="true"
                :sidePanelContent="null"
            />
            <Counter :max="maxSelectedTargets" :value="selectedTargets.length" />
            {{ $t('bottomBar.selectedTargets') }}
        </div>
        <ParameterSection
            v-if="!$loading.isLoading('crop-protection:load-targets')"
            sticky-container
        >
            <template #title>
                <ParameterLabel
                    :title="$t('bottomBar.selectedTargets')"
                    :required="true"
                    :sidePanelContent="null"
                    v-sticky
                    sticky-offset="{ top: 160 }"
                >
                    <template #extra>
                        <div class="target-selector__counter">
                            <Counter :max="maxSelectedTargets" :value="selectedTargets.length" />
                            {{ $t('bottomBar.selectedTargets') }}
                        </div>
                        <SelectedTargetsList :dontRemoveTargetsFromList="isMobile" />
                    </template>
                </ParameterLabel>
            </template>
            <template #content>
                <ScrollSpy
                    ref="scrollSpy"
                    class="target-selector__targets"
                    :order="groupedMappedTargetsOrder"
                    @onActiveSectionChanged="onActiveSectionChanged"
                >
                    <ScrollSpyContainer
                        v-for="(groupTargets, group) in groupedMappedTargets"
                        v-if="showAccordions"
                        ref="sections"
                        :id="`functionalGroup.${group}`"
                        :key="`functionalGroup.${group}`"
                    >
                        <div class="target-selector__targets-group">
                            <h2>{{ $t(getGroupDisplayName(group)) }}</h2>

                            <!--Focus targets-->
                            <OptionSelectList
                                v-if="groupTargets.focus.length"
                                name="targets"
                                optionIdentifier="id"
                                :options="groupTargets.focus"
                                :modelValue="selectedTargets"
                                multiple
                                :maxItems="maxSelectedTargets"
                                grid
                                :gridColumns="3"
                                twoXSGridColumns
                                @update:modelValue="onSelectedTargetsChange"
                            >
                                <template #content="{ item, active }">
                                    <Card
                                        :title="
                                            $t(
                                                `targets.${
                                                    item[$translationMappingKey('targets')]
                                                }.name`,
                                            )
                                        "
                                        :subtitle="item.scientificName"
                                        :image="getTargetImage(item)"
                                        :imageOverlay="!item.targetMedia"
                                        :link="item.weblink"
                                        :active="active"
                                        :linkOverwriteFunctionEvent="{
                                            eventName: 'viewTarget',
                                            data: { eppoCode: item.eppoCode },
                                        }"
                                    />
                                </template>
                            </OptionSelectList>

                            <!--All remaining targets-->
                            <Accordion v-if="showAccordions && groupTargets.remaining.length">
                                <template #head="{ opened }">
                                    <span>{{
                                        $t(`targetsPage.show${opened ? 'Less' : 'More'}${group}`)
                                    }}</span>
                                </template>
                                <template #content>
                                    <OptionSelectList
                                        name="targets"
                                        optionIdentifier="id"
                                        :options="groupTargets.remaining"
                                        :modelValue="selectedTargets"
                                        multiple
                                        :maxItems="maxSelectedTargets"
                                        grid
                                        :gridColumns="3"
                                        twoXSGridColumns
                                        @update:modelValue="onSelectedTargetsChange"
                                    >
                                        <template #content="{ item, active }">
                                            <Card
                                                :title="
                                                    $t(
                                                        `targets.${
                                                            item[$translationMappingKey('targets')]
                                                        }.name`,
                                                    )
                                                "
                                                :subtitle="item.scientificName"
                                                :image="getTargetImage(item)"
                                                :imageOverlay="!item.targetMedia"
                                                :link="item.weblink"
                                                :active="active"
                                            />
                                        </template>
                                    </OptionSelectList>
                                </template>
                            </Accordion>
                        </div>
                    </ScrollSpyContainer>
                    <ScrollSpyContainer v-else>
                        <!--All found targets-->
                        <OptionSelectList
                            v-if="allFoundTargets?.length"
                            name="targets"
                            optionIdentifier="id"
                            :options="allFoundTargets"
                            :modelValue="selectedTargets"
                            multiple
                            :maxItems="maxSelectedTargets"
                            grid
                            :gridColumns="3"
                            twoXSGridColumns
                            @update:modelValue="onSelectedTargetsChange"
                        >
                            <template #content="{ item, active }">
                                <Card
                                    :title="
                                        $t(
                                            `targets.${
                                                item[$translationMappingKey('targets')]
                                            }.name`,
                                        )
                                    "
                                    :subtitle="item.scientificName"
                                    :image="getTargetImage(item)"
                                    :imageOverlay="!item.targetMedia"
                                    :link="item.weblink"
                                    :active="active"
                                />
                            </template>
                        </OptionSelectList>
                    </ScrollSpyContainer>
                </ScrollSpy>
            </template>
        </ParameterSection>
        <div v-else>loading targets..</div>
    </section>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useCropProtectionStore } from '@/stores/crop-protection';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import OptionSelectList from '@/components/molecules/OptionSelectList.vue';
import Card from '@/components/atoms/Card.vue';
import ParameterSection from '@/components/molecules/ParameterSection.vue';
import ParameterLabel from '@/components/atoms/ParameterLabel.vue';
import Counter from '@/components/atoms/Counter.vue';
import ScrollSpy from '@/components/atoms/ScrollSpy.vue';
import SelectedTargetsList from '@/components/molecules/SelectedTargetsList.vue';
import Accordion from '@/components/atoms/Accordion.vue';
import ScrollSpyContainer from '@/components/atoms/ScrollSpyContainer.vue';
import isMobileHelper from '@/utils/isMobile';

export default {
    name: 'TargetSelector',
    components: {
        ScrollSpyContainer,
        SelectedTargetsList,
        Accordion,
        ScrollSpy,
        Counter,
        ParameterLabel,
        ParameterSection,
        Card,
        OptionSelectList,
    },
    emits: ['onActiveElementChanged', 'onSelectedTargetsChange'],
    props: {
        focusCrop: {
            type: Object,
            required: true,
        },
        groupedTargets: {
            type: Object,
            required: true,
        },
        maxSelectedTargets: {
            type: Number,
            default: 10,
        },
        fallbackTargetDisplayCount: {
            type: Number,
            default: 21,
        },
        showAccordions: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapState(useCropProtectionStore, ['targets', 'parameterConfiguration']),
        ...mapState(useConfigurationFormStore, ['selectedTargets']),

        isMobile() {
            return isMobileHelper();
        },
        groupedMappedTargets() {
            const targetDisplayInfo = this.parameterConfiguration?.functionalGroupInformation;

            return Object.fromEntries(
                Object.entries(this.groupedTargets)
                    .filter(([groupKey]) => {
                        return targetDisplayInfo?.find((g) => g.value === groupKey)?.hasTargets;
                    })
                    .map(([groupKey, group]) => {
                        const focus = group.filter((element) =>
                            element.focusForCrops.some(
                                (c) => c.eppoCode === this.focusCrop.eppoCode,
                            ),
                        );
                        let remaining = group.filter((element) => !focus.includes(element));

                        // If there are no focus targets, we take some regular targets and put them into focus.
                        if (focus.length === 0) {
                            const targetsToMove = Math.min(
                                this.fallbackTargetDisplayCount,
                                remaining.length,
                            );
                            focus.push(...remaining.slice(0, targetsToMove));
                            remaining = remaining.slice(targetsToMove);
                        }

                        return [
                            groupKey,
                            {
                                focus: this.sortTargets(focus),
                                remaining: this.sortTargets(remaining),
                            },
                        ];
                    }),
            );
        },
        groupedMappedTargetsOrder() {
            const targetDisplayInfo = this.parameterConfiguration?.functionalGroupInformation;
            return Object.keys(this.groupedMappedTargets).filter(
                (k) => targetDisplayInfo?.find((g) => g.value === k)?.hasTargets,
            );
        },

        allFoundTargets() {
            const allFocusTargets = [];
            const allRemainingTargets = [];
            const mappedTargets = this.groupedMappedTargets;
            const targetDisplayInfo = this.parameterConfiguration?.functionalGroupInformation;

            Object.keys(mappedTargets).forEach((key) => {
                const group = mappedTargets[key];

                if (targetDisplayInfo?.find((g) => g.value === key)?.hasTargets) {
                    allFocusTargets.push(...group.focus);
                    allRemainingTargets.push(...group.remaining);
                }
            });

            return this.sortTargets([...allFocusTargets, ...allRemainingTargets]);
        },
    },
    methods: {
        ...mapActions(useConfigurationFormStore, ['setSelectedTargets']),

        onSelectedTargetsChange(targets) {
            this.setSelectedTargets(targets);
            this.$emit('onSelectedTargetsChange', this.selectedTargets);
        },
        getTargetImage(item) {
            if (item.targetMedia?.media?.url) {
                return item.targetMedia?.media?.url;
            }

            const fGroup = item.functionalGroupCode;

            return new URL(`../../assets/images/icons/problems/type-${fGroup}.svg`, import.meta.url)
                .href;
        },
        onActiveSectionChanged(data) {
            this.$emit('onActiveSectionChanged', data);
        },
        sortTargets(targets) {
            return [
                ...targets.sort((a, b) => {
                    const aName = this.$t(
                        `targets.${a[this.$translationMappingKey('targets')]}.name`,
                    );
                    const bName = this.$t(
                        `targets.${b[this.$translationMappingKey('targets')]}.name`,
                    );

                    return aName.localeCompare(bName);
                }),
            ];
        },
        getGroupDisplayName(group) {
            if (
                this.$te(`functionalGroups.${group}.custom_name`) ||
                this.$te(`functionalGroups.${group}.custom_name`, 'en_ALL')
            ) {
                return `functionalGroups.${group}.custom_name`;
            }

            return `functionalGroups.${group}.name`;
        },
    },
    watch: {
        showAccordions(oldVal, newVal) {
            // Reset scroll-spy when the accordions/sections become visible again.
            if (newVal) {
                this.$refs.scrollSpy.resetSections();
            }
        },
    },
};
</script>
