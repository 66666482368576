<template>
    <div class="toggle" :class="classes" @click.stop="toggleCheckbox">
        <input
            type="checkbox"
            class="toggle__input"
            readonly
            :id="identifier"
            :name="identifier"
            :disabled="disabled"
            :checked="isChecked"
        />
        <div class="toggle__check">
            <span class="toggle__check-background"></span>
        </div>
        <div class="toggle__labels">
            <span v-if="label">{{ label }}</span>
            <em v-if="subtitle">{{ subtitle }}</em>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Toggle',
    emits: ['update:modelValue'],
    props: {
        identifier: {
            type: String,
        },
        modelValue: {},
        label: {
            type: String,
        },
        subtitle: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasBackground: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isChecked() {
            return this.modelValue === true || this.modelValue === 'true';
        },
        classes() {
            return {
                'toggle--has-background': this.hasBackground,
            };
        },
    },
    methods: {
        toggleCheckbox() {
            if (typeof this.modelValue === 'boolean') {
                this.$emit('update:modelValue', !this.modelValue);
            } else if (typeof this.modelValue === 'string') {
                this.$emit('update:modelValue', this.modelValue === 'true' ? 'false' : 'true');
            }
        },
    },
};
</script>
