<template>
    <div class="recommendation-card" :class="classes">
        <!--Header-->
        <RecommendationCardHeader
            :adviceId="advice.id"
            :title="title"
            :type="type"
            :overallEffect="overallEffect"
            :efficacies="highestEfficacies"
            :expandable="highestEfficacies?.length > 0"
            :isSeedCard="isSeedCard"
            :hasNewTag="hasNewTag"
            :seedProduct="recommendationProductForSeedData"
        />

        <div class="recommendation-card__content">
            <!--Sequence(s)-->
            <RecommendationApplication
                v-if="!isSeedCard"
                v-for="(applicationSequence, i) in adviceApplications"
                :application="applicationSequence"
                :sequenceNo="adviceApplications.length > 1 ? i + 1 : 0"
                :waterData="getWaterInfoForSequence(applicationSequence)"
                :aerialWaterData="getAerialWaterInfoForSequence(applicationSequence)"
                :enableAccordion="adviceApplications.length > 1"
                :productFootnoteMapping="manufacturerFootnotes.productMapping"
                :footnotes="manufacturerFootnotes.footnoteList"
            />
            <RecommendationSeedData
                v-else
                :advice="advice"
                :product="recommendationProductForSeedData"
            />

            <RecommendationCardProducts
                :advice="advice"
                :collapsable="adviceApplications.length > 1"
                :allRecommendationProducts="recommendationProducts"
            />
        </div>
        <div class="recommendation-card__buttons">
            <!--Buttons-->
            <Btn
                v-if="cropStrategyImage"
                type="recommendation-card"
                icon="fa-arrow-right"
                iconPosition="end"
                additionalGraphic="crop_strategy"
                @click.prevent="onCropStrategyClick"
                >{{ $t('sidePanel.cropStrategy') }}
            </Btn>
            <template v-if="isSeedCard">
                <Btn
                    v-if="calculationData && calculationData.seedRateCalculations?.length"
                    type="recommendation-card"
                    icon="fa-arrow-right"
                    iconPosition="end"
                    additionalIcon="fa-calculator"
                    @click.prevent="onCalculateSeedRateClick"
                    >{{ $t('recommendations.calculateSeedRate') }}
                </Btn>
            </template>

            <!--Additional links-->
            <Btn
                v-for="link in adviceLinks"
                type="recommendation-card"
                icon="fa-arrow-right"
                iconPosition="end"
                v-bind="
                    AdviceLinkIconsAndTitles[link.type].isFA
                        ? { additionalIcon: AdviceLinkIconsAndTitles[link.type]?.icon }
                        : { additionalGraphic: AdviceLinkIconsAndTitles[link.type]?.icon }
                "
                :href="link.link"
                target="_blank"
                >{{ $t(AdviceLinkIconsAndTitles[link.type]?.titleKey) }}
            </Btn>

            <!--Notes-->
            <Accordion
                v-if="comments"
                @onOpened="shouldShowBottomBorder = true"
                @onClosed="shouldShowBottomBorder = false"
            >
                <template #head>
                    <Btn
                        type="recommendation-card"
                        icon="fa-chevron-down"
                        iconPosition="end"
                        additionalGraphic="notes"
                        >{{ $t('recommendations.importantNotes') }}
                    </Btn>
                </template>
                <template #content>
                    <p v-html="comments" v-sub-tag-wrapper />
                </template>
            </Accordion>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { flatten, orderBy, uniqBy } from 'lodash';
import { useCropProtectionStore } from '@/stores/crop-protection';
import { efficacyStringToNumber } from '@/utils/helpers';
import Btn from '@/components/atoms/Btn.vue';
import SidebarContentTypes from '@/utils/constants/sidebar-content-types';
import RecommendationCardHeader from '@/components/molecules/Recommendation/RecommendationCardHeader.vue';
import RecommendationApplication from '@/components/molecules/Recommendation/RecommendationApplication.vue';
import RecommendationCardProducts from '@/components/molecules/Recommendation/RecommendationCardProducts.vue';
import Accordion from '@/components/atoms/Accordion.vue';
import RecommendationSeedData from '@/components/molecules/Recommendation/RecommendationSeedData.vue';
import AdviceLinkIconsAndTitles from '@/utils/constants/advice-link-icons-and-titles';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import { useSeedStore } from '@/stores/seed';
import { useAppStore } from '@/stores/app';
import translationArrayHelper from '@/utils/translation-array-helper';

export default {
    name: 'RecommendationCard',
    components: {
        RecommendationSeedData,
        Accordion,
        RecommendationCardProducts,
        RecommendationApplication,
        RecommendationCardHeader,
        Btn,
    },
    props: {
        advice: {
            type: Object,
            required: true,
        },
        cropStrategyImage: {
            type: String,
        },
        isSeedCard: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            shouldShowBottomBorder: false,
        };
    },
    computed: {
        AdviceLinkIconsAndTitles() {
            return AdviceLinkIconsAndTitles;
        },
        ...mapState(useAppStore, ['currentService']),
        ...mapState(useCropProtectionStore, [
            'getTargetByEppoCode',
            'getProductById',
            'getPackById',
            'getAdviceTitle',
            'cropRecommendations',
            'targets',
            'getAdviceOverallEffect',
        ]),
        ...mapState(useSeedStore, ['seedRecommendations', 'seedRateCalculationData']),
        ...mapState(useConfigurationFormStore, ['activeTargets']),

        classes() {
            return {
                'recommendation-card--sequence': this.adviceApplications.length > 1,
                'recommendation-card--with-bottom-border': this.shouldShowBottomBorder,
                'recommendation-card--seed': this.isSeedCard,
            };
        },
        title() {
            return this.getAdviceTitle(this.advice, '<i>&#8226;</i>', true);
        },
        comments() {
            return (
                translationArrayHelper(this.advice.comments, this.$i18n.locale.slice(0, 2)) ?? ''
            );
        },
        type() {
            let type = `${this.$t(
                `recommendations.adviceHeadline${this.advice.functionalGroupCode}`,
            )}`;

            if (this.isSeedCard) {
                if (!this.recommendationProducts[0]?.brandName) {
                    return type;
                }

                let brandName =
                    this.recommendationProducts[0]?.brandName[this.$i18n.locale.slice(0, 2)];

                if (!brandName) {
                    brandName =
                        this.recommendationProducts[0]?.brandName[
                            Object.keys(this.recommendationProducts[0]?.brandName)[0]
                        ];
                }

                if (brandName) {
                    type = brandName;
                }
            }
            return type;
        },
        adviceApplications() {
            const applications = [this.advice];

            applications.push(...this.advice.adviceSequences);

            return applications;
        },
        recommendationProducts() {
            return this.currentService === 'seeds'
                ? this.seedRecommendations?.products
                : this.cropRecommendations?.products;
        },
        overallEffect() {
            return this.getAdviceOverallEffect(this.advice);
        },
        highestEfficacies() {
            /*
             * Get all product efficacies.
             * Flatten the array of arrays.
             * Sort by amount (descending).
             * Remove duplicates by eppoCode. (Takes the first findings first - that's why we sort)
             * Sort targets alphabetically.
             */
            const adviceProductsData = uniqBy(
                orderBy(
                    flatten(this.advice.adviceProducts.map((p) => this.getEfficaciesForProduct(p))),
                    'amount',
                    'desc',
                ),
                'eppoCode',
            );
            const foundTargets = adviceProductsData.map((t) => t.eppoCode);
            const missingTargets = this.activeTargets.filter(
                (t) => !foundTargets.includes(t.eppoCode),
            );
            const missingTargetsEfficacyData = missingTargets.map((t) => ({
                eppoCode: t.eppoCode,
                name: this.$t(`targets.${t.eppoCode}.name`),
                amount: 0,
            }));

            // Filter out targets of other fct. groups.
            const filteredTargets = [...adviceProductsData, ...missingTargetsEfficacyData].filter(
                (e) => {
                    const foundTarget = this.targets.find((t) => t.eppoCode === e.eppoCode);
                    return foundTarget?.functionalGroupCode === this.advice.functionalGroupCode;
                },
            );

            return orderBy(filteredTargets, 'name', 'asc');
        },
        hasNewTag() {
            if (!this.isSeedCard) return false;

            return this.advice.adviceProducts.some((p) => {
                const pInfo = this.recommendationProducts.find((rp) => rp.id === p.productId);
                return pInfo && pInfo.hasTagNew;
            });
        },
        adviceLinks() {
            const allLinks = [...this.advice.links];
            allLinks.sort((a, b) => a.order - b.order);

            return allLinks.map((link) => ({
                type: link.linkType,
                link: link.url,
            }));
        },
        recommendationProductForSeedData() {
            const product = this.recommendationProducts.find(
                (p) => p.id === this.adviceApplications[0].adviceProducts[0].productId,
            );

            return {
                ...product,
                ...this.adviceApplications[0].adviceProducts[0],
            };
        },
        manufacturerFootnotes() {
            const products = this.advice.adviceProducts
                .map((ap) => this.getProductById(ap.productId))
                .filter((p) => !!p);

            return products.reduce(
                (result, product) => {
                    if (product.manufacturerName) {
                        const manufacturer = product.manufacturerName;
                        if (manufacturer !== 'BASF') {
                            if (!result.footnoteList.find((f) => f.name === manufacturer)) {
                                result.footnoteList.push({
                                    index: result.footnoteList.length + 1,
                                    name: manufacturer,
                                });
                            }

                            result.productMapping[product.id] = result.footnoteList.find(
                                (f) => f.name === manufacturer,
                            ).index;
                        }
                    }

                    return result;
                },
                { productMapping: {}, footnoteList: [] },
            );
        },
        calculationData() {
            const dataForAdvice = this.seedRateCalculationData?.find(
                (c) => c.adviceId === this.advice?.id,
            );

            if (dataForAdvice) {
                return dataForAdvice.adviceProducts?.find(
                    (p) => p.productId === this.recommendationProductForSeedData?.id,
                );
            }

            return null;
        },
    },
    methods: {
        getWaterInfoForSequence(sequence) {
            const sequenceAerialWaterInfo = this.getAerialWaterInfoForSequence(sequence);
            const shouldUseGroundWaterTranslation =
                (sequenceAerialWaterInfo.applicationRateUnitCode &&
                    (sequenceAerialWaterInfo.applicationRate ||
                        (sequenceAerialWaterInfo.applicationRateMin &&
                            sequenceAerialWaterInfo.applicationRateMax))) ||
                (sequenceAerialWaterInfo.applicationWaterType === 'freeText' &&
                    sequenceAerialWaterInfo.applicationFreeText);

            return {
                name: shouldUseGroundWaterTranslation
                    ? this.$t('recommendations.groundWater')
                    : this.$t('recommendations.water'),
                applicationRate: sequence.waterAmount,
                applicationRateMin: sequence.waterAmountMin,
                applicationRateMax: sequence.waterAmountMax,
                applicationRateUnitCode: sequence.waterUnitCode,
                applicationWaterType: sequence.waterType,
                applicationFreeText: sequence.waterFreeText,
            };
        },
        getAerialWaterInfoForSequence(sequence) {
            return {
                name: this.$t('recommendations.aerialWater'),
                applicationRate: sequence.aerialWaterAmount,
                applicationRateMin: sequence.aerialWaterAmountMin,
                applicationRateMax: sequence.aerialWaterAmountMax,
                applicationRateUnitCode: sequence.aerialWaterUnitCode,
                applicationWaterType: sequence.aerialWaterType,
                applicationFreeText: sequence.aerialWaterFreeText,
            };
        },
        // Get all the efficacies of a product.
        getEfficaciesForProduct(product) {
            if (!product.efficacies || product.efficacies?.length === 0) return [];

            return flatten(product.efficacies.map((efficacy) => efficacy.targetEfficacies)).map(
                (target) => ({
                    eppoCode: target.targetEppoCode,
                    name: this.$t(`targets.${target.targetEppoCode}.name`),
                    amount: efficacyStringToNumber(target.amount),
                }),
            );
        },
        onCropStrategyClick() {
            this.$openSidebar(
                SidebarContentTypes.CROP_STRATEGY,
                this.$t('sidePanel.cropStrategy'),
                {
                    title: this.getAdviceTitle(this.advice),
                    image: this.cropStrategyImage,
                },
                true,
            );
        },
        onCalculateSeedRateClick() {
            this.$openSidebar(
                SidebarContentTypes.SEED_RATE_CALCULATOR,
                this.$t('recommendations.seedCalculatorTitle'),
                {
                    advice: this.advice,
                    calculationData: this.calculationData,
                },
                true,
            );
        },
    },
};
</script>
