<template>
    <SelectedTargetsList :hasSidebarBtn="false" dontRemoveTargetsFromList />
</template>

<script>
import SelectedTargetsList from '@/components/molecules/SelectedTargetsList.vue';

export default {
    name: 'SidebarTargetsMobile',
    components: { SelectedTargetsList },
    props: {
        content: {
            type: Object,
        },
    },
};
</script>
