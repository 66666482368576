export default {
    SINGLE_SELECT: 'single-select',
    FORCE_SINGLE_SELECT_DROPDOWN: 'force-single-select-dropdown',
    MULTI_SELECT: 'multi-select',
    FORCE_MULTI_SELECT_DROPDOWN: 'force-multi-select-dropdown',
    BOOLEAN: 'boolean',
    NUMBER: 'number',
    SINGLE_AUTOCOMPLETE: 'single-autocomplete',
    MULTI_AUTOCOMPLETE: 'multi-autocomplete',
};
