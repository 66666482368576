<template>
    <div class="collapsable-input-field" :class="classes">
        <button
            class="collapsable-input-field__trigger"
            type="button"
            @click.prevent="toggleOpen"
        />
        <div class="collapsable-input-field__content">
            <InputField
                :modelValue="modelValue"
                :placeholder="$t('targetsPage.searchPlaceholder')"
                icon="fa-magnifying-glass"
                clearable
                @update:modelValue="(val) => $emit('update:modelValue', val)"
                @onClear="() => $emit('update:modelValue', '')"
            />
        </div>
    </div>
</template>

<script>
import InputField from '@/components/atoms/InputField.vue';

export default {
    name: 'CollapsableInputField',
    emits: ['update:modelValue', 'onClear'],
    components: { InputField },
    props: {
        modelValue: {
            type: String,
        },
    },
    data() {
        return {
            opened: false,
        };
    },
    computed: {
        classes() {
            return {
                'collapsable-input-field--opened': this.opened,
                'collapsable-input-field--active-search': this.searchActive,
            };
        },
        searchActive() {
            return this.modelValue?.length > 0;
        },
    },
    methods: {
        toggleOpen() {
            this.opened = !this.opened;
        },
    },
};
</script>
