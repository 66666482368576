<template>
    <div class="number-display" :class="classes">
        <div v-if="label" class="number-display__label">{{ label }}</div>
        <div class="number-display__dots">
            <div v-if="!hideValue" class="number-display__dots-start">{{ prefix }}</div>
            <div class="number-display__dots-row">
                <i v-for="i in max" :class="{ active: i <= value }" />
            </div>
            <div v-if="!hideUnderline" class="number-display__dots-underline-wrapper">
                <div class="number-display__dots-underline">
                    <div
                        :style="{ width: `${(value / max) * 100}%` }"
                        class="number-display__dots-underline-fill"
                    />
                </div>
            </div>
            <div v-if="!hideValue" class="number-display__dots-end">{{ value }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NumberDisplay',
    props: {
        value: {
            type: [Number, String],
            required: true,
        },
        prefix: {
            type: [Number, String],
        },
        label: {
            type: String,
        },
        max: {
            type: Number,
            default: 10,
        },
        hideUnderline: {
            type: Boolean,
            default: false,
        },
        hideValue: {
            type: Boolean,
            default: false,
        },
        centered: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                'number-display--centered': this.centered,
            };
        },
    },
};
</script>
