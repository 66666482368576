import { isInIframe } from '@/utils/helpers';

const inIframe = isInIframe();
const body = inIframe ? document.querySelector('#app') : document.querySelector('body');
let scrollPosition = 0;

export default {
    lock() {
        scrollPosition = window.pageYOffset;
        body.style.position = 'fixed';
        body.style.top = `-${scrollPosition}px`;
        body.style.width = '100%';

        if (inIframe) {
            body.style.overflowY = 'hidden';
        }
    },
    unlock() {
        body.style.removeProperty('position');
        body.style.removeProperty('top');
        body.style.removeProperty('width');

        if (!inIframe) {
            window.scrollTo(0, scrollPosition);
        } else {
            body.style.overflowY = 'scroll';
        }
    },
};
