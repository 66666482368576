<template>
    <a :href="link" class="product-teaser product-teaser--download" target="_blank">
        <div class="product-teaser__image" :style="imageStyle" />
        <span class="product-teaser__title">
            <span>{{ title }}</span>
            <span>{{ subtitle }}</span>
        </span>
    </a>
</template>

<script>
export default {
    name: 'DownloadTeaser',
    props: {
        download: {
            type: Object,
            required: true,
        },
    },
    computed: {
        title() {
            return 'Filename';
        },
        subtitle() {
            return 'Subtitle';
        },
        imageStyle() {
            return {
                backgroundImage: `url('https://placehold.co/200x50')`,
            };
        },
        link() {
            return this.download.link;
        },
    },
};
</script>
