<template>
    <section class="slider">
        <swiper
            :slidesPerView="slidesPerView"
            :spaceBetween="spacing"
            :centeredSlides="centeredSlides"
            :breakpoints="breakpoints"
            ref="slider"
            :navigation="controls && navigationOptions"
            :modules="modules"
            @swiper="onSwiperInit"
            @transitionEnd="onTransitionEnd"
            @slideChange="onSlideChange"
        >
            <swiper-slide v-for="(item, i) in items" :key="`slider-item-${i}`">
                <slot name="item" v-bind:item="item" />
            </swiper-slide>
        </swiper>
        <div v-if="controls" class="slider__controls">
            <Btn ref="backBtn" class="swiper-button-prev"></Btn>
            <Btn ref="nextBtn" class="swiper-button-next"></Btn>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import Btn from '@/components/atoms/Btn.vue';

export default {
    name: 'Slider',
    emits: ['slideChange', 'transitionEnd'],
    components: {
        Btn,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            slider: null,
            modules: [Navigation],
            navigationOptions: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        };
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        slidesPerView: {
            type: [Number, String],
            default: 1,
        },
        slidesPerViewM: {
            type: [Number, String],
            default: 2,
        },
        slidesPerViewL: {
            type: [Number, String],
            default: 3,
        },
        centeredSlides: {
            type: Boolean,
            default: true,
        },
        spacing: {
            type: Number,
            default: 20,
        },
        spacingM: {
            type: Number,
            default: 35,
        },
        spacingL: {
            type: Number,
            default: 50,
        },
        initialSlide: {
            type: Number,
            default: 0,
        },
        controls: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        breakpoints() {
            return {
                701: {
                    // m
                    slidesPerView: this.slidesPerViewM,
                    spaceBetween: this.spacingM,
                },
                1181: {
                    // l
                    slidesPerView: this.slidesPerViewL,
                    spaceBetween: this.spacingL,
                },
            };
        },
    },
    methods: {
        onSwiperInit(swiper) {
            this.slider = swiper;

            if (this.initialSlide !== 0) {
                this.slideTo(this.initialSlide);
            }
        },
        onSlideChange(e) {
            this.$emit('slideChange', e.activeIndex);
        },
        onTransitionEnd() {
            this.$emit('transitionEnd');
        },
        slideTo(index, speed = null) {
            this.slider.slideTo(index, speed, false);
        },
    },
};
</script>
