import { groupBy } from '@/utils/helpers';
import { toRaw } from 'vue';

let lastLoggedErrors = '';

export const checkNextParameters = (parameter, selectedValueWrapper) => {
    const canBeEmpty = parameter.orderedValues.includes('_blank_');

    // If no value is given and the parameter can't be empty, we don't continue.
    if (!selectedValueWrapper?.value?.length && !canBeEmpty) {
        return [];
    }

    const found = [];

    for (const [key, value] of Object.entries(parameter.values)) {
        const splitKeys = key.split(';').map((k) => k.toString());

        if (
            selectedValueWrapper.value.some((val) => splitKeys.includes(val.toString())) ||
            (splitKeys.includes('_blank_') && canBeEmpty && selectedValueWrapper.value.length === 0)
        ) {
            found.push(value);
        }
    }

    return found;
};

export const flattenTree = (tree, enteredValues) => {
    const flattedTree = [];
    const parametersToCheck = [tree];

    while (parametersToCheck.length) {
        let currParameter = parametersToCheck.shift();

        if (!currParameter) {
            continue;
        }

        let selectedValue = enteredValues[currParameter.apiExternalIdentifier];

        flattedTree.push(currParameter);

        if (selectedValue) {
            parametersToCheck.push(...checkNextParameters(currParameter, selectedValue));
        }
    }

    return groupBy(flattedTree, ({ id }) => id);
};

export const mergeTree = (tree, enteredValues, order) => {
    const flattedTree = flattenTree(tree, enteredValues);
    const mergedParameters = [];
    const sorted = [];

    // Merge parameter values.
    for (const group of Object.values(flattedTree)) {
        const firstParam = toRaw(group[0]);
        const parameter  = {
            ...firstParam,
            orderedValues: (firstParam.orderedValues
                ? [...firstParam.orderedValues]
                : firstParam.orderedValues),
            values:        (firstParam.values
                ? { ...firstParam.values }
                : firstParam.values)
        };

        for (let i = 1; i < group.length; i++) {
            group[i].orderedValues.forEach((v) => {
                if (!parameter.orderedValues.includes(v)) {
                    parameter.orderedValues.push(v);
                }
            });
        }

        // We should go through all orderedValues and check if there's a path inside "values".
        parameter.orderedValues.forEach(value => {
            if (!parameter.values.hasOwnProperty(value)) {
                parameter.values[value] = null;
            }
        });

        mergedParameters.push(parameter);
    }

    // Sort parameters.
    for (const key of order) {
        const param = mergedParameters.find((p) => p.id === key);

        if (param) {
            sorted.push(param);
        }
    }

    // Collect and log parameters that have no order assigned.
    const missingEntries = mergedParameters.filter((p) => !sorted.includes(p));

    if (missingEntries.length) {
        const currErrString = missingEntries
            .map((p) => `${p.id} - ${p.apiExternalIdentifier}`)
            .join(', ');

        if (currErrString !== lastLoggedErrors) {
            console.error(`Following parameters have missing order: ${currErrString}`);

            lastLoggedErrors = currErrString;
        }
    }

    return [...sorted, ...missingEntries];
};
