<template>
    <transition name="bottom-bar">
        <nav v-if="isBottomBarActive" class="bottom-bar">
            <div class="bottom-bar__progress" :style="progressBarStyle" />
            <div class="bottom-bar__content">
                <div class="bottom-bar__content-previous-btn-container">
                    <Btn
                        v-if="currentFormStepIndex > 0"
                        :disabled="!canGoBack"
                        type="text"
                        icon="fa-arrow-left"
                        iconPosition="start"
                        @click="onBackwardsClick"
                    >
                        {{ $t('bottomBar.back') }}
                    </Btn>
                </div>
                <div class="bottom-bar__content-counter">
                    <div class="bottom-bar__content-counter-title">
                        {{ $t(formSteps[currentFormStepIndex]?.title ?? '') }}
                    </div>
                    <div v-if="formSteps.length > 2" class="bottom-bar__content-counter-numbers">
                        <span>{{ currentFormStepIndex + 1 }}</span>
                        <span> / </span>
                        <span>{{ formStepCount }}</span>
                    </div>
                </div>
                <div class="bottom-bar__content-next-btn-container">
                    <Btn
                        v-if="currentFormStepIndex < formSteps.length - 1"
                        :disabled="!canGoForwards"
                        icon="fa-arrow-right"
                        iconPosition="end"
                        @click="onForwardsClick"
                    >
                        {{ $t('bottomBar.next') }}</Btn
                    >
                </div>
            </div>
        </nav>
    </transition>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';
import { mapActions, mapState } from 'pinia';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import { useAppStore } from '@/stores/app';

export default {
    name: 'BottomBar',
    components: { Btn },
    computed: {
        ...mapState(useConfigurationFormStore, [
            'formSteps',
            'currentFormStep',
            'currentFormStepIndex',
            'canGoBack',
            'canGoForwards',
            'isBottomBarActive',
            'isOnlyPGRFunctionalGroup',
        ]),
        ...mapState(useAppStore, ['currentService']),

        progressBarStyle() {
            const gapPercentage = 5;
            const widthPercentage =
                ((this.currentFormStepIndex + 1) / this.formStepCount) * 100 - gapPercentage;

            return {
                width: `${widthPercentage}%`,
            };
        },
        formStepCount() {
            if (this.isOnlyPGRFunctionalGroup && this.currentService === 'crop-protection') {
                // When only PGR is selected as FG, the targets page will be skipped,
                // so we make the user think GS is the last step.
                return this.formSteps.length - 2;
            }
            return this.formSteps.length - 1;
        },
    },
    methods: {
        ...mapActions(useConfigurationFormStore, ['onForwardsClick', 'onBackwardsClick']),
    },
};
</script>
