import { defineStore } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';
import apiRoute from '@/utils/route-helper';
import to from 'await-to-js';
import { aceApiClient } from '@/utils/axios';
import captureError from '@/utils/capture-error';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import { useCropProtectionStore } from '@/stores/crop-protection';
import translationArrayHelper from '@/utils/translation-array-helper';

export const useSeedStore = defineStore('seed', {
    state: () => ({
        isInCompareMode: false,
        tankmixesForComparison: [],
        seedRecommendations: {},
        seedRateCalculationData: [],

        cropProtectionStore: useCropProtectionStore(),
        configurationFormStore: useConfigurationFormStore(),
    }),
    actions: {
        async loadRecommendations() {
            if (
                !this.configurationFormStore.selectedCrop ||
                Object.keys(this.configurationFormStore.enteredTreeValues).length === 0
            )
                return;

            this.seedRecommendations = {};

            const baseParams = this.configurationFormStore.baseRequestParameters(true);
            const localizationStore = useLocalizationStore();

            const body = {
                ...baseParams,
                characteristics: this.configurationFormStore.enteredCharacteristics,
                cropEppoCode: this.configurationFormStore.selectedCrop.eppoCode,
                languages: localizationStore.availableLanguages.map((l) => l.language).join(','),
            };
            const route = apiRoute('seedRecommendations');

            this.$loading.startLoading('seeds:load-recommendations');

            const [err, result] = await to(aceApiClient.post(route, body));

            if (err) {
                captureError(err, 'seeds:load-recommendations', true, 'seeds.loadRecommendations');
                this.$loading.endLoading('seeds:load-recommendations');

                return;
            }

            if (result) {
                this.seedRecommendations = result.data;

                await this.loadSeedRateCalculations(
                    this.seedRecommendations.advices.map((a) => a.id),
                );
            }

            this.$loading.endLoading('seeds:load-recommendations');
        },
        toggleCompareMode(state) {
            this.isInCompareMode = state;

            // Clear previous selection.
            this.tankmixesForComparison = [];
        },
        addOrRemoveTankmixForComparison(tankmix) {
            if (this.tankmixesForComparison.includes(tankmix)) {
                this.tankmixesForComparison.splice(this.tankmixesForComparison.indexOf(tankmix), 1);
            } else {
                if (this.tankmixesForComparison.length < 3) {
                    this.tankmixesForComparison.push(tankmix);
                }
            }
        },
        async loadSeedRateCalculations(adviceIds) {
            this.seedRateCalculationData = [];

            if (adviceIds.length === 0) {
                return null;
            }

            const route = apiRoute('seedRateCalculations', null, {
                adviceIds: adviceIds,
            });

            this.$loading.startLoading('seeds:load-seed-rate-calculations');

            const [err, result] = await to(aceApiClient.get(route));

            if (err) {
                captureError(
                    err,
                    'seeds:load-seed-rate-calculations',
                    true,
                    'seeds.loadSeedRateCalculations',
                );
                this.$loading.endLoading('seeds:load-seed-rate-calculations');

                return null;
            }

            if (result) {
                this.seedRateCalculationData = result.data;
            }

            return null;
        },
    },
    getters: {
        getDataValueFromDataListItem: (state) => (item, product) => {
            switch (item.type) {
                case 'parameter':
                    // Type parameter gets its data from the entered parameters.
                    const configStore = useConfigurationFormStore();
                    const treeValues = configStore.enteredTreeValues;

                    for (let i = 0; i < Object.keys(treeValues).length; i++) {
                        const key = Object.keys(treeValues)[i];
                        const p = treeValues[key];
                        const localizationStore = useLocalizationStore();

                        if (p.parameter.externalIdentifier === item.externalIdentifier) {
                            return {
                                value: p.value
                                    ?.map((v) =>
                                        localizationStore.getParameterValueTranslationKey(
                                            p.parameter.apiExternalIdentifier,
                                            v,
                                        ),
                                    )
                                    .join(', '),
                                type: 'text',
                                source: null,
                            };
                        }
                    }

                    return null;
                case 'productMeta':
                    // Product meta values can be found directly in the product.
                    const foundMeta = product.productMetaValues.find(
                        (p) => p.externalIdentifier === item.externalIdentifier,
                    );

                    if (!foundMeta) {
                        return null;
                    }

                    return {
                        value: foundMeta.translatedValues ?? foundMeta.value,
                        type: foundMeta.type,
                        source: foundMeta.translatedSources,
                    };
                case 'characteristic':
                    // Product characteristics values can be found directly in the product.
                    const foundCharacteristic = product.characteristics.find(
                        (c) => c.externalIdentifier === item.externalIdentifier,
                    );

                    if (!foundCharacteristic) {
                        return null;
                    }

                    return {
                        value: foundCharacteristic.value,
                        type: foundCharacteristic.type,
                        source: foundCharacteristic.translatedSources,
                    };
                case 'custom':
                    switch (item.externalIdentifier) {
                        case 'seedRate':
                            return {
                                value: state.cropProtectionStore.getProductDisplayAmount(product),
                                type: 'text',
                                source: null,
                            };
                    }
                    break;
                default:
                    return null;
            }
        },
        getCardParameterValueTranslationKey: (state) => (val) => {
            if (typeof val === 'boolean') {
                return val
                    ? 'recommendations.characteristicValueTrue'
                    : 'recommendations.characteristicValueFalse';
            }
            if (Array.isArray(val)) {
                return translationArrayHelper(val, state.$i18n.locale, 'locale');
            }
            return state.$i18n.t(val);
        },
        seedAdviceSettings: (state) => {
            const localizationStore = useLocalizationStore();
            const crop = state.configurationFormStore.selectedCrop;

            let settings = localizationStore.selectedCountryMeta?.seedAdviceSettings?.find((s) =>
                s.crops.find(
                    (c) => c.eppoCode === crop?.eppoCode && c.amigaCode === crop?.amigaCode,
                ),
            );

            if (!settings) {
                settings = localizationStore.selectedCountryMeta?.seedAdviceSettings?.find(
                    (s) => s.isAllGroup === 1,
                );
            }

            return settings;
        },
    },
});
