<template>
    <div class="recommendation-card-products">
        <div class="recommendation-card-products__label">
            {{ $t('recommendations.products') }}
        </div>

        <ul
            class="recommendation-card-products__list"
            :class="{
                'recommendation-card-products__list--overlay':
                    hasCollapsedProducts && !accordionOpened,
            }"
        >
            <li v-for="product in previewProducts">
                <ProductTeaser :product="getFullProductInfo(product)" />
            </li>
        </ul>

        <Accordion
            v-if="hasCollapsedProducts"
            @onOpened="accordionOpened = true"
            @onClosed="accordionOpened = false"
        >
            <template #footer>
                <Btn
                    type="recommendation-card"
                    icon="fa-chevron-down"
                    iconPosition="end"
                    @click.prevent="null"
                    >{{
                        $t(
                            `recommendations.${
                                accordionOpened ? 'showLessProducts' : 'showMoreProducts'
                            }`,
                        )
                    }}
                </Btn>
            </template>
            <template #content>
                <ul class="recommendation-card-products__list">
                    <li v-for="product in collapsedProducts">
                        <ProductTeaser :product="getFullProductInfo(product)" />
                    </li>
                </ul>
            </template>
        </Accordion>
    </div>
</template>

<script>
import ProductTeaser from '@/components/molecules/Recommendation/ProductTeaser.vue';
import Btn from '@/components/atoms/Btn.vue';
import Accordion from '@/components/atoms/Accordion.vue';
import { mapState } from 'pinia';
import { useCropProtectionStore } from '@/stores/crop-protection';
import _ from 'lodash';

export default {
    name: 'RecommendationCardProducts',
    components: { Accordion, Btn, ProductTeaser },
    props: {
        advice: {
            type: Object,
            required: true,
        },
        collapsable: {
            type: Boolean,
            default: false,
        },
        allRecommendationProducts: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            accordionOpened: false,
        };
    },
    computed: {
        ...mapState(useCropProtectionStore, ['cropRecommendations']),

        allProducts() {
            const sequenceProducts = this.advice.adviceSequences.flatMap((s) => s.adviceProducts);
            const products = [...this.advice.adviceProducts, ...sequenceProducts];

            products.sort((a, b) => a.order - b.order);

            return _.uniqBy(products, 'productId');
        },
        hasCollapsedProducts() {
            return this.collapsedProducts.length > 0 && this.collapsable;
        },
        previewProducts() {
            const max = this.collapsable ? 3 : this.allProducts.length;

            return this.allProducts.slice(0, max);
        },
        collapsedProducts() {
            return this.allProducts.slice(3);
        },
    },
    methods: {
        getFullProductInfo(product) {
            const foundProduct = this.allRecommendationProducts?.find(
                (p) => p.id === product.productId,
            );
            return {
                ...product,
                ...foundProduct,
            };
        },
    },
};
</script>
