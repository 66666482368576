import { defineStore } from 'pinia';
import to from 'await-to-js';
import apiRoute from '@/utils/route-helper';
import captureError from '@/utils/capture-error';
import { aceApiClient } from '@/utils/axios';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: null,
    }),
    actions: {
        async getToken() {
            const credentials = {
                grant_type: 'client_credentials',
                client_id: import.meta.env.VITE_API_CLIENT_ID,
                client_secret: import.meta.env.VITE_API_CLIENT_SECRET,
                scope: 'get-recommendations get-all-master-data',
            };

            const route = apiRoute('getToken');

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'auth:get-token',
                async () => to(aceApiClient.post(route, credentials)),
            );

            if (err) {
                captureError(err, 'auth:get-token', true, 'auth.getToken');
                return;
            }

            if (result) {
                const receivedToken = result.data.access_token;

                this.token = receivedToken;

                // Add token to axios auth header.
                aceApiClient.defaults.headers.common['Authorization'] = `Bearer ${receivedToken}`;
            }
        },
    },
});
