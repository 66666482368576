export default {
  Category: {
    General: 'General',
    CropProtection: 'Crop Protection',
    Seed: 'Seed',
  },
  Type: {
    UpstreamPage: 'Upstream page',
    RecommendationFlow: 'Recommendation flow',
    Recommendation: 'Recommendation',
    Supplementary: 'Supplementary',
    Error: 'Error',
  },
};
