<template>
    <div class="compare-overlay">
        <span>{{ $t('recommendations.compareTankmixesHint') }}</span>
        <Btn icon="fa-times" iconPosition="start" type="white" @click.prevent="onStopCompareClick">
            {{ $t('recommendations.stopCompareTankmixes') }}
        </Btn>
    </div>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';
import { mapActions } from 'pinia';
import { useSeedStore } from '@/stores/seed';

export default {
    name: 'CompareOverlay',
    components: { Btn },
    methods: {
        ...mapActions(useSeedStore, ['toggleCompareMode']),

        onStopCompareClick() {
            this.toggleCompareMode(false);
        },
    },
};
</script>
