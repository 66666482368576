<template>
    <div class="loading-overlay">
        <i class="loading-overlay__spinner fa fa-spinner fa-spin" />
        <span class="loading-overlay__title">
            {{ text }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'LoadingOverlay',
    computed: {
        text() {
            return this.$te(`start.loadingOverlayText`) ||
                this.$te(`start.loadingOverlayText`, 'en_ALL')
                ? this.$t('start.loadingOverlayText')
                : 'Setting things up for you..';
        },
    },
};
</script>
