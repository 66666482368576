<template>
    <div class="share-button">
        <ButtonDropdown
            :label="$t('recommendations.saveTankmixes')"
            icon="fa-save"
            :items="shareOptions"
        >
            <template #item="{ item }">
                <i class="fa" :class="{ [item.checked ? 'fa-check' : item.icon]: true }" />
                <span>{{ $t(item.label) }}</span>
            </template>
        </ButtonDropdown>
    </div>
</template>

<script>
import ButtonDropdown from '@/components/atoms/ButtonDropdown.vue';
import { mapActions } from 'pinia';
import { useDeeplinkStore } from '@/stores/deeplink';
import SidebarContentTypes from '@/utils/constants/sidebar-content-types';

export default {
    name: 'ShareButton',
    components: { ButtonDropdown },
    data() {
        return {
            shareOptions: [
                {
                    identifier: 'copy',
                    label: 'recommendations.copyLink',
                    icon: 'fa-copy',
                    checked: false,
                    action: this.copyLink,
                },
                // {
                //     identifier: 'pdf',
                //     label: 'recommendations.downloadPdf',
                //     icon: 'fa-download',
                //     checked: false,
                //     action: this.downloadPdf,
                // },
                // {
                //     identifier: 'email',
                //     label: 'recommendations.shareEmail',
                //     icon: 'fa-envelope',
                //     checked: false,
                //     action: this.openSidebarEmailForm,
                // },
            ],
        };
    },
    methods: {
        ...mapActions(useDeeplinkStore, ['createShortLink']),

        async copyLink() {
            this.shareOptions.find((o) => o.identifier === 'copy').checked = true;

            const shortLink = await this.createShortLink();

            if (shortLink) {
                // Safari throws error w/o setTimeout...
                setTimeout(async () => {
                    await navigator.clipboard.writeText(shortLink.fullUrl);
                    this.$notify(this.$t('recommendations.copyLinkSuccess'), 'success');
                }, 0);
            }
        },
        async downloadPdf() {
            console.log('download pdf');
        },
        async openSidebarEmailForm() {
            this.$openSidebar(
                SidebarContentTypes.EMAIL_FORM,
                this.$t('recommendations.emailFormTitle'),
            );
        },
    },
};
</script>
