import { reactive } from 'vue';

class LoadingStateHandler {
    constructor() {
        this.states = reactive({});
    }
    isLoading(name) {
        return !!this.states[name];
    }
    startLoading(name) {
        return !this.states[name] && (this.states[name] = Date.now());
    }
    async startLoadingWithCallback(name, cb, defaultReturnValue = []) {
        if (this.isLoading(name)) {
            return defaultReturnValue;
        }

        try {
            this.startLoading(name);
            return await cb();
        } catch (e) {
            throw e;
        } finally {
            this.endLoading(name);
        }
    }
    endLoading(name) {
        const start = this.states[name];
        delete this.states[name];
        return Date.now() - start;
    }
    isAnyLoading() {
        return Object.keys(this.states).length;
    }
    clearAll() {
        let clearList = {};
        Object.keys(this.states).forEach((item) => {
            clearList[item] = this.endLoading(item);
        });
        return clearList;
    }
}

export default {
    install: (app) => {
        app.config.globalProperties.$loading = new LoadingStateHandler();
    },
};
