<template>
    <component
        class="btn"
        :class="classes"
        :is="getComponent"
        :href="href"
        :to="to"
        type="button"
        v-bind="submitBtn ? { type: 'submit' } : {}"
    >
        <InlineSvg v-if="additionalGraphic" class="btn__graphic" :src="additionalGraphicImg" />

        <div v-if="badge" class="btn__badge">{{ badge }}</div>
        <div class="btn__content">
            <div v-if="icon" class="btn__content-icon fas" :class="iconClasses"></div>
            <span>
                <slot></slot>
            </span>
            <div
                v-if="additionalIcon"
                class="btn__content-icon btn__content-icon--additional fas"
                :class="additionalIcon"
            ></div>
        </div>
    </component>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'Btn',
    components: { InlineSvg },
    props: {
        to: {
            type: String,
        },
        href: {
            type: String,
        },
        type: {
            type: String,
            validator: (val) =>
                [
                    'default',
                    'text',
                    'recommendation-card',
                    'translucent',
                    'white',
                    'green',
                    'link',
                ].includes(val),
            default: 'default',
        },
        icon: {
            type: String,
        },
        iconPosition: {
            type: String,
            default: 'end',
            validator: (val) => ['start', 'end'].includes(val),
        },
        badge: {
            type: [String, Number],
        },
        additionalGraphic: {
            type: String,
        },
        additionalIcon: {
            type: String,
        },
        submitBtn: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                [`btn--${this.type}`]: true,
            };
        },
        iconClasses() {
            return {
                [this.icon]: true,
                'btn__content-icon--start': this.iconPosition === 'start',
                'btn__content-icon--end': this.iconPosition === 'end',
            };
        },
        getComponent() {
            if (this.to) {
                return 'router-link';
            } else if (this.href) {
                return 'a';
            }

            return 'button';
        },
        additionalGraphicImg() {
            const img =
                new URL(`../../assets/images/icons/${this.additionalGraphic}.svg`, import.meta.url)
                    .href ?? null;

            if (img.includes('undefined')) {
                return null;
            }

            return img;
        },
    },
};
</script>
