<template>
    <component
        :is="grid ? 'GridList' : 'HorizontalList'"
        :columns="gridColumns"
        :twoXSColumns="twoXSGridColumns"
    >
        <li v-for="(opt, i) in options" :key="`${name}-item-${i}`">
            <div :data-active="isOptionSelected(opt)" @click="() => onInput(opt)">
                <slot name="content" v-bind:item="opt" v-bind:active="isOptionSelected(opt)"></slot>
            </div>
        </li>
    </component>
</template>

<script>
import HorizontalList from '@/components/atoms/HorizontalList.vue';
import GridList from '@/components/atoms/GridList.vue';
import OptionCard from '@/components/atoms/OptionCard.vue';
import { toRaw } from 'vue';

export default {
    name: 'OptionSelectList',
    emits: ['update:modelValue', 'onMaxItemsSelected'],
    components: { OptionCard, HorizontalList, GridList },
    props: {
        modelValue: {},
        name: {
            type: String,
            required: true,
        },
        optionIdentifier: {
            // Use identifier (e.g. object ID) if the options are dynamic and can change.
            // Otherwise selected options will break after options change from outside.
            type: String,
            default: null,
        },
        options: {
            type: Array,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        maxItems: {
            type: Number,
            default: -1,
        },
        emptyValue: {
            type: [String, Object],
            default: null,
        },
        grid: {
            type: Boolean,
            default: false,
        },
        gridColumns: {
            type: Number,
            default: 5,
        },
        twoXSGridColumns: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedItems: [],
        };
    },
    created() {
        this.$nextTick(() => {
            this.updateSelectedItems(this.modelValue);
        });
    },
    computed: {
        selectedItemsMapped() {
            return this.optionIdentifier
                ? this.selectedItems.map((i) => i[this.optionIdentifier])
                : this.selectedItems;
        },
    },
    methods: {
        onInput(option) {
            const optionToCompare = this.optionIdentifier ? option[this.optionIdentifier] : option;

            if (this.multiple) {
                // Select / Deselect items.
                if (this.selectedItemsMapped.includes(optionToCompare)) {
                    this.selectedItems.splice(this.selectedItemsMapped.indexOf(optionToCompare), 1);
                } else {
                    if (this.maxItems === -1 || this.selectedItems.length < this.maxItems) {
                        this.selectedItems.push(option);
                    } else {
                        this.$emit('onMaxItemsSelected');
                    }
                }
            } else {
                if (this.emptyValue !== null) {
                    if (this.selectedItemsMapped.includes(optionToCompare)) {
                        // If same item is already selected, value will change to emptyValue.
                        this.selectedItems = [this.emptyValue];
                    } else {
                        // Otherwise we set the single item.
                        this.selectedItems = [option];
                    }
                } else {
                    // Just set to a single item.
                    this.selectedItems = [option];
                }
            }

            const returnValue = this.multiple ? this.selectedItems : this.selectedItems[0];

            this.$emit('update:modelValue', returnValue);
        },
        updateSelectedItems(value) {
            // Selected items should always be an array.
            const initialValue = toRaw(value) instanceof Array ? value : [value];

            this.selectedItems = initialValue ?? [];
        },
        isOptionSelected(option) {
            const identifier = this.optionIdentifier;

            return identifier
                ? this.selectedItemsMapped.includes(option[identifier])
                : this.selectedItems.includes(option);
        },
    },
    watch: {
        modelValue(newValue, oldValue) {
            // We have to handle outside model changes manually because the is a custom input.
            this.updateSelectedItems(newValue);
        },
    },
};
</script>
