<template>
    <div class="product" v-sub-tag-wrapper>
        <span
            >{{ productName }}<sup v-if="footnoteIndex">{{ footnoteIndex }}</sup></span
        >
        <span>{{ applicationRate }}</span>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useCropProtectionStore } from '@/stores/crop-protection';

export default {
    name: 'ProductAmount',
    props: {
        product: {
            type: Object,
            required: true,
        },
        isWater: {
            type: Boolean,
            default: false,
        },
        footnoteIndex: {
            type: Number,
            required: false,
        },
    },
    computed: {
        ...mapState(useCropProtectionStore, ['getProductById', 'getProductDisplayAmount', 'getProductDisplayAmountFreeText']),

        applicationRate() {
            if (this.isWater && this.product?.applicationWaterType === 'freeText') {
                return this.getProductDisplayAmountFreeText(this.product.applicationFreeText, this.product.applicationRateUnitCode);
            }
            return this.getProductDisplayAmount(this.product);
        },
        productName() {
            if (this.product.name) {
                return this.product.name;
            }
            return this.product.productId
                ? this.$t(`products.${this.product.productId}.name`)
                : this.productDetails?.name;
        },
        productDetails() {
            return this.getProductById(this.product.productId);
        },
    },
};
</script>
