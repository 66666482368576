import '@/scss/app.scss';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { useNotificationsStore } from '@/stores/notifications';
import VueSelect from 'vue-select';
import Sticky from 'vue3-sticky-directive';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import './newRelicAgent';

import App from '@/App.vue';
import router from '@/router';
import initSentry from '@/sentry';
import i18n, { instance } from '@/i18n';
import loadingStateHandler from '@/utils/loading-state-handler';
import { useAppStore } from '@/stores/app';
import { useSidebarStore } from '@/stores/sidebar';
import IframeResize from '@/utils/directives/iframeResize';
import SubTagWrapper from '@/utils/directives/subTagWrapper';
import '@/utils/validators';

const app = createApp(App);

const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(loadingStateHandler);
app.use(Sticky);
app.directive('resize', IframeResize);
app.directive('sub-tag-wrapper', SubTagWrapper);

// Create global property to send notifications.
const notificationStore = useNotificationsStore();
const sidebarStore = useSidebarStore();
app.config.globalProperties.$notify = notificationStore.addNotification;

// Inject loading state handler into store.
pinia
    .use(({ store }) => {
        store.$loading = app.config.globalProperties.$loading;
        store.$i18n = instance;
        store.$notify = app.config.globalProperties.$notify;
        store.router = router;
        store.$openSidebar = sidebarStore.openSidebar;
    })
    .use(piniaPluginPersistedstate);

const appStore = useAppStore();

app.config.globalProperties.$translationMappingKey = appStore.translationMappingKey;

app.component('v-select', VueSelect);

app.config.globalProperties.$openSidebar = sidebarStore.openSidebar;
app.config.globalProperties.$openModalWithTimer = sidebarStore.openModalWithTimer;
app.config.globalProperties.$hideSidebar = sidebarStore.hideSidebar;

// If we're in DEV mode, we don't want to initialize Sentry.
if (!import.meta.env.DEV) {
    initSentry(app, router, import.meta.env.VITE_SENTRY_DSN);
}

app.mount('#app');

// Show version in console.
console.log(`version: ${__APP_VERSION__}`);

export default app;
