<template>
    <div class="sidebar-growing-conditions">
        <div class="sidebar-growing-conditions__parameters">
            <template v-for="param in parameters">
                <ParameterInputWrapper
                    :inputType="param.type"
                    :customLabel="$t(`parameters.${param.id}.name_translations`)"
                    :possibleOptions="getPossibleOptions(param)"
                    :parameterId="param.id"
                    :parameterIdentifier="param.apiExternalIdentifier"
                    :savedValue="getSavedParameterValue(param)"
                    :onInput="(val) => onInput(param, val)"
                    canBeEmpty
                    limitInputTypes
                    hasToggleBackground
                />
            </template>
        </div>
        <Btn @click.prevent="onApply">{{ $t('sidePanel.specifyGrowingConditions') }}</Btn>
    </div>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';
import { mapActions, mapState } from 'pinia';
import { useCropProtectionStore } from '@/stores/crop-protection';
import ParameterInputWrapper from '@/components/molecules/ParameterInputWrapper.vue';
import { useConfigurationFormStore } from '@/stores/configuration-form';

export default {
    name: 'SidebarGrowingConditions',
    components: { ParameterInputWrapper, Btn },
    data() {
        return {
            // We don't want to update the store on input, so we store the user inputs until confirmation.
            inputs: {},
        };
    },
    mounted() {
        this.parameters.forEach((p) => {
            this.inputs[p.apiExternalIdentifier] = this.getSavedParameterValue(p);
        });
    },
    computed: {
        ...mapState(useCropProtectionStore, ['parameterConfiguration']),
        ...mapState(useConfigurationFormStore, ['getAdditionalInputValueByParameterName']),

        parameters() {
            return this.parameterConfiguration?.additionalFilterParameters;
        },
    },
    methods: {
        ...mapActions(useCropProtectionStore, ['loadRecommendations']),
        ...mapActions(useConfigurationFormStore, [
            'setAdditionalInputValueByParameterIdentifier',
            'markEntityForReload',
        ]),

        async onApply() {
            this.$hideSidebar();

            Object.keys(this.inputs).forEach((paramKey) => {
                const param = this.parameters.find((p) => p.apiExternalIdentifier === paramKey);
                let value = this.inputs[paramKey];

                value = value.map((v) => v.toString());

                // Set input value in store.
                this.setAdditionalInputValueByParameterIdentifier(param.apiExternalIdentifier, {
                    value: value,
                    parameter: param,
                });
            });

            this.markEntityForReload('recommendations');
            await this.loadRecommendations();
        },
        onInput(param, value) {
            if (!this.inputs.hasOwnProperty(param.apiExternalIdentifier)) {
                this.inputs[param.apiExternalIdentifier] = [];
            }

            if (!(value instanceof Array)) {
                value = [value];
            }

            this.inputs[param.apiExternalIdentifier] = value;
        },
        getPossibleOptions(param) {
            let options = param.orderedValues || Object.keys(param.values).map((key) => key);

            if (
                param.values['_blank_'] ||
                param.orderedValues.find((v) => v === '_blank_') !== undefined
            ) {
                options = options.filter((o) => o !== '_blank_');
            }

            return options;
        },
        getSavedParameterValue(param) {
            const savedParamValue = this.getAdditionalInputValueByParameterName(
                param.apiExternalIdentifier,
            );
            return savedParamValue ?? param.defaultValues;
        },
    },
};
</script>
