<template>
    <div class="select-button" :class="classes">
        <div v-if="checkbox" class="select-button__checkbox">
            <Toggle :modelValue="active" />
        </div>

        <template v-if="image">
            <div v-if="!isSVGImage" class="select-button__img" :style="imageStyle" />
            <InlineSvg v-else :src="image" class="select-button__img" />
        </template>

        <div class="select-button__content">
            <span v-if="subtitle">{{ subtitle }}</span>
            <strong>{{ title }}</strong>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import Toggle from '@/components/atoms/Toggle.vue';

export default {
    name: 'SelectButton',
    components: { Toggle, InlineSvg },
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
        },
        image: {
            type: String,
        },
        isSVGImage: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
        checkbox: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                'select-button--active': this.active,
                'select-button--with-image': this.image,
                'select-button--with-svg-image': this.image && this.isSVGImage,
                'select-button--with-checkbox': this.checkbox,
            };
        },
        imageStyle() {
            return {
                backgroundImage: `url(${this.image})`,
            };
        },
    },
};
</script>
