const apis = {
    // App
    init: '/agsf/init',
    // Auth
    getToken: '/oauth/token',
    // Entities
    advices: '/agsf/advices',
    cropVarieties: '/agsf/crop-varieties',
    crops: '/agsf/crops',
    parameters: '/agsf/parameters',
    growthStages: '/agsf/growth-stages',
    products: '/agsf/products',
    recommendations: '/agsf/recommendations',
    seedRecommendations: '/agsf/seed-recommendations',
    targetAdviceData: '/agsf/target-advice-data',
    targets: '/agsf/targets',
    externalApiTest: '/agsf/external-api-test/progress',
    countries: '/agsf/countries',
    entityTranslations: '/agsf/translations/entities',
    interfaceTranslations: '/agsf/translations/interface',
    parameterConfiguration: '/agsf/parameter-configurations',
    shortLink: '/agsf/short-links',
    adviceImages: '/agsf/advice-images',
    recommendationsMail: '/agsf/send-advice-recommendations',
    growthStageImage: '/storage/images/growth-stage-illustrations',
    teaser: '/agsf/teaser-contents',
    staticContent: '/agsf/static-contents',
    linksAndResources: '/agsf/crop-links-and-resources',
    seedRateCalculations: '/agsf/seed-rate-calculations',
};

// Generates an absolute API url to given entity with given parameters.
// Usage: apiRoute('advices', 2, { param: 123 })
export default function apiRoute(key, id = null, params = {}, sanitize = true) {
    let url = apis[key] + (id !== null ? '/' + id : '');
    let concat = '?';

    // Iterate through object keys of params.
    for (const key in params) {
        if (!sanitize || (params[key] !== 0 && params[key] !== '')) {
            // Append new query parameter to url.
            url += concat + key + '=' + params[key];
            concat = '&';
        }
    }

    return url;
}
