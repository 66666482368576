<template>
    <Form class="sidebar-email-form" @submit="onSendClick">
        <InputField
            v-model="toEmail"
            identifier="toEmail"
            type="email"
            :label="$t('recommendations.emailFormToLabel')"
            :placeholder="$t('recommendations.emailFormToPlaceholder')"
            rules="required|email"
        />
        <InputField
            v-model="fromEmail"
            identifier="fromEmail"
            type="email"
            :label="$t('recommendations.emailFormFromLabel')"
            :placeholder="$t('recommendations.emailFormFromPlaceholder')"
            rules="required|email"
        />
        <InputField
            v-model="subject"
            identifier="subject"
            :label="$t('recommendations.emailFormSubjectLabel')"
            :placeholder="$t('recommendations.emailFormSubjectPlaceholder')"
            rules="required"
        />
        <InputField
            v-model="mailContent"
            identifier="mailContent"
            multiline
            :label="$t('recommendations.emailFormContentLabel')"
            :placeholder="$t('recommendations.emailFormContentPlaceholder')"
        />

        <hr />

        <h3>{{ $t('recommendations.emailFormRecommendationsHeadline') }}</h3>

        <!--Display first 5 advices-->
        <ul class="sidebar-email-form__recommendation-list">
            <li v-for="(group, key) in initialAdvicesGroupedByFunctionalGroup" :key="key">
                <h5>{{ $t(`functionalGroups.${key}.name`) }}</h5>
                <ul>
                    <li v-for="recommendation in group" :key="recommendation.id">
                        <Toggle
                            :label="getAdviceTitle(recommendation, ', ')"
                            :subtitle="getRecommendationSubtitle(recommendation)"
                            :modelValue="isRecommendationSelected(recommendation.id)"
                            :disabled="isRecommendationSelectionDisabled(recommendation)"
                            @update:modelValue="
                                (val) => toggleRecommendation(recommendation.id, val)
                            "
                        />
                    </li>
                </ul>
            </li>
        </ul>

        <!--Remaining advices are hidden in accordion-->
        <Accordion v-if="remainingDisplayAdvices.length">
            <template #head>
                <Btn type="recommendation-card"
                    >{{ $t('recommendations.emailFormShowAllAdvices') }}
                </Btn>
            </template>
            <template #content>
                <ul class="sidebar-email-form__recommendation-list">
                    <li v-for="(group, key) in remainingAdvicesGroupedByFunctionalGroup" :key="key">
                        <h5>{{ $t(`functionalGroups.${key}.name`) }}</h5>
                        <ul>
                            <li v-for="recommendation in group" :key="recommendation.id">
                                <Toggle
                                    :label="getAdviceTitle(recommendation, ', ')"
                                    :subtitle="getRecommendationSubtitle(recommendation)"
                                    :modelValue="isRecommendationSelected(recommendation.id)"
                                    :disabled="isRecommendationSelectionDisabled(recommendation)"
                                    @update:modelValue="
                                        (val) => toggleRecommendation(recommendation.id, val)
                                    "
                                />
                            </li>
                        </ul>
                    </li>
                </ul>
            </template>
        </Accordion>

        <Btn
            submitBtn
            :disabled="
                $loading.isLoading('crop-protection:send-recommendations-mail') ||
                selectedRecommendations.length === 0
            "
            >{{ $t('recommendations.emailFormSendButton') }}
        </Btn>
    </Form>
</template>

<script>
import InputField from '@/components/atoms/InputField.vue';
import { mapActions, mapState } from 'pinia';
import { useCropProtectionStore } from '@/stores/crop-protection';
import Toggle from '@/components/atoms/Toggle.vue';
import Btn from '@/components/atoms/Btn.vue';
import { groupBy } from '@/utils/helpers';
import Accordion from '@/components/atoms/Accordion.vue';
import { Form } from 'vee-validate';
import { useSeedStore } from '@/stores/seed';
import { useAppStore } from '@/stores/app';

export default {
    name: 'SidebarEmailForm',
    components: { Accordion, Btn, Toggle, InputField, Form },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            toEmail: '',
            fromEmail: '',
            subject: '',
            mailContent: '',
            advicesToPreview: 5,
            recommendationStatuses: {},
        };
    },
    beforeUnmount() {
        this.recommendationStatuses = {};
    },
    mounted() {
        if (this.recommendationAdvices?.length) {
            this.toggleRecommendation(this.recommendationAdvices[0].id, true);
        }
    },
    computed: {
        ...mapState(useCropProtectionStore, ['cropRecommendations', 'getAdviceTitle', 'getProductDisplayAmount']),
        ...mapState(useSeedStore, ['seedRecommendations']),
        ...mapState(useAppStore, ['currentService']),

        recommendationAdvices() {
            return this.currentService === 'seeds'
                ? this.seedRecommendations?.advices
                : this.cropRecommendations?.advices;
        },
        initialDisplayAdvices() {
            return this.recommendationAdvices.slice(0, this.advicesToPreview);
        },
        remainingDisplayAdvices() {
            return this.recommendationAdvices.slice(
                this.advicesToPreview,
                this.recommendationAdvices.length,
            );
        },
        initialAdvicesGroupedByFunctionalGroup() {
            return groupBy(
                this.initialDisplayAdvices,
                ({ functionalGroupCode }) => functionalGroupCode,
            );
        },
        remainingAdvicesGroupedByFunctionalGroup() {
            return groupBy(
                this.remainingDisplayAdvices,
                ({ functionalGroupCode }) => functionalGroupCode,
            );
        },
        selectedRecommendations() {
            return this.recommendationAdvices.filter(
                (advice) => this.recommendationStatuses[advice.id],
            );
        },
    },
    methods: {
        ...mapActions(useCropProtectionStore, ['sendRecommendationsMail']),

        async onSendClick() {
            const options = {
                receiver: this.toEmail,
                sender: this.fromEmail,
                subject: this.subject,
                content: this.mailContent,
                adviceIds: this.selectedRecommendations.map((a) => a.id).join(';'),
            };
            const status = await this.sendRecommendationsMail(options);

            if (status) {
                this.$notify(this.$t('recommendations.mailSentSuccessfully'), 'success');
                this.$hideSidebar();
            }
        },
        getRecommendationSubtitle(recommendation) {
            const products = recommendation.adviceProducts;
            return products.map((p) => `${this.getProductDisplayAmount(p)}`).join(',');
        },
        isRecommendationSelected(recommendationId) {
            return this.recommendationStatuses[recommendationId] || false;
        },
        isRecommendationSelectionDisabled(recommendation) {
            return (
                this.selectedRecommendations.length > 2 &&
                !this.recommendationStatuses[recommendation.id]
            );
        },
        toggleRecommendation(recommendationId, isEnabled) {
            this.recommendationStatuses[recommendationId] = isEnabled;
        },
    },
};
</script>
