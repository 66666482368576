<template>
    <div class="text-display">
        <div class="text-display__label">
            {{ label }}
        </div>
        <div class="text-display__value">
            {{ value }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'TextDisplay',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
};
</script>
