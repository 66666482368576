import iframeResize from 'iframe-resizer/js/iframeResizer';

export default {
    mounted(el, binding) {
        const options = binding.value || {};

        el.addEventListener('load', () => iframeResize(options, el));
    },
    unmounted(el) {
        const resizableEl = el;

        if (resizableEl.iFrameResizer) {
            resizableEl.iFrameResizer.removeListeners();
        }
    },
};
