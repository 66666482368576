<template>
    <div class="counter" :class="classes">
        <strong class="counter__content">
            <span>{{ value }}</span>
            /
            <span>{{ max }} </span>
        </strong>
    </div>
</template>

<script>
export default {
    name: 'Counter',
    props: {
        value: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
    },
    computed: {
        classes() {
            return {
                'counter--active': this.value > 0,
                'counter--max': this.value === this.max,
            };
        },
    },
};
</script>
