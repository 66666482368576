<template>
    <div class="iframe-test">
        <section class="iframe-test__text">
            <p>
                <h2>Die digitale Produktempfehlung</h2>
                <br>
                <h3>AgSolutions Finder</h3>
                <br>
                Erhalten Sie mit nur wenigen Klicks
                eine spezifische und praktikable Produktempfehlung. Abgestimmt auf Ihre individuelle
                Anbausituation ermittelt Ihnen der AgSolutions Finder eine Produktempfehlung zur Planung
                Ihrer Pflanzenschutzmaßnahme. Oder lassen Sie sich vom AgSolutions Finder bei der
                Auswahl des für Sie passenden Raps-Saatguts beraten.

                Profitieren Sie jederzeit und an jedem Ort von unserem agronomischen Wissen!
                Sie können den AgSolutions Finder kostenlos im Web nutzen.
                Digitale Produktempfehlung Erhalten Sie mit dem AgSolutions Finder von
                BASF eine spezifische Produktempfehlung für Ihre individuelle Anbausituation.
            </p>
            <br>
            <h4>Lassen Sie sich beraten:</h4>
        </section>
        <section>
            <iframe v-resize :src="url" frameborder="0" class="iframe-test__iframe" />
        </section>
    </div>
</template>
<script>
export default {
    name: 'IFrameTest',
    computed: {
        url() {
            return `${window.location.protocol}//${window.location.host}/country/ES/service-select`;
        },
    },
};
</script>
