import { defineStore } from 'pinia';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import { useCropProtectionStore } from '@/stores/crop-protection';
import FormStepKeys from '@/utils/constants/form-step-keys';
import AllowedUrlParameters from '@/utils/constants/allowed-url-parameters';
import { aceApiClient } from '@/utils/axios';
import apiRoute from '@/utils/route-helper';
import to from 'await-to-js';
import captureError from '@/utils/capture-error';
import { checkNextParameters } from '@/utils/tree-helpers';
import { useSidebarStore } from '@/stores/sidebar';
import SidebarContentTypes from '@/utils/constants/sidebar-content-types';
import { useAppStore } from '@/stores/app';
import { useSeedStore } from '@/stores/seed';

const VALUE_SEPARATOR = ';';

export const useDeeplinkStore = defineStore('deeplink', {
    state: () => ({
        deeplink: '',
        fullUrl: '',
        allowRedirectToFirstMissingFormStep: false,
        hasSelectedAnotherCrop: false,
        shouldShowPopup: false,
        appliedData: {
            crop: null,
            parameters: [],
            growthStage: null,
            targets: [],
        },

        cropProtectionStore: useCropProtectionStore(),
        seedStore: useSeedStore(),
        configurationFormStore: useConfigurationFormStore(),
        sidebarStore: useSidebarStore(),
    }),
    actions: {
        setHasSelectedAnotherCrop(state) {
            this.hasSelectedAnotherCrop = state;
        },
        // Generate deeplink on form update.
        updateDeeplink(formState, updateUrl = true) {
            const cropSegment = this.generateCropSegment(formState.selectedCrop);
            const treeSegment = this.generateParametersSegment(formState.enteredTreeValues);
            const additionalParametersSegment = this.generateAdditionalParametersSegment(
                formState.additionalFilterInputs,
            );
            const characteristicsSegment = this.generateCharacteristicsSegment(
                formState.enteredCharacteristics,
            );
            const growthStageSegment = this.generateGrowthStageSegment(
                formState.selectedGrowthStage,
            );
            const targetsSegment = this.generateTargetsSegment(
                this.configurationFormStore.activeTargets,
            );

            // Get the user inputs and put them in array.
            // The parameters in the URL should only go as far as the current form step...
            // E.g. user is on growth-stages step => the targets (next step) shouldn't be in the URL.
            const segments = [];
            const currFormStepIndex = this.configurationFormStore.currentFormStepIndex;

            if (cropSegment && currFormStepIndex >= FormStepKeys.CROP) segments.push(cropSegment);
            if (treeSegment && currFormStepIndex >= FormStepKeys.PARAMETERS)
                segments.push(...treeSegment);
            if (growthStageSegment && currFormStepIndex >= FormStepKeys.GROWTH_STAGE)
                segments.push(growthStageSegment);
            if (targetsSegment && currFormStepIndex >= FormStepKeys.TARGETS)
                segments.push(targetsSegment);
            if (additionalParametersSegment && currFormStepIndex >= FormStepKeys.RESULT)
                segments.push(...additionalParametersSegment);
            if (characteristicsSegment && currFormStepIndex >= FormStepKeys.PARAMETERS)
                segments.push(...characteristicsSegment);

            // Keep custom parameters.
            const customParametersSegment = this.readCustomParametersFromUrl();
            if (customParametersSegment) segments.push(...customParametersSegment);

            // Generate & encode URL.
            this.deeplink = segments.join('&');

            const base =
                window.location.protocol + '//' + window.location.host + window.location.pathname;
            const fullUrl = encodeURI(`${base}?${this.deeplink}`);

            this.fullUrl = fullUrl;

            // Update actual URL.
            if (
                updateUrl &&
                window.location.href !== this.fullUrl &&
                !this.$loading.isLoading('deeplink:apply-deep-link')
            ) {
                window.history.replaceState(null, null, this.fullUrl);
            }

            return fullUrl;
        },
        // Triggers the function without having to input the state.
        triggerUpdateDeeplink() {
            this.updateDeeplink(this.configurationFormStore.$state);
        },

        generateCropSegment(crop) {
            return crop ? `crop=${crop.eppoCode}` : '';
        },
        generateParametersSegment(treeValues) {
            const activeTreeParameters =
                this.configurationFormStore.collectActiveFieldsInParameterConfiguration(false);
            const segments = [];
            const keys = [];
            const order =
                this.cropProtectionStore.parameterConfiguration?.recommendationFlowParameterOrder;

            if (!order) return;

            for (const key of order) {
                const param = activeTreeParameters.find((p) => p.id === key);

                if (param && treeValues.hasOwnProperty(param.apiExternalIdentifier)) {
                    keys.push(param.apiExternalIdentifier);
                }
            }

            keys.forEach((key) => {
                const param = treeValues[key];
                const encodedValues = param.value.map(encodeURIComponent);
                encodedValues.sort();

                const value = encodedValues.join(VALUE_SEPARATOR);
                const str = `${param.parameter.externalIdentifier}=${value}`;

                segments.push(str);
            });

            return segments;
        },
        generateAdditionalParametersSegment(additionalParameters) {
            const segments = [];

            Object.keys(additionalParameters).forEach((key) => {
                const param = additionalParameters[key];
                const encodedValues = param.value.map(encodeURIComponent);
                encodedValues.sort();

                const value = encodedValues.join(VALUE_SEPARATOR);
                const str = `${param.parameter.externalIdentifier}=${value}`;

                segments.push(str);
            });

            return segments;
        },
        generateCharacteristicsSegment(characteristics) {
            const appStore = useAppStore();
            const isSeedService = appStore.currentService === 'seeds';

            if (!isSeedService) {
                return [];
            }

            const segments = [];

            Object.keys(characteristics).forEach((identifier) => {
                segments.push(`${identifier}=${characteristics[identifier]}`);
            });

            return segments;
        },
        generateGrowthStageSegment(growthStage) {
            return growthStage ? `growthStage=${growthStage.code}` : '';
        },
        generateTargetsSegment(targets) {
            if (!targets?.length) {
                return '';
            }
            const mappedTargets = targets.map((t) => t.eppoCode);
            mappedTargets.sort();

            return `targets=${mappedTargets.join(VALUE_SEPARATOR)}`;
        },

        // Extract inputs from url.
        readDataFromUrl() {
            const queryString = decodeURI(window.location.search);
            const urlParams = new URLSearchParams(queryString);
            const data = {};

            for (const entry of urlParams.entries()) {
                data[entry[0]] = entry[1];
            }

            return data;
        },
        // Extract custom parameters (e.g. requestingSystem=).
        readCustomParametersFromUrl() {
            const allParams = this.readDataFromUrl();

            return Object.entries(allParams)
                .filter(([param]) => AllowedUrlParameters.includes(param))
                .map(([param, value]) => `${param}=${encodeURIComponent(value)}`);
        },

        // Applying values of deeplink.
        async validateAndApplyDeeplink(awaitConfirmation = false) {
            const data = this.readDataFromUrl();

            if (!data || Object.keys(data).length === 0) {
                // There is no deeplink.
                return;
            }

            const appStore = useAppStore();
            const isSeedService = appStore.currentService === 'seeds';
            // When only FG 'PGR' is set we don't require targets.
            const areTargetsRequired = data.functionalGroupCodes !== 'PGR';

            // Check if we should redirect to first incomplete form-step on invalid input.
            if (this.readCustomParametersFromUrl()?.includes('showPopup=true')) {
                this.allowRedirectToFirstMissingFormStep = true;
                this.shouldShowPopup = true;
            }

            this.$loading.startLoading('deeplink:apply-deep-link');

            // Try to apply the user inputs.
            const cropSet = await this.applyCrop(data.crop);
            const paramConfigSet = cropSet ? await this.applyParameters(data) : false;
            const growthStageSet =
                cropSet && !isSeedService ? await this.applyGrowthStage(data.growthStage) : false;
            const targetsSet =
                growthStageSet && !isSeedService && areTargetsRequired
                    ? await this.applyTargets(data.targets)
                    : false;

            const seedSpecificDataSet = cropSet && paramConfigSet;

            let isDeepLinkValid =
                (isSeedService && seedSpecificDataSet) ||
                (cropSet &&
                    paramConfigSet &&
                    growthStageSet &&
                    (targetsSet || !areTargetsRequired));

            if (isDeepLinkValid) {
                this.$loading.endLoading('deeplink:apply-deep-link');

                // Make sure showPopup parameter is deleted.
                const url = new URL(window.location.href);
                const params = new URLSearchParams(url.search);

                params.delete('showPopup');
                url.search = params.toString();
                window.history.replaceState(null, null, url.toString());

                if (awaitConfirmation && this.shouldShowPopup) {
                    this.sidebarStore.openModalWithTimer(
                        SidebarContentTypes.DEEPLINK,
                        this.$i18n.t('sidePanel.deeplinkPopupTitle'),
                        null,
                        async () => await this.gotoDeeplinkResults(),
                        10,
                    );
                    return;
                }

                await this.gotoDeeplinkResults();
            } else {
                // Redirect to first form-step with missing data when enabled.
                if (this.allowRedirectToFirstMissingFormStep) {
                    let redirectFormStep = FormStepKeys.CROP;

                    if (cropSet && !paramConfigSet) {
                        redirectFormStep = FormStepKeys.PARAMETERS;
                    } else if (cropSet && paramConfigSet && !growthStageSet) {
                        redirectFormStep = FormStepKeys.GROWTH_STAGE;
                    } else if (cropSet && paramConfigSet && growthStageSet && !targetsSet) {
                        redirectFormStep = FormStepKeys.TARGETS;
                    }

                    this.configurationFormStore.setCurrentStep(
                        this.configurationFormStore.formSteps.findIndex(
                            (s) => s.identifier === redirectFormStep,
                        ),
                    );
                }

                // Update the URL (remove stuff that couldn't be applied).
                this.$loading.endLoading('deeplink:apply-deep-link');
                this.updateDeeplink(this.configurationFormStore.$state);

                if (!cropSet) console.log('Crop missing');
                if (!paramConfigSet) console.log('Param config missing or invalid');
                if (!growthStageSet && !isSeedService)
                    console.log('Growth-stage missing or invalid');
                if (!targetsSet && !isSeedService) console.log('Targets missing or invalid');
            }
        },
        async gotoDeeplinkResults() {
            const appStore = useAppStore();
            const isSeedService = appStore.currentService === 'seeds';
            const recommendationStep = isSeedService
                ? FormStepKeys.SEED_RESULT
                : FormStepKeys.RESULT;

            this.configurationFormStore.setCurrentStep(
                this.configurationFormStore.formSteps.findIndex(
                    (s) => s.identifier === recommendationStep,
                ),
            );

            if (isSeedService) {
                await this.seedStore.loadRecommendations();
            } else {
                await this.cropProtectionStore.loadRecommendations();
            }
        },

        async applyCrop(crop) {
            const foundCrop = this.cropProtectionStore.getCropByEppoCode(crop);

            if (foundCrop) {
                this.appliedData['crop'] = foundCrop;

                await this.configurationFormStore.setSelectedCrop(foundCrop);
                this.setHasSelectedAnotherCrop(false);
                return true;
            }

            return false;
        },
        async applyParameters(data) {
            const appStore = useAppStore();
            const isSeedService = appStore.currentService === 'seeds';
            const paramConfig = await this.cropProtectionStore.loadParameterConfiguration(
                data.crop,
                isSeedService,
            );

            // Create copy of inputs object.
            const paramInput = { ...data };

            // Remove the fields we don't need for the param config.
            const customParameters = this.readCustomParametersFromUrl().map((v) => v.split('=')[0]);
            const ignoreFields = ['crop', 'growthStage', 'targets', ...customParameters];

            // Make sure we exclude the additional parameters and characteristics
            // from further evaluation. They will be applied directly.
            const additionalFilterParameters = paramConfig.additionalFilterParameters;
            const characteristics = paramConfig.recommendationCharacteristics;

            if (additionalFilterParameters) {
                ignoreFields.push(...additionalFilterParameters.map((p) => p.externalIdentifier));

            if (characteristics) {
                ignoreFields.push(...characteristics.map((p) => p.externalIdentifier));
            }

                additionalFilterParameters
                    .filter((p) => paramInput.hasOwnProperty(p.externalIdentifier))
                    .forEach((p) => {
                        this.configurationFormStore.setAdditionalInputValueByParameterIdentifier(
                            p.apiExternalIdentifier,
                            {
                                value: this.transformInputParameterValue(
                                    paramInput[p.externalIdentifier],
                                ),
                                parameter: p,
                            },
                        );
                    });
            }

            if (characteristics) {
                ignoreFields.push(...characteristics.map((p) => p.externalIdentifier));

            if (isSeedService && characteristics) {
                    characteristics
                        .filter((c) => paramInput.hasOwnProperty(c.externalIdentifier))
                        .forEach((c) => {
                            this.configurationFormStore.setCharacteristicByIdentifier(
                                c.externalIdentifier,
                                paramInput[c.externalIdentifier] == 'true',
                            );
                        });
                }
            }

            // Remove unwanted fields.
            ignoreFields.forEach((f) => {
                delete paramInput[f];
            });

            if (
                Object.keys(paramInput).length === 0 &&
                Array.isArray(paramConfig?.recommendationFlowParameters) &&
                paramConfig?.recommendationFlowParameters.length > 0
            ) {
                // There are no parameters given.
                return false;
            }

            let isConfigurationValid = true;

            const activeParameters = this.collectParameterTreeFields(
                paramConfig.recommendationFlowParameters,
                paramInput,
            );

            const fallbackParameters =
                this.configurationFormStore.collectActiveFieldsInParameterConfiguration(true);

            // Set all parameter values.
            for (const paramKey of Object.keys(paramInput)) {
                let currentValue = paramInput[paramKey];
                let parameter = activeParameters.find((p) => p.externalIdentifier === paramKey);

                if (!parameter) {
                    parameter = fallbackParameters.find((p) => p.externalIdentifier === paramKey);
                }

                if (parameter) {
                    currentValue = this.transformInputParameterValue(currentValue);

                    // Check if the value is valid. If not, we don't redirect to the recommendation page.
                    if (
                        (!parameter.orderedValues.includes('_blank_') ||
                            parameter.orderedValues.required) &&
                        currentValue.length === 0
                    ) {
                        isConfigurationValid = false;
                    }
                    if (
                        !parameter.orderedValues.includes(currentValue.toString()) &&
                        !currentValue.every((v) => parameter.orderedValues.includes(v)) &&
                        !currentValue.includes(false)
                    ) {
                        isConfigurationValid = false;
                    }

                    this.appliedData['parameters'].push({
                        parameter: parameter.id,
                        identifier: parameter.apiExternalIdentifier,
                        value: currentValue,
                    });

                    this.configurationFormStore.setValueByParameterIdentifier(
                        parameter.apiExternalIdentifier,
                        {
                            value: currentValue,
                            parameter: parameter,
                        },
                    );
                }
            }

            const isValid = this.configurationFormStore.validateParameterConfiguration();

            return isConfigurationValid && isValid;
        },
        collectParameterTreeFields(parameter, paramInput, appliedParameters = []) {
            let currentValue = paramInput[parameter?.externalIdentifier];

            // No value given, we can abort, the end is reached.
            if (!currentValue) {
                if (parameter && !parameter?.orderedValues?.includes('_blank_')) {
                    appliedParameters.push(parameter);
                }
                return appliedParameters;
            }

            // At this point the parameter is correct and can be applied.
            appliedParameters.push(parameter);

            // Check if there is a next parameter to continue with.
            currentValue = this.transformInputParameterValue(currentValue);

            const nextParameters = checkNextParameters(parameter, {
                value: currentValue,
            });

            if (nextParameters?.length) {
                nextParameters.map((p) =>
                    this.collectParameterTreeFields(p, paramInput, appliedParameters),
                );
            }

            return appliedParameters;
        },
        transformInputParameterValue(value) {
            if (!value) return [];

            if (value.includes(VALUE_SEPARATOR)) {
                value = value.split(VALUE_SEPARATOR);
            }

            // Cast to bool if input is a bool.
            if (value === 'true' || value === 'false') {
                value = value === 'true';
            }

            // If currentValue is no Array, make it an Array.
            if (!(value instanceof Array)) {
                value = [value];
            }

            return value;
        },

        async applyGrowthStage(growthStage) {
            const loaded = await this.cropProtectionStore.loadGrowthStages();

            if (!growthStage || !loaded) {
                // No growth-stage given.
                return false;
            }

            const foundGrowthStage = this.cropProtectionStore.getGrowthStageByCode(growthStage);

            if (foundGrowthStage) {
                this.appliedData['growthStage'] = foundGrowthStage;

                await this.configurationFormStore.setSelectedGrowthStage(foundGrowthStage);
                return true;
            }
            return false;
        },
        async applyTargets(targetEppoCodes) {
            await this.cropProtectionStore.loadTargets();

            if (!targetEppoCodes?.length) {
                // No targets given.
                return false;
            }

            const availableTargets = this.cropProtectionStore.targets;
            const requestedTargetEppoCodes = targetEppoCodes?.split(VALUE_SEPARATOR) ?? [];

            const validTargets = availableTargets.filter((t) =>
                requestedTargetEppoCodes.find((eppo) => eppo === t.eppoCode),
            );

            if (validTargets?.length) {
                this.appliedData['targets'] = validTargets;

                await this.configurationFormStore.setSelectedTargets(validTargets);

                return true;
            }
            return false;
        },

        // Get full URL from short-link hash, redirect optionally.
        async resolveShortLink(hash, shouldRedirect = true) {
            const route = apiRoute('shortLink', hash);

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'deeplink:resolve-short-link',
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(err, 'deeplink:resolve-short-link', true, 'deeplink:resolveShortLink');
                return false;
            }

            if (result && result.data) {
                if (shouldRedirect) {
                    window.location.href = result.data.targetUrl;
                }

                return result.data.targetUrl;
            }
        },
        // Create short-link with given or current URL.
        async createShortLink(url = null) {
            const body = {
                targetUrl: url || this.fullUrl,
            };
            const route = apiRoute('shortLink');

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'deeplink:create-short-link',
                async () => to(aceApiClient.post(route, body)),
            );

            if (err) {
                captureError(err, 'deeplink:create-short-link', true, 'deeplink:createShortLink');
                return null;
            }

            if (result && result.data) {
                const baseUrl = window.location.protocol + '//' + window.location.host;

                return {
                    hash: result.data.hash,
                    fullUrl: `${baseUrl}/s/${result.data.hash}`,
                };
            }
        },
    },
});
