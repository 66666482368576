<template>
    <div class="horizontal-list">
        <ul class="horizontal-list__content">
            <slot />
        </ul>
    </div>
</template>

<script>
export default {
    name: 'HorizontalList',
};
</script>

<style>
.horizontal-list ul {
    display: flex;
    flex-flow: row nowrap;
}

.horizontal-list ul li {
    margin-right: 20px;
}
</style>
