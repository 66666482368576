<template>
    <div class="seeds">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'Seeds',
};
</script>
