<template>
    <div class="hero-area-parameters-slider">
        <Slider
            :items="items"
            :slidesPerView="'auto'"
            :slidesPerViewM="'auto'"
            :slidesPerViewL="'auto'"
            :spacing="0"
            :spacingM="0"
            :spacingL="0"
            :initialSlide="0"
            :centeredSlides="false"
            controls
        >
            <template #item="{ item }">
                <div class="hero-area-parameters-slider__item">
                    <span v-if="item.label">{{ item.label }}</span>
                    <strong>{{ item.value }}</strong>
                </div>
            </template>
        </Slider>
    </div>
</template>

<script>
import Slider from '@/components/molecules/Slider.vue';
import GrowthStageSelectorItem from '@/components/atoms/GrowthStageSelectorItem.vue';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import { mapState } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';
import { useCropProtectionStore } from '@/stores/crop-protection';

export default {
    name: 'HeroAreaParametersSlider',
    components: { GrowthStageSelectorItem, Slider },
    computed: {
        ...mapState(useConfigurationFormStore, [
            'selectedCrop',
            'selectedGrowthStage',
            'enteredTreeValues',
            'collectActiveFieldsInParameterConfiguration',
        ]),
        ...mapState(useCropProtectionStore, ['parameterConfiguration']),
        ...mapState(useLocalizationStore, ['getParameterValueTranslation']),

        items() {
            const growthStage = this.selectedGrowthStage;
            const crop = this.selectedCrop;
            const parameterOrder = this.parameterConfiguration?.recommendationFlowParameterOrder;
            const activeTreeParameterIds = this.collectActiveFieldsInParameterConfiguration(
                false,
            )?.map((p) => p.apiExternalIdentifier);

            const parameters = Object.keys(this.enteredTreeValues)
                .filter((key) => activeTreeParameterIds.includes(key))
                .map((key) => {
                    const isBoolAndTrue =
                        this.enteredTreeValues[key].value?.includes(true) ||
                        this.enteredTreeValues[key].value?.includes('true');
                    const translation = this.$t(
                        `parameters.${this.enteredTreeValues[key].parameter.id}.name_translations`,
                    );
                    const valueTranslations = this.enteredTreeValues[key].value
                        ?.map((t) => this.getParameterValueTranslation(key, t))
                        .join(', ');

                    return {
                        label: `${isBoolAndTrue ? '' : translation}${isBoolAndTrue ? '' : ':'}`,
                        order: parameterOrder.indexOf(this.enteredTreeValues[key].parameter.id),
                        value: isBoolAndTrue
                            ? this.$t(
                                  `parameters.${this.enteredTreeValues[key].parameter.id}.name_translations`,
                              )
                            : valueTranslations,
                    };
                })
                .filter((i) => i.value && i.value !== 'false');

            parameters.sort((a, b) => a.order - b.order);

            return [
                {
                    label: `${this.$t('bottomBar.crop')}:`,
                    value: this.$t(`crops.${crop[this.$translationMappingKey('crops')]}.name`),
                },
                ...(growthStage
                    ? [
                          {
                              label: `${this.$t('recommendations.growthStage')}:`,
                              value: `${growthStage?.growthStageScaleName} ${growthStage?.code}`,
                          },
                      ]
                    : []),
                ...parameters,
            ];
        },
    },
};
</script>
