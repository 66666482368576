<template>
    <div class="recommendation-application">
        <Accordion :enableInteraction="enableAccordion" :initiallyOpen="sequenceNo === 1">
            <template #head>
                <div class="recommendation-application__header">
                    <span
                        v-if="sequenceNo !== null && sequenceNo > 0"
                        class="recommendation-application__header-no"
                    >
                        {{ sequenceNo }}.
                    </span>
                    <span class="recommendation-application__header-title">
                        {{ $t('recommendations.application') }}
                        <em>({{ growthStageUnit }})</em>
                    </span>
                    <span
                        v-if="selectedCountryMeta.showGrowthStageCode"
                        class="recommendation-application__header-min-max"
                    >
                        {{ growthStageMinText }} -
                        {{ growthStageMaxText }}
                    </span>
                </div>
            </template>
            <template #content>
                <div
                    v-if="
                        selectedCountryMeta.showGrowthStageDescription &&
                        growthStageMinDescription &&
                        growthStageMaxDescription
                    "
                    class="recommendation-application__range"
                >
                    <span>
                        <strong>{{ $t('recommendations.from') }}:</strong>
                        <span v-html="growthStageMinDescription"></span>
                    </span>
                    <span>
                        <strong>{{ $t('recommendations.to') }}:</strong>
                        <span v-html="growthStageMaxDescription"></span>
                    </span>
                </div>
                <ul
                    class="recommendation-application__product-list"
                    :class="{
                        'recommendation-application__product-list--footnote': !!footnotes.length,
                    }"
                >
                    <li v-for="product in sortedProducts">
                        <ProductAmount
                            :product="product"
                            :key="`product-${product.productId}`"
                            :footnoteIndex="
                                productFootnoteMapping.hasOwnProperty(product.productId)
                                    ? productFootnoteMapping[product.productId]
                                    : null
                            "
                        />
                    </li>
                    <li v-if="isWaterDisplayable(aerialWaterData)">
                        <!--Aerial Water-->
                        <ProductAmount
                            :product="aerialWaterData"
                            isWater
                            :key="`product-aerial-water-${application.id}`"
                        />
                    </li>
                    <li v-if="isWaterDisplayable(waterData)">
                        <!--Water-->
                        <ProductAmount
                            :product="waterData"
                            isWater
                            :key="`product-water-${application.id}`"
                        />
                    </li>
                </ul>
                <div v-if="!!footnotes.length" class="recommendation-application__footnotes">
                    <ul>
                        <li v-for="footnote in footnotes">
                            ®{{ footnote.index }} - {{ footnote.name }}
                        </li>
                    </ul>
                </div>
            </template>
        </Accordion>
    </div>
</template>

<script>
import ProductAmount from '@/components/molecules/Recommendation/ProductAmount.vue';
import Accordion from '@/components/atoms/Accordion.vue';
import { mapState } from 'pinia';
import { useCropProtectionStore } from '@/stores/crop-protection';
import { useLocalizationStore } from '@/stores/localization';

export default {
    name: 'RecommendationApplication',
    components: { Accordion, ProductAmount },
    props: {
        application: {
            type: Object,
            required: true,
        },
        sequenceNo: {
            type: Number,
            required: false,
        },
        waterData: {
            type: Object,
            required: true,
        },
        aerialWaterData: {
            type: Object,
            required: true,
        },
        enableAccordion: {
            type: Boolean,
            default: true,
        },
        productFootnoteMapping: {
            type: Object,
            default: true,
        },
        footnotes: {
            type: Array,
            default: true,
        },
    },
    computed: {
        ...mapState(useCropProtectionStore, ['growthStagesCodeIdMapping']),
        ...mapState(useLocalizationStore, ['selectedCountryMeta']),

        sortedProducts() {
            const products = [...this.application.adviceProducts];

            products.sort((a, b) => a.order - b.order);

            return products;
        },
        growthStageUnit() {
            return this.application.growthStageScale;
        },
        growthStageMinText() {
            return this.application.growthStageMin;
        },
        growthStageMaxText() {
            return this.application.growthStageMax;
        },
        growthStageMinDescription() {
            const id = this.growthStagesCodeIdMapping?.[this.application.growthStageMin];
            if (!id) {
                return null;
            }

            return this.$te(`growthStages.${id}`) || this.$te(`growthStages.${id}`, 'en_ALL')
                ? this.$t(`growthStages.${id}`)
                : null;
        },
        growthStageMaxDescription() {
            const id = this.growthStagesCodeIdMapping?.[this.application.growthStageMax];
            if (!id) {
                return null;
            }

            return this.$te(`growthStages.${id}`) || this.$te(`growthStages.${id}`, 'en_ALL')
                ? this.$t(`growthStages.${id}`)
                : null;
        },
    },
    methods: {
        isWaterDisplayable(data) {
            if (!data) return false;

            return (
                (data.applicationRateUnitCode &&
                    (data.applicationRate ||
                        (data.applicationRateMin && data.applicationRateMax))) ||
                (data.applicationWaterType === 'freeText' && data.applicationFreeText)
            );
        },
    },
};
</script>
