import {BrowserAgent} from '@newrelic/browser-agent/loaders/browser-agent';

const options = {
  init: {
    distributed_tracing: {
      enabled: true,
    },
    privacy: {
      cookies_enabled: true,
    },
    ajax: {
      deny_list: [import.meta.env.VITE_NEW_RELIC_BEACON],
    },
  },
  info: {
    sa: 1,
    beacon: import.meta.env.VITE_NEW_RELIC_BEACON,
    errorBeacon: import.meta.env.VITE_NEW_RELIC_BEACON,
    licenseKey: import.meta.env.VITE_NEW_RELIC_LICENSE_KEY,
    applicationID: import.meta.env.VITE_NEW_RELIC_AGENT_ID,
  },
  loader_config: {
    accountID: import.meta.env.VITE_NEW_RELIC_ACCOUNT_ID,
    trustKey: import.meta.env.VITE_NEW_RELIC_TRUST_KEY,
    agentID: import.meta.env.VITE_NEW_RELIC_AGENT_ID,
    licenseKey: import.meta.env.VITE_NEW_RELIC_LICENSE_KEY,
    applicationID: import.meta.env.VITE_NEW_RELIC_APPLICATION_ID,
  },
};

export const newRelicAgent = new BrowserAgent(options);
