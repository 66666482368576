<template>
    <div class="no-service">
        <p v-html="selectedCountryMeta?.maintenanceText"></p>

        <Btn
            v-if="selectedCountryMeta?.maintenanceLink"
            :href="selectedCountryMeta?.maintenanceLink"
            target="_blank"
        >
            {{ selectedCountryMeta?.maintenanceLink }}
        </Btn>
    </div>
</template>

<script>
import { track } from '@/utils/utag';
import TrackingTypes from '@/utils/constants/trackingTypes';
import { mapState } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';
import Btn from '@/components/atoms/Btn.vue';
import { useConfirmationGateStore } from '@/stores/confirmation-gate';

export default {
    name: 'NoService',
    components: {
        Btn,
    },
    mounted() {
        // Redirect to service-select if user confirmed confirmation gate.
        // TODO :: Has to be removed once we use this as a general maintenance page.
        if (
            this.$route.name === 'no-service' &&
            this.confirmedCountries?.hasOwnProperty(this.selectedCountry) &&
            this.confirmedCountries[this.selectedCountry]
        ) {
            this.$router.push({
                name: 'service-select',
                params: this.$route.params,
                query: this.$route.query,
            });
            return;
        }

        track({
            page_name: 'No service',
            page_category: TrackingTypes.Category.General,
            page_subcategory: '',
            page_country: '',
            page_language: 'en',
            page_type: TrackingTypes.Type.UpstreamPage,
            page_privacy_policy: false,
        });
    },
    computed: {
        ...mapState(useLocalizationStore, ['selectedCountryMeta', 'selectedCountry']),
        ...mapState(useConfirmationGateStore, ['confirmedCountries']),
    },
};
</script>
