<template>
    <div v-if="!$loading.isLoading('localization:set-selected-country')" class="country-select">
        <Dropdown
            :items="filteredCountries"
            filterField="name"
            :placeholder="$t('start.selectCountry')"
            icon="fa-magnifying-glass"
            ref="dropdown"
            @update:modelValue="onSelectedCountryChange"
        >
            <template #item="{ item }">
                {{ item.name }}
            </template>
            <template #selectedItem="{ selectedItem }">
                {{ selectedItem[0].name }}
            </template>
        </Dropdown>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';
import Dropdown from '@/components/atoms/Dropdown.vue';
import { track } from '@/utils/utag';
import TrackingTypes from '@/utils/constants/trackingTypes';

export default {
    name: 'CountrySelect',
    components: { Dropdown },
    computed: {
        ...mapState(useLocalizationStore, ['countries', 'selectedCountry']),

        filteredCountries() {
            const countries = this.countries.filter(
                (c) => c.hasCropProtection || c.hasSeedProtection,
            );

            countries.sort((a, b) => a.name.localeCompare(b.name));

            return countries;
        },
    },
    methods: {
        ...mapActions(useLocalizationStore, ['setSelectedCountry']),

        async onSelectedCountryChange(value) {
            // Set new country.
            const isCountrySetSuccessfully = await this.setSelectedCountry(value[0]?.contraction);

            if (isCountrySetSuccessfully) {
                // Navigate to main-application.
                this.$router.push({
                    name: 'service-select',
                    params: { country: value[0].contraction },
                    query: this.$route.query,
                });
            } else {
                this.$refs.dropdown.onClearClick();
            }
        },
    },
    mounted() {
        track({
            page_name: 'Country select',
            page_category: TrackingTypes.Category.General,
            page_subcategory: '',
            page_country: '',
            page_language: 'en',
            page_type: TrackingTypes.Type.UpstreamPage,
            page_privacy_policy: false,
        });
    },
};
</script>
