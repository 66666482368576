<template>
    <div class="button-dropdown">
        <DropdownMenu :overlay="false" :closeOnClickOutside="true" withDropdownCloser>
            <template #trigger>
                <div v-if="icon" class="btn__content-icon fas" :class="iconClasses"></div>
                <span class="v-dropdown-menu__label">{{ label }}</span>
                <div class="v-dropdown-menu__icon-btn" dropdown-closer />
            </template>

            <template #body>
                <ul>
                    <li
                        v-for="(item, i) in items"
                        :key="i"
                        dropdown-closer
                        @click="() => onItemClick(item)"
                    >
                        <slot name="item" v-bind:item="item"></slot>
                    </li>
                </ul>
            </template>
        </DropdownMenu>
    </div>
</template>

<script>
import DropdownMenu from 'v-dropdown-menu';

export default {
    name: 'ButtonDropdown',
    components: {
        DropdownMenu,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isOpened: false,
        };
    },
    computed: {
        iconClasses() {
            return {
                [this.icon]: true,
            };
        },
    },
    methods: {
        onItemClick(item) {
            item.action();
        },
    },
};
</script>
