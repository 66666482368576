<template>
    <main
        :lang="selectedLanguage?.language"
        role="main"
        class="container background-section content-wrapper"
        :class="classes"
    >
        <div class="background-section__content">
            <HeroArea />
            <LoadingOverlay v-if="isInLoadingState" />

            <div class="container container--narrow">
                <RouterView v-if="!isInLoadingState" />

                <NotificationContainer />
            </div>
        </div>

        <div class="container container--narrow">
            <Sidebar />
        </div>

        <BottomBar />
    </main>
</template>
<script>
import { mapActions, mapState } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';
import { useAuthStore } from '@/stores/auth';
import { useAppStore } from '@/stores/app';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import NotificationContainer from '@/components/molecules/NotificationContainer.vue';
import Sidebar from '@/components/molecules/Sidebar/Sidebar.vue';
import { useSidebarStore } from '@/stores/sidebar';
import HeroArea from '@/components/molecules/HeroArea.vue';
import BottomBar from '@/components/molecules/BottomBar.vue';
import { isInIframe } from '@/utils/helpers';
import LoadingOverlay from '@/components/atoms/LoadingOverlay.vue';

export default {
    name: 'Home',
    components: { LoadingOverlay, BottomBar, HeroArea, Sidebar, NotificationContainer },
    data() {
        return {
            appInitialized: false,
        };
    },
    created() {
        if (isInIframe()) {
            document.body.classList.add('in-iframe');

            this.$nextTick(() => {
                // Scrolling within an iframe will not work without this.
                // Using it outside an iframe can trigger double scrollbar (BACE-2984)..
                document.querySelector('#app').style.overflowY = 'scroll';
            });
        }

        this.loadInitialData();
    },
    computed: {
        ...mapState(useLocalizationStore, ['countries', 'selectedLanguage']),
        ...mapState(useConfigurationFormStore, ['isBottomBarActive']),
        ...mapState(useSidebarStore, { sidebarVisible: (state) => state.show }),

        classes() {
            return {
                'content-wrapper--sidebar-open': this.sidebarVisible,
                'content-wrapper--bottom-bar-visible': this.isBottomBarActive,
                'content-wrapper--is-initializing': !this.appInitialized,
            };
        },
        isInLoadingState() {
            return (
                !this.appInitialized ||
                this.$loading.isLoading('localization:set-selected-country') ||
                this.$loading.isLoading('teaser:load-teasers')
            );
        },
    },
    methods: {
        ...mapActions(useLocalizationStore, ['loadCountries', 'getInterfaceTranslations', 'getEntityTranslations']),
        ...mapActions(useAuthStore, ['getToken']),
        ...mapActions(useAppStore, ['getInitialData']),

        async loadInitialData() {
            await this.getToken();

            await this.getInterfaceTranslations(null);
            await this.getEntityTranslations(null);
            await this.getInitialData();
            await this.loadCountries();

            this.appInitialized = true;
        },
    },
};
</script>
