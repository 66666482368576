<template>
    <div class="sidebar-seed-rate-calculator">
        <nav class="sidebar-seed-rate-calculator__navigation">
            <ul class="tab-navigation">
                <li
                    v-for="(tab, i) in calculationData"
                    class="tab-navigation__item"
                    :class="{ 'tab-navigation__item--active': selectedTab === i }"
                    @click="() => changeSelectedTab(i)"
                >
                    <span class="tab-navigation__item-link">
                        {{ tab.tabName }}
                    </span>
                </li>
            </ul>
        </nav>
        <h3 class="sidebar-seed-rate-calculator__title" v-sub-tag-wrapper>{{ title }}</h3>
        <div class="sidebar-seed-rate-calculator__sections">
            <div>
                <SeedCalculatorInput
                    identifier="growingArea"
                    :dataObject="farmAreaData"
                    :type="farmAreaData?.inputType"
                />

                <SeedCalculatorInput
                    identifier="bagSize"
                    :dataObject="bagSizeData"
                    :type="bagSizeData?.inputType"
                />
            </div>
            <div>
                <SeedCalculatorInput
                    identifier="thousandGrainWeight"
                    :dataObject="thousandGrainWeightData"
                    :type="thousandGrainWeightData?.inputType"
                />

                <SeedCalculatorInput
                    identifier="sowingRate"
                    :dataObject="sowingRateData"
                    :type="sowingRateData?.inputType"
                />

                <SeedCalculatorInput
                    identifier="germinability"
                    :dataObject="germinabilityData"
                    :type="germinabilityData?.inputType"
                />

                <SeedCalculatorInput
                    identifier="rowSpacing"
                    :dataObject="rowSpacingData"
                    :type="rowSpacingData?.inputType"
                />

                <Btn icon="fa-arrow-right" iconPosition="start" @click="onCalculateClick"
                    >{{ $t('recommendations.seedCalculatorCalculate') }}
                </Btn>
            </div>
            <div>
                <SeedCalculatorResult
                    v-if="hasAnyResult"
                    :resultData="result"
                    :resultUnits="resultUnits"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SeedCalculatorResult from '@/components/atoms/SeedCalculatorResult.vue';
import InputField from '@/components/atoms/InputField.vue';
import Btn from '@/components/atoms/Btn.vue';
import {
    calculateNumberOfBags,
    calculateSeedsPerMeter,
    calculateSowingRate,
    numberFieldTypeMap,
    roundCalculatedNumber,
} from '@/utils/seed-rate-calculator-helpers';
import { parseNumber } from '@/utils/helpers';
import { mapState } from 'pinia';
import { useSeedStore } from '@/stores/seed';
import Dropdown from '@/components/atoms/Dropdown.vue';
import SeedCalculatorInput from '@/components/molecules/SeedCalculatorInput.vue';

export default {
    name: 'SidebarSeedRateCalculator',
    components: { SeedCalculatorInput, InputField, SeedCalculatorResult, Btn, Dropdown },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedTab: 0,
            result: {
                result: null,
                requiredAmount: null,
                seedsPerMeter: null,
            },
            resultUnits: {
                resultUnit: '',
                requiredAmountUnit: '',
            },
            equationNumber: -1,

            farmAreaData: {
                value: 0,
                isEditable: false,
                hidden: false,
                unit: '',
            },
            bagSizeData: {
                value: 0,
                isEditable: false,
                hidden: false,
                unit: '',
            },
            thousandGrainWeightData: {
                value: 0,
                isEditable: false,
                hidden: false,
                unit: '',
            },
            germinabilityData: {
                value: 0,
                isEditable: false,
                hidden: false,
                unit: '',
            },
            sowingRateData: {
                value: 0,
                isEditable: false,
                hidden: false,
                unit: '',
            },
            rowSpacingData: {
                value: 0,
                isEditable: false,
                hidden: false,
                unit: '',
            },
        };
    },
    mounted() {
        this.changeSelectedTab(0);
    },
    computed: {
        ...mapState(useSeedStore, ['seedRateCalculationData']),

        title() {
            return this.$t(`products.${this.product?.productId}.name`);
        },
        product() {
            return this.content.advice?.adviceProducts[0];
        },
        calculationData() {
            const calculations = [...this.content.calculationData?.seedRateCalculations];

            calculations.sort((a, b) => a.order - b.order);

            return calculations;
        },
        hasAnyResult() {
            return Object.keys(this.result).some(
                (k) => this.result[k] !== null && this.result[k] !== -1,
            );
        },
    },
    methods: {
        applyCalculationConfig() {
            const config = { ...this.calculationData[this.selectedTab] };

            // Data for results.
            this.equationNumber = config.calculation.equationNumber;
            this.resultUnits = config.calculation;

            // Parse numbers to float or int depending on mapping.
            Object.keys(config).forEach((field) => {
                if (
                    field !== 'equationNumber' &&
                    field !== 'calculation' &&
                    field !== 'inputType' &&
                    (config[field]?.hasOwnProperty('value') ||
                        config[field]?.hasOwnProperty('options'))
                ) {
                    const expectedType = numberFieldTypeMap[field];

                    if (expectedType) {
                        if (
                            Array.isArray(config[field]?.options) &&
                            config[field]?.options?.length
                        ) {
                            config[field].options = config[field].options.map((f) =>
                                parseNumber(f, expectedType),
                            );
                        } else {
                            config[field].value = parseNumber(config[field].value, expectedType);
                        }
                    }
                }
            });

            // Input data.
            this.farmAreaData = { ...config.farmArea };
            this.sowingRateData = { ...config.sowingRate };
            this.thousandGrainWeightData = { ...config.thousandGrainWeight };
            this.germinabilityData = { ...config.germinability };
            this.rowSpacingData = { ...config.rowSpacing };
            this.bagSizeData = { ...config.bagSize };
        },
        onCalculateClick() {
            const inputData = {
                farmArea: this.farmAreaData?.value ?? 0,
                sowingRate: this.sowingRateData?.value ?? 0,
                thousandGrainWeight: this.thousandGrainWeightData?.value ?? 0,
                germinability: this.germinabilityData?.value ?? 0,
                rowSpacing: this.rowSpacingData?.value ?? 0,
                bagSize: this.bagSizeData?.value ?? 0,
            };

            // Make sure all inputs are numbers.
            Object.keys(inputData).forEach((key) => {
                if (Array.isArray(inputData[key])) {
                    inputData[key] = inputData[key][0];
                }
                if (typeof inputData[key] === 'string') {
                    inputData[key] = parseNumber(inputData[key], numberFieldTypeMap[key]);
                }
            });

            const sowingRate = roundCalculatedNumber(
                calculateSowingRate(this.equationNumber, inputData),
            );
            const numberOfBags = Math.ceil(calculateNumberOfBags(this.equationNumber, inputData));
            const seedsPerMeter = roundCalculatedNumber(
                calculateSeedsPerMeter(this.equationNumber, inputData),
            );

            this.result = {
                result: sowingRate,
                requiredAmount: numberOfBags,
                seedsPerMeter: seedsPerMeter,
            };
        },
        changeSelectedTab(tab) {
            this.selectedTab = tab;
            this.result = {
                result: null,
                requiredAmount: null,
                seedsPerMeter: null,
            };

            this.applyCalculationConfig();
        },
    },
};
</script>
