// Equations
export const calculateSowingRate = (equationNumber, inputs) => {
    const { sowingRate, thousandGrainWeight, germinability, bagSize } = inputs;

    switch (equationNumber) {
        case 1:
            return (sowingRate * thousandGrainWeight) / (germinability / 100) / 100;
        case 2:
            return sowingRate / (bagSize * 1000000);
        case 3:
            return sowingRate / (bagSize * 1000000 * (germinability / 100));
        case 4:
            return (sowingRate * (thousandGrainWeight / 1000 / 1000)) / (germinability / 100);
        case 5:
            return (sowingRate * thousandGrainWeight) / (germinability / 100) / 1000000;
        case 7:
            return sowingRate / ((germinability / 100) * bagSize);
        default:
            return null;
    }
};

export const calculateNumberOfBags = (equationNumber, inputs) => {
    const { sowingRate, farmArea, thousandGrainWeight, germinability, bagSize } = inputs;

    switch (equationNumber) {
        case 1:
            return (sowingRate * farmArea * 10000) / (bagSize * 1000000);
        case 2:
            return (sowingRate * farmArea) / (bagSize * 1000000);
        case 3:
            return (sowingRate * farmArea) / (bagSize * 1000000 * (germinability / 100));
        case 4:
            return (
                ((sowingRate * (thousandGrainWeight / 1000 / 1000)) /
                    (germinability / 100) /
                    bagSize) *
                farmArea
            );
        case 5:
            return (sowingRate / (bagSize * 1000000)) * farmArea;
        case 6:
            return sowingRate * farmArea;
        case 7:
            return farmArea * (sowingRate / ((germinability / 100) * bagSize));
        case 8:
            return farmArea * sowingRate * 10000;
        default:
            return null;
    }
};

export const calculateSeedsPerMeter = (equationNumber, inputs) => {
    const { sowingRate, rowSpacing, germinability } = inputs;

    switch (equationNumber) {
        case 3:
        case 4:
        case 5:
            return (rowSpacing * sowingRate) / 10000 / (germinability / 100);
        default:
            return null;
    }
};

// Mapping which field should have which type.
export const numberFieldTypeMap = {
    farmArea: 'int',
    sowingRate: 'float',
    thousandGrainWeight: 'int',
    germinability: 'int',
    rowSpacing: 'float',
    bagSize: 'float',
};

// Rounds to 2 decimals.
export const roundCalculatedNumber = (number) => {
    return Math.round(number * 100) / 100;
};
