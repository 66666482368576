export default {
    consultant: {
        icon: 'fa-comment',
        titleKey: 'recommendations.regionalConsultant',
        isFA: true,
    },
    downloadLeaflet: {
        icon: 'product_benefits',
        titleKey: 'recommendations.productBenefits',
    },
};
