import { instance } from '../i18n';

const getOneTrustCookieSettingsButton = () => `
<button onclick="Optanon.ToggleInfoDisplay();" class="btn btn--default" type="button">
  <div class="btn__content">
    <span>${instance.t('oneTrust.openCookieSettings')}</span>
  </div>
</button>`;

function insertBetween(content, start, newSubStr) {
  return `${content.slice(0, start)}${newSubStr}${content.slice(start)}`;
}

function insertPlaceholderPrivacyPolicy(content) {
  const positionMatch = /<div class="cookie-settings"/.exec(content);
  if (positionMatch) {
    const cookieTable = document.querySelector('#onetrust-cookie-table');
    if (cookieTable) {
      content = insertBetween(content, positionMatch.index, `${cookieTable.innerHTML}${getOneTrustCookieSettingsButton()}`);
    }
  }

  return content;
}

export function insertStaticContentData(content) {
  return [
    insertPlaceholderPrivacyPolicy,
  ].reduce((currentContent, replacer) => replacer(currentContent), content);
}
