import { createI18n } from 'vue-i18n';

const messages = {};

const i18n = createI18n({
    locale: import.meta.env.VITE_DEFAULT_LOCALE,
    fallbackLocale: 'en_ALL',
    legacy: false,
    messages,
});

export default i18n;

export const instance = i18n.global;
