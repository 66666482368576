<template>
    <div class="tree-parameter">
        <ParameterSection
            :title="parameterTranslation"
            :required="isRequired"
            :sidePanelContent="sidePanelContent"
        >
            <template #content>
                <ParameterInputWrapper
                    :savedValue="savedValue"
                    :parameterId="parameterConfiguration.id"
                    :parameterIdentifier="parameterIdentifier"
                    :possibleOptions="possibleOptions"
                    :canBeEmpty="canBeEmpty"
                    :inputType="inputType"
                    :onInput="onInput"
                    hasToggleBackground
                />
            </template>

            <!-- If there is a next parameter, recursively render it -->
            <template #nextParameter>
                <TreeParameter
                    v-if="parameterConfigurationForNextParameter"
                    :tree="tree"
                    :index="index + 1"
                    ref="childrenTree"
                    @treeChange="emitInput"
                />
            </template>
        </ParameterSection>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import ParameterInputTypes from '@/utils/constants/parameter-input-types';
import OptionSelectList from '@/components/molecules/OptionSelectList.vue';
import Toggle from '@/components/atoms/Toggle.vue';
import InputField from '@/components/atoms/InputField.vue';
import OptionCard from '@/components/atoms/OptionCard.vue';
import SelectButton from '@/components/atoms/SelectButton.vue';
import Autocomplete from '@/components/molecules/Autocomplete.vue';
import ParameterSection from '@/components/molecules/ParameterSection.vue';
import Dropdown from '@/components/atoms/Dropdown.vue';
import ParameterInputWrapper from '@/components/molecules/ParameterInputWrapper.vue';
import LocationAutocomplete from '@/components/atoms/LocationAutocomplete.vue';
import { useLocalizationStore } from '@/stores/localization';

export default {
    name: 'TreeParameter',
    components: {
        ParameterInputWrapper,
        LocationAutocomplete,
        Dropdown,
        ParameterSection,
        SelectButton,
        OptionCard,
        InputField,
        Toggle,
        OptionSelectList,
        Autocomplete,
    },
    props: {
        tree: {
            type: Array,
            required: true,
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    created() {
        setTimeout(() => {
            const newValues = [];

            // Clear selected options for this parameter that don't exist (anymore).
            for (let i = 0; i < this.savedValue.length; i++) {
                const val = this.savedValue[i];

                if (this.possibleOptions.includes(val.toString())) {
                    newValues.push(val);
                }
            }

            if (
                newValues.length === 0 &&
                !this.parameterConfiguration.orderedValues.includes('_blank_')
            ) {
                newValues.push(...this.defaultValues.filter((val) => val !== null));
            }

            this.updateFieldInStore(newValues);
            this.$nextTick(this.validateParameterConfiguration);
        }, 0);
    },
    emits: ['treeChange'],
    computed: {
        ...mapState(useConfigurationFormStore, ['getValueByParameterName', 'isParameterRequired']),
        ...mapState(useLocalizationStore, ['selectedCountry', 'selectedCountryMeta']),

        parameterConfiguration() {
            return this.tree[this.index];
        },
        allInputTypes() {
            return ParameterInputTypes;
        },
        inputType() {
            return this.parameterConfiguration.type;
        },
        parameterIdentifier() {
            return this.parameterConfiguration.apiExternalIdentifier;
        },
        parameterTranslation() {
            return this.$t(`parameters.${this.parameterConfiguration.id}.name_translations`);
        },
        possibleOptions() {
            let options =
                this.parameterConfiguration.orderedValues ||
                Object.keys(this.parameterConfiguration.values).map((key) => key);

            if (this.canBeEmpty) {
                // If the selection can be empty, we ensure that the empty value is not rendered.
                options = options.filter((o) => o !== '_blank_');
            }

            return options;
        },
        defaultValues() {
            return this.parameterConfiguration.defaultValues;
        },
        isRequired() {
            return this.isParameterRequired(this.parameterConfiguration);
        },
        translationKey() {
            return `parameters.${this.parameterIdentifier}`;
        },
        parameterConfigurationForNextParameter() {
            return this.tree[this.index + 1];
        },
        canBeEmpty() {
            return (
                !!this.parameterConfiguration.values['_blank_'] ||
                this.parameterConfiguration.orderedValues.find((v) => v === '_blank_') !== undefined
            );
        },
        childTree() {
            if (!this.parameterConfigurationForNextParameter) {
                return null;
            }
            return this.$refs.childrenTree;
        },
        savedValue() {
            const value = this.getValueByParameterName(this.parameterIdentifier);

            if (!value) {
                this.updateFieldInStore(this.defaultValues.filter((val) => val !== null));
            }

            return this.getValueByParameterName(this.parameterIdentifier);
        },
        sidePanelContent() {
            const key = `explanationInfos.parameters_${this.parameterConfiguration.id}.content`;
            return this.$te(key) || this.$te(key, 'en_ALL') ? this.$t(key) : null;
        },
    },
    methods: {
        ...mapActions(useConfigurationFormStore, [
            'setValueByParameterIdentifier',
            'clearStoredFieldsWithExceptions',
            'validateParameterConfiguration',
        ]),

        onInput(value) {
            // Single values should be stored in array to keep it consistent.
            if (!(value instanceof Array)) {
                value = [value];
            }

            this.emitInput(this.parameterIdentifier);
            this.updateFieldInStore(value);
        },
        emitInput(key) {
            this.$emit('treeChange', key);
        },
        resetToInitialState() {
            // Depending on the type of input, we assign the default value(s) differently.
            let val = null;

            switch (this.inputType) {
                case this.allInputTypes.BOOLEAN:
                    val = [this.defaultValues[0] === 'true'];
                    break;
                case this.allInputTypes.SINGLE_SELECT:
                    val = this.defaultValues;
                    break;
                case this.allInputTypes.MULTI_SELECT:
                    val = [...this.defaultValues];
                    break;
            }

            this.updateFieldInStore(val);

            if (this.childTree) {
                this.childTree.resetToInitialState();
            }
        },
        updateFieldInStore(value) {
            value = value.map((v) => v.toString());

            // Set input value in store.
            this.setValueByParameterIdentifier(this.parameterIdentifier, {
                value: value,
                parameter: this.parameterConfiguration,
            });
        },
    },
};
</script>
