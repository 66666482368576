<template>
    <ul class="tab-navigation" ref="list">
        <li
            v-for="(tab, i) in tabs"
            class="tab-navigation__item"
            :class="{ 'tab-navigation__item--active': currentTab === i }"
            :key="`tab-navigation-${prefix}-${tab}`"
        >
            <a
                class="tab-navigation__item-link"
                @click.prevent="scrollToSection(`${prefix}.${tab}`)"
            >
                <slot v-bind:item="tab" />
            </a>
        </li>
    </ul>
</template>

<script>
import { isInIframe } from '@/utils/helpers';

export default {
    name: 'TabNavigation',
    data() {
        return {
            currentTab: 0,
        };
    },
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        prefix: {
            type: String,
            default: 'group-',
        },
        offset: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        setCurrentTab(data) {
            this.currentTab = data.index;
        },
        scrollToSection(key) {
            const element = document.getElementById(key);

            if (element) {
                const rect = element.getBoundingClientRect();

                if (isInIframe()) {
                    // Scrolling in iframe has to be handled differently.
                    const parentEl = window.document.querySelector('#app');
                    const y = rect.top + parentEl.scrollTop - this.offset;
                    parentEl.scrollTo({ top: y, behavior: 'smooth' });
                } else {
                    const y = rect.top + window.scrollY - this.offset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            }
        },
    },
};
</script>
