<template>
    <section class="form-section">
        <div class="form-section__btn-row">
            <Btn
                v-if="parameterConfiguration?.additionalFilterParameters?.length"
                icon="fa-bars-filter"
                iconPosition="start"
                type="green"
                @click.prevent="onSpecifyGrowingConditionsClick"
            >
                {{ $t('bottomBar.growingConditions') }}
            </Btn>
            <Btn
                v-if="!forSeeds"
                :badge="activeTargets.length || null"
                icon="fa-bars-filter"
                iconPosition="start"
                type="white"
                @click.prevent="onSpecifyTargetsClick"
            >
                {{ $t('bottomBar.selectedTargets') }}
            </Btn>

            <ShareButton v-if="hasRecommendations" />

            <Btn
                v-if="selectedCountryMeta?.recommendationEcommerceLink"
                :href="selectedCountryMeta?.recommendationEcommerceLink"
                type="green"
                icon="fa-cart-shopping"
                iconPosition="start"
                target="_blank"
                >{{ $t('recommendations.whereToBuy') }}</Btn
            >

            <template v-if="forSeeds && recommendations.advices?.length > 1">
                <Btn
                    v-if="!isInCompareMode"
                    icon="fa-arrow-right-arrow-left"
                    iconPosition="start"
                    type="green"
                    @click.prevent="onCompareStartClick"
                >
                    {{ $t('recommendations.compareTankmixes') }}
                </Btn>
                <Btn
                    v-else-if="isInCompareMode"
                    icon="fa-arrow-right"
                    iconPosition="start"
                    type="green"
                    :disabled="tankmixesForComparison.length < 2"
                    @click.prevent="onCompareClick"
                >
                    {{ $t('recommendations.compareTankmixes') }}
                </Btn>
            </template>
        </div>

        <CompareOverlay v-if="isInCompareMode" />

        <template v-if="!$loading.isLoading('crop-protection:load-recommendations')">
            <RecommendationCardList
                v-if="recommendations.advices && recommendations.advices.length"
                :advices="sortedAdvices"
                :showSeedCards="forSeeds"
            />
            <h2 v-else>{{ $t('recommendations.noResults') }}</h2>

            <RestartButtons />

            <ServiceTeaserList />
            <PromoTeaserList />
        </template>
    </section>
</template>

<script>
import RecommendationCardList from '@/components/molecules/Recommendation/RecommendationCardList.vue';
import ServiceTeaserList from '@/components/molecules/ServiceTeaserList.vue';
import Btn from '@/components/atoms/Btn.vue';
import PromoTeaserList from '@/components/molecules/Recommendation/PromoTeaserList.vue';
import ShareButton from '@/components/molecules/ShareButton.vue';
import { mapActions, mapState } from 'pinia';
import { useCropProtectionStore } from '@/stores/crop-protection';
import SidebarContentTypes from '@/utils/constants/sidebar-content-types';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import { useSeedStore } from '@/stores/seed';
import CompareOverlay from '@/components/molecules/CompareOverlay.vue';
import RestartButtons from '@/components/molecules/Recommendation/RestartButtons.vue';
import { useLocalizationStore } from '@/stores/localization';

export default {
    name: 'Recommendation',
    components: {
        RestartButtons,
        CompareOverlay,
        ShareButton,
        PromoTeaserList,
        Btn,
        ServiceTeaserList,
        RecommendationCardList,
    },
    props: {
        recommendations: {
            type: Object,
            required: true,
        },
        forSeeds: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(useCropProtectionStore, ['parameterConfiguration', 'getAdviceOverallEffect']),
        ...mapState(useConfigurationFormStore, ['activeTargets']),
        ...mapState(useSeedStore, ['isInCompareMode', 'tankmixesForComparison']),
        ...mapState(useLocalizationStore, ['selectedCountryMeta']),

        hasRecommendations() {
            return this.recommendations.advices?.length;
        },
        sortedAdvices() {
            // Sort advices by FG and overall effect.
            const advices = [...this.recommendations.advices];

            advices.sort((a, b) => {
                const groupComparison = a.functionalGroupCode.localeCompare(b.functionalGroupCode);

                if (groupComparison !== 0) {
                    return groupComparison;
                } else {
                    return this.getAdviceOverallEffect(b) - this.getAdviceOverallEffect(a);
                }
            });

            return advices;
        },
    },
    methods: {
        ...mapActions(useSeedStore, ['toggleCompareMode']),

        onSpecifyGrowingConditionsClick() {
            this.$openSidebar(
                SidebarContentTypes.GROWING_CONDITIONS,
                this.$t('sidePanel.specifyGrowingConditions'),
            );
        },
        onSpecifyTargetsClick() {
            this.$openSidebar(
                SidebarContentTypes.SELECTED_TARGETS,
                this.$t('sidePanel.specifySelectedTargets'),
            );
        },
        onCompareStartClick() {
            this.toggleCompareMode(true);
        },
        onCompareClick() {
            this.$openSidebar(
                SidebarContentTypes.COMPARE_TANKMIX,
                this.$t('recommendations.compareTankmixesTitle'),
                {},
                true,
            );
        },
    },
};
</script>
