import { defineStore } from 'pinia';
import to from 'await-to-js';
import apiRoute from '@/utils/route-helper';
import captureError from '@/utils/capture-error';
import { aceApiClient } from '@/utils/axios';
import { useLocalizationStore } from '@/stores/localization';
import translationArrayHelper from '@/utils/translation-array-helper';
import { useRoute } from 'vue-router'

export const useTeaserStore = defineStore('teaser', {
    state: () => ({
        serviceTeasers: [],
        promotionTeasers: [],

        localizationStore: useLocalizationStore(),
    }),
    actions: {
        async loadTeasers(type = 'service') {
            const options = {
                positionGroup: type,
                countryCode: this.localizationStore.selectedCountry,
            };
            const route = apiRoute('teaser', null, options);

            const [err, result] = await this.$loading.startLoadingWithCallback(
                `teaser:load-${type}-teasers`,
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(err, '`teaser:load-${type}-teasers`,', true, 'teaser:loadTeasers');
                return;
            }

            return result.data;
        },
        async loadServiceTeasers() {
            const serviceTeasers = await this.loadTeasers();

            if (serviceTeasers) {
                this.serviceTeasers = serviceTeasers;
            }
        },
        async loadPromotionTeasers(type) {
            if (!type) return;

            const promotionTeasers = await this.loadTeasers(type);

            if (promotionTeasers) {
                this.promotionTeasers = promotionTeasers;
                this.promotionTeasers.sort((a, b) => a.positionOrder - b.positionOrder);
            }
        },
    },
    getters: {
        availableServices: (state) => {
            const hasCropProtection = state.localizationStore.selectedCountryMeta.hasCropProtection;
            const hasSeedProtection = state.localizationStore.selectedCountryMeta.hasSeedProtection;
            const teasers = state.serviceTeasers;

            // Fallback teasers.
            if (hasCropProtection && !teasers.filter((t) => t.target === 'crop')?.length) {
                teasers.push({
                    title: state.$i18n.t('start.cropProtectionTeaserTitle'),
                    button: state.$i18n.t('start.cropProtectionTeaserButton'),
                    target: 'crop',
                });
            }

            if (hasSeedProtection && !teasers.filter((t) => t.target === 'seed')?.length) {
                teasers.push({
                    title: state.$i18n.t('start.seedsTeaserTitle'),
                    button: state.$i18n.t('start.seedsTeaserButton'),
                    target: 'seed',
                });
            }

            return teasers;
        },
        availableServicesOrdered: (state) => {
            const currentRouteName = state.currentRoute?.name;

            const servicesWithInternalLinks = state.availableServices.map((s) => ({
                ...s,
                internalLink: state.getServiceLink(s)?.name ?? null,
            }));

            servicesWithInternalLinks.sort((a, b) => {
                if (a.internalLink === currentRouteName) return 1;
                if (b.internalLink === currentRouteName) return -1;

                return a.positionOrder - b.positionOrder;
            });

            return servicesWithInternalLinks;
        },
        getServiceTypeLabel: (state) => (service) => {
            if (service.target === 'crop') {
                return state.$i18n.t('burgerMenu.cropProtection');
            } else if (service.target === 'seed') {
                return state.$i18n.t('burgerMenu.seeds');
            } else {
                return '';
            }
        },
        getServiceLink: (state) => (service) => {
            const route = useRoute();
            if (service.target === 'crop') {
                return {
                    name: 'crop-protection.form',
                    params: { country: state.localizationStore.selectedCountry },
                    query: { ...route?.query ?? {} },
                };
            } else if (service.target === 'seed') {
                return {
                    name: 'seeds.form',
                    params: { country: state.localizationStore.selectedCountry },
                    query: { ...route?.query ?? {} },
                };
            } else {
                return state.getTeaserTranslation(service.linkUrl);
            }
        },
        getTeaserTranslation: (state) => (item) => {
            if (!item) return '';
            if (typeof item === 'string') {
                return item;
            }

            return translationArrayHelper(item, state.$i18n.locale.slice(0, 2));
        },
        getTeaserIcon: (state) => (service) => {
            let icon = null;

            if (service.target === 'crop') {
                icon = 'crop';
            } else if (service.target === 'seed') {
                icon = 'seeds';
            }

            return new URL(`../assets/images/icons/services/${icon}.svg`, import.meta.url).href;
        },
    },
});
