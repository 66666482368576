<template>
    <div class="sidebar-targets">
        <div class="sidebar-targets__groups">
            <h4>{{ $t('bottomBar.selectedTargets') }}</h4>

            <div v-for="(group, code) in groupedTargets" class="sidebar-targets__group-item">
                <h5>{{ $t(`functionalGroups.${code}.name`) }}</h5>
                <ul>
                    <li v-for="t in group">
                        <Toggle
                            :modelValue="t.enabled"
                            :label="
                                $t(`targets.${t.target[$translationMappingKey('targets')]}.name`)
                            "
                            :subtitle="t.target.scientificName"
                            @update:modelValue="(val) => (t.enabled = val)"
                        />
                    </li>
                </ul>
            </div>
        </div>
        <div class="sidebar-targets__btn">
            <Btn @click.prevent="onApply">{{ $t('sidePanel.specifyGrowingConditions') }}</Btn>
        </div>
    </div>
</template>

<script>
import SelectedTargetsList from '@/components/molecules/SelectedTargetsList.vue';
import { mapActions, mapState } from 'pinia';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import { groupBy } from '@/utils/helpers';
import Toggle from '@/components/atoms/Toggle.vue';
import Btn from '@/components/atoms/Btn.vue';
import { useCropProtectionStore } from '@/stores/crop-protection';

export default {
    name: 'SidebarTargets',
    components: { Btn, Toggle, SelectedTargetsList },
    props: {
        content: {},
    },
    data() {
        return {
            targetStates: [],
        };
    },
    mounted() {
        // Store selected targets in separate array to be able to reset them later.
        this.targetStates = this.selectedTargets.map((target) => ({
            target: target,
            enabled: !this.isTargetDisabled(target.id),
        }));
    },
    computed: {
        ...mapState(useConfigurationFormStore, [
            'selectedTargets',
            'isTargetDisabled',
            'userDisabledTargets',
        ]),

        groupedTargets() {
            return groupBy(this.targetStates, ({ target }) => target.functionalGroupCode);
        },
    },
    methods: {
        ...mapActions(useConfigurationFormStore, ['setUserDisabledTargets']),
        ...mapActions(useCropProtectionStore, ['loadRecommendations']),

        async onApply() {
            const disabledTargets = this.targetStates.filter((t) => !t.enabled);

            this.setUserDisabledTargets(disabledTargets.map((t) => t.target.id));
            this.$hideSidebar();

            await this.loadRecommendations();
        },
    },
};
</script>
