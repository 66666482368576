import { defineStore } from 'pinia';
import to from 'await-to-js';
import apiRoute from '@/utils/route-helper';
import captureError from '@/utils/capture-error';
import { aceApiClient } from '@/utils/axios';
import { useLocalizationStore } from '@/stores/localization';

export const useLinksAndResourcesStore = defineStore('links-and-resources', {
    state: () => ({
        cropSpecificLinks: [],

        localizationStore: useLocalizationStore(),
    }),
    actions: {
        async loadCropSpecificLinks(eppoCode, division) {
            const route = apiRoute('linksAndResources', null, {
                countryCode: this.localizationStore.selectedCountry,
                cropEppoCode: eppoCode,
                division: division,
            });

            const [err, result] = await this.$loading.startLoadingWithCallback(
                'links-and-resources:load-crop-specific-links',
                async () => to(aceApiClient.get(route)),
            );

            if (err) {
                captureError(err, 'links-and-resources:load-crop-specific-links');
                return;
            }

            if (result) {
                this.cropSpecificLinks = result.data;
            }
        },
    },
});
