export const efficacyStringToNumber = (efficacy) => {
    switch (efficacy) {
        case '+++':
            return 3;
        case '++':
            return 2;
        case '+':
            return 1;
        case '0':
            return 0;
        default:
            return -1;
    }
};

export const groupBy = (array, callback) => {
    return array.reduce((result, element) => {
        const key = callback(element);

        if (!result[key]) {
            result[key] = [];
        }

        result[key].push(element);

        return result;
    }, {});
};

export const binaryToBase64 = (data) => {
    const base64Data = btoa(data);
    return 'data:image/jpg;base64,' + base64Data;
};

export const isInIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export const parseNumber = (val, type = 'float') => {
    const parsedValue = type === 'int' ? parseInt(val, 10) : parseFloat(val);

    return isNaN(parsedValue) ? null : parsedValue;
};
