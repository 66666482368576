export default (translationsArray, desiredLanguage, languageFieldName = 'language') => {
    let translation = translationsArray.find(
        (i) => i && i[languageFieldName] === desiredLanguage,
    )?.translation;

    // If nothing is found for the desired language, fall back to 'en'.
    if (!translation) {
        translation = translationsArray.find(
            (i) => i && i[languageFieldName] === 'en',
        )?.translation;
    }

    // If there's no english translation, try to set first available translation.
    if (!translation && translationsArray.length) {
        translation = translationsArray[0]?.translation;
    }

    return translation;
};
