const REQUIRED_TRACKING_DATA = [
    'page_name',
    'page_category',
    'page_subcategory',
    'page_type',
    'page_language',
    'page_country',
    'page_privacy_policy',
];

let hasNotLoadedWarned = false;
let isFirstTrack = true;

/**
 * @param data.page_name Contains a user-friendly name for the page (e.g., title)
 * @param data.page_category Should align with website category structure
 * @param data.page_subcategory May contain navigation/menu level 2 entry
 * @param data.page_type I.e. Home, Product Category, Product Page, Cart etc.
 * @param data.page_language Targeted language of website
 * @param data.page_country Targeted country of website
 * @param data.page_privacy_policy Boolean value. true if the page is your privacy policy pge, else false.
 */
export function track(data, updateTitle = true) {
    // Check if tealium is available
    if (!window.utag) {
        if (!hasNotLoadedWarned) {
            console.warn('[Tealium] Not loaded');
            hasNotLoadedWarned = true;
        }

        return;
    }

    // Check if every required property is present
    if (
        !REQUIRED_TRACKING_DATA.every((key) => {
            if (!data.hasOwnProperty(key)) {
                console.error(`[Tealium] Track - Missing required tracking data: ${key}`);
                return false;
            }

            return true;
        })
    ) {
        return;
    }

    data.page_name = `BASF AgSolutions Finder - ${data.page_name}`;

    if (updateTitle) {
        document.title = data.page_name;
    }

    const send = () => {
        // Send data to tealium
        window.utag.view(data);
    };

    // When the first track is called, wait a bit before sending the data (so utag can initialize)
    if (isFirstTrack) {
        isFirstTrack = false;

        setTimeout(() => send(), 500);
    } else {
        send();
    }
}
