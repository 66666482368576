<template>
    <section class="crop-protection-form" sticky-container>
        <transition name="loading-overlay">
            <LoadingOverlay v-if="!hasLoadingFinished" />
            <MultiForm v-else ref="form" @onStepChanged="onFormStepChange">
                <div v-show="currentFormStep === formStepKeys.CROP">
                    <ParameterSection
                        :title="$t('form.selectCrop')"
                        class="parameter-section__crops"
                    >
                        <template #content>
                            <OptionSelectList
                                v-if="!$loading.isLoading('crop-protection:load-crops')"
                                name="crops"
                                :options="sortedCrops"
                                grid
                                :gridColumns="3"
                                :modelValue="currentSelectedCrop"
                                @update:modelValue="(crop) => onCropChange(crop)"
                            >
                                <template #content="props">
                                    <SelectButton
                                        :title="getCropName(props.item)"
                                        :image="getCropImage(props.item)"
                                        isSVGImage
                                        :active="props.active"
                                    />
                                </template>
                            </OptionSelectList>
                            <div v-else>loading crops..</div>
                            <Btn
                                v-if="selectedCountryMeta?.showMoreCropsLink"
                                :href="selectedCountryMeta.showMoreCropsLink"
                                target="_blank"
                                type="text"
                                icon="fa-arrow-up-right"
                                iconPosition="start"
                                class="more-crops-link"
                                @click="(e) => linkOverwriteFunction(e, 'viewCropOverview')"
                                >{{ $t('header.moreCropsOnOurWebsite') }}
                            </Btn>
                        </template>
                    </ParameterSection>
                </div>

                <div v-if="currentFormStep === formStepKeys.PARAMETERS">
                    <!--Tree-->
                    <DecisionTree
                        v-if="
                            !$loading.isLoading('crop-protection:load-parameter-configuration') &&
                            parameterConfiguration &&
                            parameterConfiguration.recommendationFlowParameters &&
                            parameterConfiguration.additionalFilterParameters
                        "
                        :parameterConfiguration="parameterConfiguration"
                        ref="tree"
                    />
                    <div v-else>loading parameter tree..</div>
                </div>

                <div v-if="currentFormStep === formStepKeys.GROWTH_STAGE">
                    <!--Growth stages-->
                    <template v-if="!$loading.isLoading('crop-protection:load-growth-stages')">
                        <GrowthStageSelector
                            v-if="growthStages?.length"
                            :growthStages="growthStages"
                            :selectedCrop="currentSelectedCrop"
                        />
                        <div v-else>
                            {{ $t('cropProtection.noGrowthStagesFound') }}
                        </div>
                    </template>
                    <div v-else>loading growth stages..</div>
                </div>

                <div v-if="currentFormStep === formStepKeys.TARGETS">
                    <InputField
                        v-model="targetSearchTerm"
                        :placeholder="$t('targetsPage.searchPlaceholder')"
                        class="targets-mobile-search-input"
                        icon="fa-magnifying-glass"
                        clearable
                        @onClear="() => (targetSearchTerm = '')"
                    />
                    <TargetsToolbar
                        v-model="targetSearchTerm"
                        :tabs="showAccordionsAndToolbar ? targetTabs : []"
                        :counterMax="maxSelectedTargets"
                        :counterValue="selectedTargets.length"
                        ref="targetToolbar"
                        v-sticky
                    />
                    <TargetSelector
                        :groupedTargets="targetsGroupedByFunctionalGroups"
                        :maxSelectedTargets="maxSelectedTargets"
                        :focusCrop="currentSelectedCrop"
                        :showAccordions="showAccordionsAndToolbar"
                        @onActiveSectionChanged="onActiveTargetSectionChanged"
                    />
                    <CollapsableInputField v-model="targetSearchTerm" />
                </div>

                <div v-show="currentFormStep === formStepKeys.RESULT">
                    <!--Recommendation-->
                    <Recommendation :recommendations="cropRecommendations" />
                </div>
            </MultiForm>
        </transition>
    </section>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useCropProtectionStore } from '@/stores/crop-protection';
import OptionSelectList from '@/components/molecules/OptionSelectList.vue';
import DecisionTree from '@/components/molecules/DecisionTree.vue';
import MultiForm from '@/components/molecules/MultiForm.vue';
import GrowthStageSelector from '@/components/organisms/GrowthStageSelector.vue';
import RecommendationCardList from '@/components/molecules/Recommendation/RecommendationCardList.vue';
import Card from '@/components/atoms/Card.vue';
import OptionCard from '@/components/atoms/OptionCard.vue';
import SelectButton from '@/components/atoms/SelectButton.vue';
import ParameterSection from '@/components/molecules/ParameterSection.vue';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import Btn from '@/components/atoms/Btn.vue';
import BottomBar from '@/components/molecules/BottomBar.vue';
import FormStepKeys from '@/utils/constants/form-step-keys';
import TargetSelector from '@/components/molecules/TargetSelector.vue';
import TargetsToolbar from '@/components/molecules/TargetsToolbar.vue';
import CollapsableInputField from '@/components/molecules/CollapsableInputField.vue';
import InputField from '@/components/atoms/InputField.vue';
import { groupBy } from '@/utils/helpers';
import { useDeeplinkStore } from '@/stores/deeplink';
import ShareButton from '@/components/molecules/ShareButton.vue';
import ServiceTeaserList from '@/components/molecules/ServiceTeaserList.vue';
import LoadingOverlay from '@/components/atoms/LoadingOverlay.vue';
import { useLocalizationStore } from '@/stores/localization';
import { useTeaserStore } from '@/stores/teaser';
import PromoTeaserList from '@/components/molecules/Recommendation/PromoTeaserList.vue';
import Recommendation from '@/components/organisms/Recommendation.vue';
import { track } from '@/utils/utag';
import { useAppStore } from '@/stores/app';
import { linkOverwriteFunction } from '@/utils/sendPostMessage';
import TrackingTypes from '@/utils/constants/trackingTypes';

export default {
    name: 'CropProtectionForm',
    components: {
        Recommendation,
        PromoTeaserList,
        ServiceTeaserList,
        LoadingOverlay,
        ShareButton,
        InputField,
        CollapsableInputField,
        TargetsToolbar,
        TargetSelector,
        BottomBar,
        Btn,
        ParameterSection,
        SelectButton,
        OptionCard,
        Card,
        RecommendationCardList,
        GrowthStageSelector,
        MultiForm,
        DecisionTree,
        OptionSelectList,
    },
    data() {
        return {
            initialized: false,
            maxSelectedTargets: 10,
            targetSearchTerm: '',
        };
    },
    async created() {
        const steps = [
            {
                title: 'bottomBar.crop',
                heroTitle: 'header.selectCrop',
                identifier: FormStepKeys.CROP,
            },
        ];

        if (this.isParameterConfigExisting && this.hasParameterConfigAdvices) {
            steps.push({
                title: 'bottomBar.context',
                heroTitle: 'header.selectContext',
                identifier: FormStepKeys.PARAMETERS,
            });
        }

        if (this.hasParameterConfigAdvices) {
            steps.push(
                {
                    title: 'bottomBar.growth',
                    heroTitle: 'header.selectGrowthStage',
                    identifier: FormStepKeys.GROWTH_STAGE,
                },
                {
                    title: 'bottomBar.targets',
                    heroTitle: 'targetsPage.targets',
                    identifier: FormStepKeys.TARGETS,
                },
                {
                    title: 'bottomBar.results',
                    heroTitle: 'recommendations.recommendedTankMixtures',
                    identifier: FormStepKeys.RESULT,
                },
            );
        }

        this.setFormSteps(steps);

        await this.loadCrops();

        this.initialized = true;

        // Subscribe to all form changes and pass to deeplink store.
        const formStore = useConfigurationFormStore();
        formStore.$subscribe((mutation, state) => {
            this.updateDeeplink(state);
        });

        // Apply deeplink values if present.
        await this.validateAndApplyDeeplink(true);
        this.triggerUpdateDeeplink();
    },
    async mounted() {
        // Load promo teasers.
        await this.loadPromotionTeasers('crop');
    },
    watch: {
        currentFormStep: {
            immediate: true,
            handler(current) {
                const data = {
                    page_category: TrackingTypes.Category.CropProtection,
                    page_subcategory: '',
                    page_country: this.selectedCountry,
                    page_language: this.selectedLanguage.language,
                    page_type: TrackingTypes.Type.RecommendationFlow,
                    page_privacy_policy: false,
                };

                if (current === FormStepKeys.CROP) {
                    data.page_name = 'Step 1 - Crop';
                } else if (current === FormStepKeys.PARAMETERS) {
                    data.page_name = 'Step 2 - Parameters';
                } else if (current === FormStepKeys.GROWTH_STAGE) {
                    data.page_name = 'Step 3 - Growth stage';
                } else if (current === FormStepKeys.TARGETS) {
                    data.page_name = 'Step 4 - Targets';
                } else if (current === FormStepKeys.RESULT) {
                    data.page_name = 'Recommendation';
                    data.page_type = TrackingTypes.Type.Recommendation;
                }

                track(data);
            },
        },
    },
    computed: {
        ...mapState(useLocalizationStore, [
            'selectedCountry',
            'selectedLanguage',
            'selectedCountryMeta',
        ]),
        ...mapState(useCropProtectionStore, [
            'crops',
            'sortedCrops',
            'parameterConfiguration',
            'growthStages',
            'targets',
            'cropRecommendations',
        ]),
        ...mapState(useConfigurationFormStore, [
            'formSteps',
            'currentFormStep',
            'currentFormStepIndex',
            'canGoBack',
            'canGoForwards',
            'selectedCrop',
            'selectedTargets',
            'selectedGrowthStage',
            'activeTargets',
        ]),
        ...mapState(useAppStore, ['isMobileVersion']),

        currentSelectedCrop() {
            return this.selectedCrop;
        },
        isParameterConfigExisting() {
            return (
                this.parameterConfiguration?.recommendationFlowParameters !== null &&
                this.parameterConfiguration?.additionalFilterParameters !== null
            );
        },
        hasParameterConfigAdvices() {
            return (
                this.parameterConfiguration?.recommendationFlowParameters !== false &&
                this.parameterConfiguration?.additionalFilterParameters !== false
            );
        },
        formStepKeys() {
            return FormStepKeys;
        },
        targetsGroupedByFunctionalGroups() {
            const term = this.targetSearchTerm.toLowerCase();

            const filteredTargets = this.targets.filter((t) => {
                const hasScientificNameMatch = t.scientificName.toLowerCase().indexOf(term) !== -1;
                const hasNameMatch = t.translatedNames.find(
                    (n) => n.translation.toLowerCase().indexOf(term) !== -1,
                );

                return hasNameMatch || hasScientificNameMatch;
            });
            return groupBy(filteredTargets, ({ functionalGroupCode }) => functionalGroupCode);
        },
        targetTabs() {
            const targetDisplayInfo = this.parameterConfiguration?.functionalGroupInformation;
            return Object.keys(this.targetsGroupedByFunctionalGroups).filter(
                (k) => targetDisplayInfo?.find((g) => g.value === k)?.hasTargets,
            );
        },
        showAccordionsAndToolbar() {
            return this.targetSearchTerm === '';
        },
        hasLoadingFinished() {
            return (
                this.initialized &&
                !this.$loading.isLoading('deeplink:apply-deep-link') &&
                !this.$loading.isLoading('crop-protection:load-crops') &&
                !this.$loading.isLoading('crop-protection:load-parameter-configuration') &&
                !this.$loading.isLoading('crop-protection:load-growth-stages') &&
                !this.$loading.isLoading('crop-protection:load-targets') &&
                !this.$loading.isLoading('crop-protection:load-recommendations')
            );
        },
    },
    methods: {
        linkOverwriteFunction,
        ...mapActions(useCropProtectionStore, [
            'loadCrops',
            'loadGrowthStages',
            'loadTargets',
            'loadRecommendations',
            'loadParameterConfiguration',
        ]),
        ...mapActions(useConfigurationFormStore, [
            'setSelectedCrop',
            'setSelectedTargets',
            'setFormSteps',
            'setCurrentStep',
            'removeHiddenFieldFromUserInput',
            'createParameterTreeFields',
            'validateParameterConfiguration',
        ]),
        ...mapActions(useDeeplinkStore, [
            'updateDeeplink',
            'validateAndApplyDeeplink',
            'triggerUpdateDeeplink',
        ]),
        ...mapActions(useTeaserStore, ['loadPromotionTeasers']),

        async onCropChange(crop) {
            await this.setSelectedCrop(crop);

            if (!this.hasParameterConfigAdvices) {
                this.$notify(this.$t('errors.cropProtection.noAdvicesForCrop'), 'warning');
                return;
            }

            if (this.$refs.tree) {
                this.$refs.tree.resetTree();
            }
        },
        async onFormStepChange(current) {
            this.setCurrentStep(current.step.identifier);

            // Load targets when user navigates to targets form step.
            if (current.step.identifier === FormStepKeys.PARAMETERS) {
                await this.loadParameterConfiguration(this.currentSelectedCrop.eppoCode);

                await this.$nextTick(() => {
                    this.triggerUpdateDeeplink();
                    this.validateParameterConfiguration();
                });
            } else if (current.step.identifier === FormStepKeys.TARGETS) {
                await this.loadTargets();
            } else if (current.step.identifier === FormStepKeys.GROWTH_STAGE) {
                this.removeHiddenFieldFromUserInput(false);
                await this.loadGrowthStages();
            } else if (current.step.identifier === FormStepKeys.RESULT) {
                await this.loadRecommendations();
            }
        },
        getCropImage(crop) {
            const img = new URL(
                `../../assets/images/icons/crops/${crop.eppoCode}.svg`,
                import.meta.url,
            ).href;

            if (img.includes('undefined')) {
                return null;
            }

            return img;
        },
        getCropName(crop) {
            if (
                this.$te(`crops.${crop[this.$translationMappingKey('crops')]}.custom_name`) ||
                this.$te(
                    `crops.${crop[this.$translationMappingKey('crops')]}.custom_name`,
                    'en_ALL',
                )
            ) {
                return this.$t(`crops.${crop[this.$translationMappingKey('crops')]}.custom_name`);
            }
            return this.$t(`crops.${crop[this.$translationMappingKey('crops')]}.name`);
        },
        onActiveTargetSectionChanged(data) {
            this.$refs.targetToolbar.updateSelectedTab(data);
        },
    },
};
</script>
