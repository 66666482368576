<template>
    <div class="restart-buttons">
        <Btn type="default" icon="fa-arrow-rotate-right" @click="onRestartClick"
            >{{ $t('bottomBar.restart') }}
        </Btn>

        <Btn type="default" icon="fa-arrow-rotate-right" @click="onRestartWithParametersClick"
            >{{ $t('recommendations.restartWithCurrentParameters') }}
        </Btn>
    </div>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';
import FormStepKeys from '@/utils/constants/form-step-keys';
import { mapActions } from 'pinia';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import { useSeedStore } from '@/stores/seed';

export default {
    name: 'RestartButtons',
    components: { Btn },
    methods: {
        ...mapActions(useConfigurationFormStore, ['resetAllUserInputs', 'setCurrentStep']),
        ...mapActions(useSeedStore, ['toggleCompareMode']),

        onRestartClick() {
            this.resetAllUserInputs();
            this.toggleCompareMode(false);
            this.setCurrentStep(FormStepKeys.CROP);
        },
        onRestartWithParametersClick() {
            this.toggleCompareMode(false);
            this.setCurrentStep(FormStepKeys.CROP);
        },
    },
};
</script>
