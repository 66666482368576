// Form steps, order needs to be correct.
export default {
    CROP: 0,
    PARAMETERS: 1,
    GROWTH_STAGE: 2,
    TARGETS: 3,
    RESULT: 4,

    SEED_RESULT: 2,
};
