<template>
    <div class="crop-protection">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'CropProtection',
};
</script>
