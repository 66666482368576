<template>
    <div class="recommendation-seed-data">
        <ul class="recommendation-application__product-list">
            <li v-for="item in dataToDisplay">
                <div class="product">
                    <span>{{ $t(item.label) }}</span>
                    <span>{{ $t(getCardParameterValueTranslationKey(item.value)) }}</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useCropProtectionStore } from '@/stores/crop-protection';
import { useSeedStore } from '@/stores/seed';
import { useConfigurationFormStore } from '@/stores/configuration-form';
import translationArrayHelper from '@/utils/translation-array-helper';

export default {
    name: 'RecommendationSeedData',
    props: {
        advice: {
            type: Object,
            required: true,
        },
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState(useCropProtectionStore, ['getProductDisplayAmount']),
        ...mapState(useSeedStore, [
            'seedRecommendations',
            'getDataValueFromDataListItem',
            'getCardParameterValueTranslationKey',
        ]),
        ...mapState(useConfigurationFormStore, ['enteredTreeValues']),

        dataToDisplay() {
            const primaryDataList = this.seedRecommendations?.primaryDataList;
            primaryDataList.sort((a, b) => a.order - b.order);

            return primaryDataList
                .map((p) => {
                    let label =
                        p.translatedNames && p.translatedNames?.length > 0
                            ? translationArrayHelper(p.translatedNames, this.$i18n.locale, 'locale')
                            : p.externalIdentifier;

                    if (p.type === 'custom' && p.externalIdentifier === 'seedRate') {
                        const varietyType = this.product.varietySeedType;
                        label = `recommendations.seedRate${
                            varietyType.charAt(0).toUpperCase() + varietyType.slice(1)
                        }`;
                    }

                    const value = this.getDataValueFromDataListItem(p, this.product)?.value;

                    return {
                        label,
                        value,
                    };
                })
                .filter((item) => item?.value);
        },
    },
};
</script>
