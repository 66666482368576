<template>
    <a
        :href="link"
        class="product-teaser"
        target="_blank"
        @click="
            (e) =>
                linkOverwriteFunction(e, 'viewProduct', {
                    productNumber: product.productNumber,
                })
        "
    >
        <div class="product-teaser__image" :style="imageStyle" />
        <span class="product-teaser__title" v-sub-tag-wrapper>{{ title }}</span>
    </a>
</template>

<script>
import { linkOverwriteFunction } from '@/utils/sendPostMessage';

export default {
    name: 'ProductTeaser',
    methods: { linkOverwriteFunction },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        title() {
            return this.$t(`products.${this.product.productId}.name`);
        },
        imageStyle() {
            const url = this.product.media?.url;
            const placeholderPath = new URL(
                `@/assets/images/product-placeholder.png`,
                import.meta.url,
            ).href;

            return {
                backgroundImage: `url(${url ?? placeholderPath})`,
            };
        },
        link() {
            return this.product.externalProductLink;
        },
    },
};
</script>
