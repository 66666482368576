<template>
    <RouterView v-if="hasLoadingFinished" />
</template>

<script>
import { RouterView } from 'vue-router';
import { mapActions, mapState } from 'pinia';
import { useLocalizationStore } from '@/stores/localization';
import { useDeeplinkStore } from '@/stores/deeplink';
import { useAppStore } from '@/stores/app';

// Component is used as a wrapper for the RouterView in order to receive the country regardless of the initial route.
// It's the entry point for the router.
export default {
    name: 'RouterViewWrapper',
    components: {
        RouterView,
    },
    data() {
        return {
            hasLoadingFinished: false,
        };
    },
    async created() {
        // Get country from URL.
        const country = this.$route.params.country;

        // If no country is given, check if there's a short-link hash.
        if (!country) {
            const hash = this.$route.params.hash;

            if (!hash) {
                // Without a hash we navigate to the country-select page.
                this.$router.push({ name: 'country-select', query: this.$route.query });
            } else {
                // With a hash we try to resolve the short-link and redirect.
                const foundUrl = await this.resolveShortLink(hash);
                // Invalid short-links redirect to the country select.
                if (!foundUrl) {
                    this.$router.push({ name: 'country-select', query: this.$route.query });
                }
            }
        }
        // Otherwise try to set the selected country in the store.
        else {
            const isCountrySetSuccessfully =
                (this.selectedCountry && this.selectedLanguage) ||
                (await this.setSelectedCountry(country));

            // If the country is not a part of the supported countries, go to country-selection.
            if (!isCountrySetSuccessfully) {
                this.$router.push({
                    name: 'country-select',
                    query: this.$route.query,
                });
                return;
            }
        }

        this.hasLoadingFinished = true;

        const hasMobileQueryParam = this.$route?.query?.hasOwnProperty('isMobile');
        this.setIsMobileVersion(hasMobileQueryParam);
    },
    computed: {
        ...mapState(useLocalizationStore, ['selectedCountry', 'selectedLanguage']),
    },
    methods: {
        ...mapActions(useLocalizationStore, ['setSelectedCountry']),
        ...mapActions(useDeeplinkStore, ['resolveShortLink']),
        ...mapActions(useAppStore, ['setIsMobileVersion']),
    },
};
</script>
