<template>
    <section class="static-text-container" v-sub-tag-wrapper>
        <slot />
    </section>
</template>

<script>
export default {
    name: 'StaticTextContainer',
};
</script>
