<template>
    <div class="efficacy" :title="title">
        <img
            v-for="(image, index) in ratingImages"
            :key="index"
            :src="image.src"
            :alt="image.alt"
        />
    </div>
</template>

<script>
export default {
    name: 'Efficacy',
    props: {
        amount: {
            type: Number,
            validator: (val) => val >= 0 && val <= 3,
        },
    },
    computed: {
        ratingImages() {
            const fullCount = Math.floor(this.amount);
            const fractionalPart = this.amount - fullCount;
            const halfCount = fractionalPart >= 0.25 && fractionalPart < 0.75 ? 1 : 0;

            const noneCount = 3 - fullCount - halfCount;
            const images = [];

            for (let i = 0; i < fullCount; i++) {
                images.push({ src: this.getDotImage('full'), alt: '' });
            }

            if (halfCount) {
                images.push({ src: this.getDotImage('half'), alt: '' });
            }

            for (let i = 0; i < noneCount; i++) {
                images.push({ src: this.getDotImage('none'), alt: '' });
            }

            return images;
        },
        title() {
            switch (this.amount) {
                case 1:
                    return this.$t('overallEffect.bad');
                case 2:
                    return this.$t('overallEffect.medium');
                case 3:
                    return this.$t('overallEffect.good');
            }
        },
    },
    methods: {
        getDotImage(type) {
            return new URL(`../../assets/images/icons/dots/state=${type}.svg`, import.meta.url)
                .href;
        },
    },
};
</script>
