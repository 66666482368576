import { defineRule } from 'vee-validate';

defineRule('required', (value) => {
    if (!value || !value.length) {
        return 'required';
    }
    return true;
});
defineRule('email', (value) => {
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(value)) {
        return 'email';
    }
    return true;
});
