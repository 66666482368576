<template>
    <a :href="link" target="_blank" class="promo-teaser">
        <div class="promo-teaser__image" :style="imageStyle" />
        <div class="promo-teaser__content">
            <h3 class="promo-teaser__content-title">
                {{ title }}
            </h3>
            <p class="promo-teaser__content-text">
                {{ text }}
            </p>
            <Btn
                type="text"
                icon="fa-arrow-up-right"
                iconPosition="start"
                class="promo-teaser__content-link"
                >{{ btnText }}
            </Btn>
        </div>
    </a>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';

export default {
    name: 'PromoTeaser',
    components: { Btn },
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        btnText: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
    },
    computed: {
        imageStyle() {
            return { backgroundImage: `url(${this.image})` };
        },
    },
};
</script>
