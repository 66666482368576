<template>
    <StaticTextContainer class="sidebar-text">
        <p v-html="content.text" />
    </StaticTextContainer>
</template>

<script>
import StaticTextContainer from '@/components/atoms/StaticTextContainer.vue';

export default {
    name: 'SidebarText',
    components: { StaticTextContainer },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
};
</script>
